import { SyntheticEvent, useCallback, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { Navigate, createSearchParams, useLocation, useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as SegmentIcon } from '@carbonmaps/media/icons/category-icon.svg';
import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { formatUrl, getFacetLabel, parseUrl } from '@carbonmaps/shared/utils/utils';
import { getOneSegmentTemporality } from '@carbonmaps/ui/actions/analyse.actions';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { pxToRem } from '@carbonmaps/ui/utils/styles';

import SEO from '../../../components/SEO';
import TabsBar, { TabItem } from '../../../components/TabsBar';
import { useYearSelection } from '../../../hooks/useImpactYear';
import { useJuneTrack } from '../../../hooks/useJuneTrack';
import { useQueryParams } from '../../../hooks/useSearchParams';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import { useGetClientAuthQuery } from '../../../lib/react-query/features/auth/auth.hooks';
import { CARBONE_IMPACT_VIEW, TOP_BAR_HEIGHT } from '../../../utils/constants';

import HeaderCategoryPage from './component/HeaderCategoryPage';
import Ingredient from './component/Ingredient';
import Product from './component/Product';
import Supplier from './component/Supplier';
import Synthesis from './component/Synthesis';

const tabs: any = {
	synthesis: {
		title: 'synthesis-tab',
		component: Synthesis,
		tabName: 'Summary',
	},
	product: {
		title: 'products',
		component: Product,
		tabName: 'Products',
	},
	ingredients: {
		title: 'ingredients',
		component: Ingredient,
		tabName: 'Ingredients',
	},
	supplier: {
		title: 'suppliers',
		component: Supplier,
		tabName: 'Suppliers',
	},
};

const CategoryPage = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const [searchQueryParams] = useSearchQueryParams();
	const { setViewMode, viewMode, setBreadcrumbs } = useApp();
	const [categoryData, setCategoryData] = useState<any>(null);
	const queryParams = useQueryParams();
	const searchParams = createSearchParams(queryParams as any)?.toString();

	// translation
	const { t } = useTranslation();

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	// get url params
	const params = useParams();

	// get the selected year stored with zustand
	const { selectedYear } = useYearSelection();

	// -------------------------------------------------------- //
	// --------------------- use query ------------------------ //
	// -------------------------------------------------------- //
	const { data, isLoading: isLoadingData } = useQuery({
		queryKey: [
			'getOneSegmentTemporality',
			{
				segmentName: params.segment,
				segmentValue: params.value,
				facetFilters: searchQueryParams.facetFilters,
				period: selectedYear,
			},
		],
		queryFn: async ({ queryKey }) => {
			const { 1: params } = queryKey;
			const result = await getOneSegmentTemporality(params);
			return result;
		},
	});

	const analytics = useJuneTrack();
	const trackEvent = useCallback(
		(name: string, options: any) => {
			if (!analytics) return;
			analytics.track(name, options, {
				context: { category: 'Analyse' },
			});
		},
		[analytics],
	);

	useEffect(() => {
		if (viewMode) return;

		setViewMode && setViewMode(CARBONE_IMPACT_VIEW);
	}, [setViewMode, viewMode]);

	useEffect(() => {
		setBreadcrumbs([
			{
				text: `Analyse ${getFacetLabel(params.segment || '', authData?.session.company)}`,
				link: `${FRONT_PATH_NAMES.analyse}/${params.segment}?${searchParams}`,
			},
			{ text: t(parseUrl(params.value) as any) || '' },
		]);
		trackEvent('Analyse Land Segment', {
			Segment: params.segment,
			Category: parseUrl(params.value),
		});
	}, [searchParams, params, t]);

	useEffect(() => {
		if (!data) return;
		setCategoryData(data);
	}, [data]);

	const isLoading = !categoryData;

	// -------------------------------------------------------------------- //
	// ---------------------- LISTENER  ----------------------------------- //
	// -------------------------------------------------------------------- //

	const handleTabChange = (_: SyntheticEvent, value: string) => {
		navigate(`${FRONT_PATH_NAMES.analyse}/${params.segment}/${formatUrl(params.value)}/${value}?${searchParams}`);

		trackEvent('Analyse Segment Switch Tab', {
			Segment: params.segment,
			Category: parseUrl(params.value),
			AnalyseTabTarget: tabs[value]?.tabName,
		});
	};

	if (!params.tab) {
		return (
			<Navigate
				replace
				state={{ from: location }}
				to={`${FRONT_PATH_NAMES.analyse}/${params.segment}/${params.value}/${Object.keys(tabs)[0]}`}
			/>
		);
	}

	return (
		<Box>
			<SEO title={`${getFacetLabel(params.segment || '', authData?.session.company)} - Carbon Maps`} />
			<Box width="90%" mx="auto">
				<HeaderCategoryPage
					icon={<SegmentIcon />}
					title={
						<Typography variant="h2" color={siteColors.purple500} sx={{ textTransform: 'uppercase', fontSize: '13px' }}>
							{params?.segment}
						</Typography>
					}
					subtitle={
						<Typography fontWeight={600} fontSize={'24px'}>
							{t(params?.value)}
						</Typography>
					}
				/>
			</Box>

			{isLoading
			? (
				<Skeleton variant="rectangular" height="62px" sx={{ borderRadius: '16px', width: '90%', mx: 'auto' }} />
			)
			: (
				<TabsBar
					value={params.tab}
					onChange={handleTabChange}
					sticky
					sx={{
						mb: pxToRem(42),
						width: '90%',
						mx: 'auto',
						top: TOP_BAR_HEIGHT,
						'&.sticky': { width: '100%', borderRadius: '16px' },
					}}
				>
					{Object.entries(tabs).map(([tabName, { title, disabled }]: any) => {
						return (
							<TabItem
								key={tabName}
								label={<span className="capitalize">{t(title)}</span>}
								value={tabName}
								disabled={isLoading ? true : disabled}
							/>
						);
					})}
				</TabsBar>
			)}

			<Box width="90%" mx="auto">
				{Object.entries(tabs).map(([tabName, { component: Component }]: any) => {
					return (
						!isLoading &&
						tabName === params.tab && <Component key={tabName} dataSegment={categoryData} loading={isLoadingData} />
					);
				})}
			</Box>
		</Box>
	);
};

export default CategoryPage;
