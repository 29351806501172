import { css, cx } from '@emotion/css';

import TrajectoryGraph from '../../../../components/charts/TrajectoryGraph';
import TrajectoryLegend from '../../../../components/charts/TrajectoryLegend';
import Card from '../../../../components/v3/Card';
import { useTranslation } from '../../../../hooks/useTranslation';
import { siteColors } from '../../../../lib/colors';
import { orderBy } from '../../../../utils/array.utils';
import { useSBTiFlagGraphData } from '../../sbtiFlag.hooks';

import SBTiCardTitle from './SBTiCardTitle';

const styles = {
	container: css({
		maxWidth: '100% !important',
	}),
	green800: css({
		color: `${siteColors.green800} !important`,
	}),
	carbon400: css({
		color: `${siteColors.carbon400} !important`,
	}),
};
const SBTi_LEGEND = [
	{
		label: 'Total CO₂e (tonnes)',
		color: siteColors.carbon500,
	},
	{
		label: 'SBTi FLAG',
		color: siteColors.green800,
	},
];
type SBTiFLAGTrajectoryProps = {
	graphWidth?: string
}

const SBTiFLAGTrajectory = ({ graphWidth }:SBTiFLAGTrajectoryProps ) => {
	const { t }= useTranslation();

	// ---- fetch data ---- //
	const { data, isLoading } = useSBTiFlagGraphData();

	return (
		<Card
			title={<SBTiCardTitle title={t('Trajectoire du SBTi FLAG')}/>}
			loading={isLoading}
			innerClassName={cx('flex1', styles.container)}
			containerClassName={styles.container}
		>
			<TrajectoryGraph
				data={orderBy(data || [], 'year','asc')}
				tooltipItems= {[
					{
						label: t('SBTi FLAG'),
						unit: 'tCO₂e',
						color: siteColors.green800,
						className: styles.green800,
					},
					{
						label: t('Total CO₂e'),
						unit: 'tCO₂e',
						color: siteColors.carbon400,
						className: styles.carbon400,
					},
				]}
			legend={<TrajectoryLegend items={SBTi_LEGEND} />}
			graphWidth={graphWidth}
			/>
		</Card>
	);
};

export default SBTiFLAGTrajectory;

