import { css, cx } from '@emotion/css';

import Typography from '../../../../components/Typography';

const styles = {
	title: css({
		fontSize: '16px !important',
	}),
};

type SBTiCardTitleProps= {
	title: string;
}

const SBTiCardTitle = ({ title }: SBTiCardTitleProps ) => {

	return (
		<Typography variant="body2" className={cx('grey900', styles.title)}>{title}</Typography>
	);

};

export default SBTiCardTitle;
