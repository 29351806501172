import { css, cx } from '@emotion/css';

import BlockTitle from '../../../../components/compareProduct/BlockTitle';
import CompareProductBarChart from '../../../../components/compareProduct/CompareProductBarChart';
import ImpactSelected from '../../../../components/compareProduct/ImpactSelected';
import { useCompareProduct } from '../../../../hooks/useCompareProduct';
import { useTranslation } from '../../../../hooks/useTranslation';
import { orderBy } from '../../../../utils/array.utils';
import { compareProductConfig } from '../../../../utils/compareProduct';


const styles = {
	container: css({
			padding: '16px 8px',
	}),
};

const options = [
	{
		label: 'Tri du moins impactant au plus impactant',
		value: 'asc',
	},
	{
		label: 'Tri du plus impactant au mois impactant',
		value: 'desc',
	},
];

const PerPeriodContent = () => {

	const { t }= useTranslation();

	const { sortDirection, setSortDirection, products, compareType, indicator } = useCompareProduct();

	// config for compare value
	const config = compareProductConfig.get(compareType)[indicator] as any;

	return (
		<div className={cx('flexColumn flex1 gap24', styles.container)}>
			<BlockTitle title={t('Comparaison de l’impact par période')} subtitle={t('Visualiser les évolutions des émissions carbone pour identifier les grandes tendances')}/>
			<ImpactSelected options={options} value={sortDirection} onChange={(v: any) => {setSortDirection(v);}}/>
			<CompareProductBarChart products={orderBy(products, config.field, sortDirection as any) as any}/>
		</div>
	);


};

export default PerPeriodContent;
