import { useMemo } from 'react';

import Typography from '../../../../components/Typography';
import Unit from '../../../../components/units/Unit';
import Card from '../../../../components/v3/Card';
import { useTranslation } from '../../../../hooks/useTranslation';
import { siteColors } from '../../../../lib/colors';
import CarbonFootprintLegend from '../../../dashboard/v3/carbon_footprint/CarbonFootprintLegend';
import CarbonFootprintTooltip from '../../../dashboard/v3/carbon_footprint/CarbonFootprintTooltip';
import DonutChart from '../../../packagings/components/DonutChart';
import { useSBTiFlagKpiData } from '../../sbtiFlag.hooks';

import SBTiCardTitle from './SBTiCardTitle';

const LandUseManagementChart = () => {
	const { t }= useTranslation();

		// ---- fetch data ---- //
		const { data, isLoading } = useSBTiFlagKpiData();

		const items = useMemo(() => {

			if (!data?.gesFlagLmCo2Impact && !data?.gesFlagLmNonCo2Impact ) {
				 return [
					{
						color: siteColors.grey3,
						value: 99.9,
						tonnage: 0,
					},
				];
			}

			const total = data?.gesFlagLmCo2Impact + data?.gesFlagLmNonCo2Impact;

			return [
				{
					label: 'CO₂ LM Emissions',
					value: (data?.gesFlagLmCo2Impact * 100) / (total || 1),
					impact: data?.gesFlagLmCo2Impact,
					color: siteColors.sbtiFlag.gesFlagLmCo2,
				},
				{
					label: 'Non-CO₂ LM Emissions',
					value: (data?.gesFlagLmNonCo2Impact * 100) / (total || 1),
					impact: data?.gesFlagLmNonCo2Impact,
					color: siteColors.sbtiFlag.gesFlagLmNonCo2,
				},
			];
		}, [data]);

	return (
		<Card
			title={< SBTiCardTitle title="Land Use Management" />}
			loading={isLoading}
			innerClassName={'flex1'}
		>
			<DonutChart
				width={200}
				height={200}
				items={items}
				innerRadius={85}
				outerRadius={70}
				legend={!data?.gesFlagLmCo2Impact && !data?.gesFlagLmNonCo2Impact ? null: <CarbonFootprintLegend items={items as any} />}
				withTooltip={(data?.gesFlagLmCo2Impact || data?.gesFlagLmNonCo2Impact) ? true : false}
				renderTooltip={(item, indicator) => {
					return <CarbonFootprintTooltip label={item?.label} value={item?.impact} unit={<Unit measure='carbonImpactTon'/>}/>;
				}}
				data={items}
				className="flex1 justifyCenter width100 nowrap"
			/>
		</Card>
	);

};

export default LandUseManagementChart;
