import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import { type CarbonFootprintItem } from '@carbonmaps/shared/types/carbonFootprint.types';
import { getCarbonFootprintProductAction } from '@carbonmaps/ui/actions/carbonFootprint.actions';

import Card from '../../../../components/v3/Card';
import { useYearSelection } from '../../../../hooks/useImpactYear';
import { useTranslation } from '../../../../hooks/useTranslation';
import { siteColors } from '../../../../lib/colors';
import DonutChart from '../../../packagings/components/DonutChart';

import CarbonFootprintLegend from './CarbonFootprintLegend';
import CarbonFootprintTitle from './CarbonFootprintTitle';
import CarbonFootprintTooltip from './CarbonFootprintTooltip';
import EmptyCarbonFootprint from './EmptyCarbonFootprint';

const colors_config = {
	0: siteColors.carbonImpact,
	1: siteColors.carbon100,
};

const CarbonFootprintProduct = () => {
	// ---- hooks ---- //
	const { t } = useTranslation();

	const { selectedYear } = useYearSelection();

	// ---- fetch data ---- //
	const { data, isLoading } = useQuery({
		queryKey: ['getCarbonFootprintProduct'],
		queryFn: getCarbonFootprintProductAction,
	});

	const items = useMemo(() => {
		if (!data?.items?.length)
			return [
				{
					color: siteColors.grey3,
					value: 99.9,
					tonnage: 0,
				},
			];

		return data.items.map((item: CarbonFootprintItem, index: number) => {
			return {
				...item,
				color: (colors_config as any)[index],
			};
		});
	}, [data]);

	return (
		<Card title={<CarbonFootprintTitle title={t('Bilan carbone', { period: '' })} item={data} />} loading={isLoading}>
			{!data
? (
				<EmptyCarbonFootprint text={t('empty-carbon-footprint', { period: selectedYear })} />
			)
: (
				<DonutChart
					width={200}
					height={200}
					items={items}
					innerRadius={85}
					outerRadius={70}
					legend={<CarbonFootprintLegend items={items} />}
					withTooltip
					renderTooltip={(item, indicator) => {
						return <CarbonFootprintTooltip label={item?.label} value={item?.tonnage} />;
					}}
					data={items}
					className="width100 nowrap"
				/>
			)}
		</Card>
	);
};

export default CarbonFootprintProduct;
