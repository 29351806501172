import { css, cx } from '@emotion/css';
import { Dialog, DialogContent, type DialogProps, DialogTitle, IconButton } from '@mui/material';
import _ from 'lodash';
import { X } from 'lucide-react';

import SearchProduct from '../../containers/products/general/tabs/products/SearchProduct';
import { product } from '../../hooks/useCompareProduct';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import Typography from '../Typography';

import ProductTableSelect from './ProductTableSelect';

type ProductModalSelectProps= {
	open: DialogProps['open'];
	onClose?: DialogProps['onClose'];
	onAddProduct: (product: product) => void;
	onRemoveProduct: (product: product) => void;
	container?: any;
}

const filterContainerStyle = css({
	'& .filterContainer-wrapper': {
		padding: '0!important',
		width: '90%',
	},
});

const ProductModalSelect = ({ open, onClose, onAddProduct, onRemoveProduct , container }: ProductModalSelectProps) => {
		const { t }= useTranslation();
		return (
			<Dialog
			container={container}
				open={open}
				onClose={onClose}
				scroll="paper"
				PaperProps={{
					sx: {
						borderRadius: 3,
					},
				}}
				fullWidth
				maxWidth="lg"
			>
				<DialogTitle component="div" id="scroll-dialog-title" sx={{ padding: '32px', position: 'relative' }}>
					<Typography variant="h2" sx={{ color: siteColors.primary, mb: '16px' }}>
						{t('add-product-quote', { product: _.toLower(t('product')), of_product: t('of_product') })}
					</Typography>

					<div className={cx('flexRow alignCenter', filterContainerStyle)}>
						<SearchProduct withMore={false} />
					</div>

					<IconButton
						sx={{ position: 'absolute', right: 10, top: 10 }}
						onClick={(e) => {
							onClose?.(e, 'backdropClick');
						}}
					>
						<X size={20} />
					</IconButton>
				</DialogTitle>
				<DialogContent
					dividers={true}
					sx={{
						padding: 0,
					}}
				>
					<ProductTableSelect onAddProduct={onAddProduct} onRemoveProduct={onRemoveProduct} />
				</DialogContent>
			</Dialog>
		);

};

export default ProductModalSelect;
