import DeleteConsoleUpdate from './DeleteConsoleUpdate';
import VersionTable from './VersionTable';

const ImportsTab = () => {
	return (
		<>
		<DeleteConsoleUpdate />
		{/* // UNDO HERE */}
		<VersionTable />
		</>
	);
};

export default ImportsTab;
