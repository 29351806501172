import { useMemo } from 'react';

import { css } from '@emotion/css';
import Grid from '@mui/material/Grid';
import { PieChart } from 'lucide-react';

import { WORDING_TERMS } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { WATER_INDICATOR } from '@carbonmaps/ui/utils/constants';
import { pxToRem } from '@carbonmaps/ui/utils/styles';

import CategoriesGraph from '../../../../../components/charts/CategoriesGraph';
import MetaDataCard from '../../../../../components/supplier/MetaDataCard';
import Unit from '../../../../../components/units/Unit';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { cn, siteColors } from '../../../../../lib/colors';
import { orderBy } from '../../../../../utils/array.utils';
import { CARBON_INDICATOR } from '../../../../../utils/constants';
import { useSupplierSetup } from '../../hooks/supplier.hooks';

import ApprosBlock from './blocks/ApprosBlock';
import SupplierIndicatorsBlock from './blocks/SupplierIndicatorsBlock';
import SupplierQuantityBlock from './blocks/SupplierQuantityBlock';
import SupplyRsePerformance from './blocks/SupplyRsePerformance';

const popoverIndicatorConfigItems = new Map([
	[
		CARBON_INDICATOR,
		{
			value: 'carbonImpact',
			label: 'Carbone',
			unit: (
				<Unit
					measure="carbonImpactTon"
					fontWeight={500}
					fontSize="12px"
					color={siteColors.grey700}
					lineHeight={1}
					sx={{ textTransform: 'uppercase' }}
				/>
			),
			popover: [
				{
					label: WORDING_TERMS.IMPACT,
					key: 'carbonImpact',
					unit: (
						<Unit
							measure="carbonImpactTon"
							fontWeight={600}
							lineHeight={'16.8px'}
							fontSize="14px"
							color={siteColors.text}
							sx={{ textTransform: 'capitalize' }}
						/>
					),
					precision: 0,
				},
				{
					label: 'Volume',
					key: 'tonnage',
					unit: <span>Tonne</span>,
					precision: 0,
				},
			],
		},
	],
	[
		WATER_INDICATOR,
		{
			value: 'waterImpact',
			label: 'Eau',
			unit: (
				<Unit
					measure="waterImpact"
					fontWeight={500}
					fontSize="12px"
					color={siteColors.grey700}
					lineHeight={1}
					sx={{ textTransform: 'uppercase' }}
				/>
			),
			popover: [
				{
					label: WORDING_TERMS.IMPACT,
					key: 'waterImpact',
					unit: (
						<Unit
							measure="waterImpact"
							fontWeight={600}
							lineHeight={'16.8px'}
							fontSize="14px"
							color={siteColors.text}
							sx={{ textTransform: 'capitalize' }}
						/>
					),
					precision: 2,
				},
				{
					label: 'Volume',
					key: 'tonnage',
					unit: <span>Tonne</span>,
					precision: 0,
				},
			],
		},
	],
]);

const SupplierDetailsSynthesis = () => {
	const { indicator } = useApp();
	const {
		supplierObject: supplier,
		dataCategories: segmentationData,
	} = useSupplierSetup();


	return (
		<Grid container gap="40px" sx={{ position: 'relative', flexWrap: 'nowrap', mt: '32px' }}>
			<Grid item xs={8.2}>
				<SupplierIndicatorsBlock />

				{Object.keys(segmentationData).length
					? (<CategoriesGraph
							data={segmentationData}
							showToggle={false}
							defaultIndicator={popoverIndicatorConfigItems.get(indicator)}
							color={indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500}
							icon={<PieChart color={siteColors.grey700} size={20} />}
							isDisabled={!supplier?.isCarboneImpactValid()}
						/>)
					: null}

				<ApprosBlock />

				<SupplyRsePerformance />
			</Grid>

			<Grid item flexGrow={1}>
				<SupplierQuantityBlock supplier={supplier} />
				<MetaDataCard supplier={supplier} />
			</Grid>
		</Grid>
	);
};

export default SupplierDetailsSynthesis;
