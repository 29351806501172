import { type RouteObject } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import {
	MOD_ANALYSE,
	MOD_AUDIT,
	MOD_CAMPAIGN,
	MOD_CAP2ER,
	MOD_DASHBOARD,
	MOD_ECO_CONCEPTION,
	MOD_INGREDIENT,
	MOD_PACKAGING,
	MOD_PRODUCT,
	MOD_QUOTE,
	MOD_SALES,
	MOD_SBTI_FLAG,
	MOD_SUPPLIER,
	MOD_TRAIN,
} from '@carbonmaps/shared/utils/module';

import AuthGuard from '../components/AuthGuard';
import CompanyModuleGuard from '../components/CompanyModuleGuard';
import CompanySessionGuard from '../components/CompanySessionGuard';
import DashboardSbtiFlag from '../containers/DashboardSbtiFlag/DashboardSbtiFlag';
import Analyse from '../containers/analyse/Analyse';
import CategoryPage from '../containers/analyse/detail/CategoryPage';
import ApboDashboard from '../containers/apbo/ApboDashboard';
import ModelizationSheet from '../containers/audit/modelizationSheet/ModelizationSheet';
import AuditSheet from '../containers/auditSheet/AuditSheet';
import AuditsGeneral from '../containers/audits/general/AuditsGeneral';
import Dashboard from '../containers/dashboard/Dashboard';
import IngredientSheet from '../containers/ingredientSheet/IngredientSheet';
import Ingredients from '../containers/ingredients/Ingredients';
import ChooseCompanyAuth from '../containers/logIn/CompanyList';
import ManagerSettings from '../containers/managerSettings/ManagerSettings';
import Documentation from '../containers/notionPages/Documentation';
import ReleaseNotes from '../containers/notionPages/ReleaseNotes';
import SectorSheet from '../containers/notionPages/SectorSheet';
import PackagingSheet from '../containers/packagingSheet/PackagingSheet';
import PackagingsGeneral from '../containers/packagings/PackagingsGeneral';
import ProductNotFound from '../containers/productSheet/ProductNotFound';
import ProductSheet from '../containers/productSheet/ProductSheet';
import ProductsGeneral from '../containers/products/general/ProductsGeneral';
import Promote from '../containers/promote/Promote';
import QuestionFormDetails from '../containers/questionForms/details/QuestionFormDetails';
import PrepareQuestionForm from '../containers/questionForms/details/_old/PrepareQuestionForm';
import QuestionFormSuppliers from '../containers/questionForms/details/_old/screens/suppliers-selection/QuestionFormSuppliers';
import QuestionFormsGeneral from '../containers/questionForms/general/QuestionFormsGeneral';
import Questionnaire from '../containers/questionnaire/Questionnaire';
import QuoteSheet from '../containers/quoteSheet/QuoteSheet';
import ReportClientSheet from '../containers/reportClientSheet/ReportClientSheet';
import ClientReports from '../containers/sales/clientsReports/ClientReports';
import Scenario from '../containers/scenario/Scenario';
import EcoConception from '../containers/simulation/ecoConception/EcoConception';
import SupplierContributor from '../containers/supplier/details/SupplierContributor';
import SupplierDetails from '../containers/supplier/details/SupplierDetails';
import SuppliersGeneral from '../containers/supplier/general/SuppliersGeneral';
import UserSettings from '../containers/userSettings/UserSettings';
import NotionLayout from '../layouts/embedNotion/NotionLayout';
import MainLayout from '../layouts/main/MainLayout';

const userRoutes: RouteObject[] = [
	{
		path: FRONT_PATH_NAMES.chooseCompany,
		element: (
			<AuthGuard allowedRolesLabels="all">
				<ChooseCompanyAuth />
			</AuthGuard>
		),
	},
	{
		element: (
			<AuthGuard allowedRolesLabels={['contributeur', 'utilisateur', 'éditeur', 'administrateur']}>
				<CompanySessionGuard />
			</AuthGuard>
		),
		children: [
			{
				element: <MainLayout />,
				children: [
					{
						path: FRONT_PATH_NAMES.home,
						element: (
							<CompanyModuleGuard module={MOD_DASHBOARD}>
								<Dashboard />
							</CompanyModuleGuard>
						),
						index: true,
					},

					{
						path: `${FRONT_PATH_NAMES.contributor}/:supplierId`,
						element: <SupplierContributor />,
						children: [
							{
								path: ':tab',
								index: true,
								element: null,
							},
						],
					},
				],
			},
			{
				element: <MainLayout disableIndicatorSwitch  />,
				children: [{
					path: FRONT_PATH_NAMES.dashboardSbtiFlag,
					element: (
						<CompanyModuleGuard module={MOD_SBTI_FLAG}>
							<DashboardSbtiFlag />
						</CompanyModuleGuard>
					),
				}],
			},

			{
				element: <MainLayout disableIndicatorSwitch disableYearSelection />,
				children: [
					{
						path: 'promote',
						element: <Promote />,
					},

					{
						path: FRONT_PATH_NAMES.cap2er,
						index: true,
						element:
							<CompanyModuleGuard module={MOD_CAP2ER}>
								<ApboDashboard />
							</CompanyModuleGuard>,
					},
					{
						path: FRONT_PATH_NAMES.userSettings,
						element: <UserSettings />,
						children: [
							{
								path: ':tab',
								element: null,
							},
						],
					},
					{
						path: FRONT_PATH_NAMES.questionForms,
						children: [
							{
								path: 'general',
								element: (
									<CompanyModuleGuard module={MOD_CAMPAIGN}>
										<QuestionFormsGeneral />
									</CompanyModuleGuard>
								),
								children: [
									{
										path: ':tab',
										element: null,
										index: true,
									},
								],
							},
							{
								path: 'details/:questionFormId',
								element: (
									<CompanyModuleGuard module={MOD_CAMPAIGN}>
										<QuestionFormDetails />
									</CompanyModuleGuard>
								),
								children: [
									{
										path: ':tab',
										element: null,
										index: true,
									},
								],
							},
							{
								path: 'prepare/:questionFormId',
								element: (
									<CompanyModuleGuard module={MOD_CAMPAIGN}>
										<QuestionFormSuppliers />
									</CompanyModuleGuard>
								),
							},
						],
					},
				],
			},
			{
				element: <MainLayout disableIndicatorSwitch disableYearSelection />,
				children: [
					{
						path: FRONT_PATH_NAMES.questionnaire,
						index: true,
						element: <Questionnaire />,
					},
				],
			},
			{
				element: <MainLayout disableAllYear />,
				path: 'audits',
				children: [
					{
						path: 'general',
						element: (
							<CompanyModuleGuard module={MOD_AUDIT}>
								<AuditsGeneral />
							</CompanyModuleGuard>
						),
						children: [
							{
								path: ':tab',
								index: true,
								element: null,
							},
						],
					},
				],
			},
			{
				element: <MainLayout disableAllYear />,
				children: [
					{
						path: FRONT_PATH_NAMES.audit,
						children: [
							{
								path: ':itemType',
								element: (
									<CompanyModuleGuard module={MOD_AUDIT}>
										<AuditSheet />
									</CompanyModuleGuard>
								),
							},
							{
								path: 'modelization',
								children: [
									{
										path: ':modelizationId',
										element: <ModelizationSheet />,
									},
								],
							},
						],
					},

					{
						path: FRONT_PATH_NAMES.sales,
						children: [
							{
								element: (
									<CompanyModuleGuard module={MOD_SALES}>
										<ClientReports />
									</CompanyModuleGuard>
								),
								index: true,
							},
							{
								path: ':slugClient',
								element: (
									<CompanyModuleGuard module={MOD_SALES}>
										<ReportClientSheet />
									</CompanyModuleGuard>
								),
								children: [
									{
										path: ':tab',
										index: true,
										element: null,
									},
								],
							},
						],
					},
				],
			},

			{
				element: <MainLayout />,
				children: [
					{
						path: 'products',
						children: [
							{
								path: 'general',
								element: (
									<CompanyModuleGuard module={MOD_PRODUCT}>
										<ProductsGeneral />
									</CompanyModuleGuard>
								),
								children: [
									{
										path: ':tab',
										index: true,
										element: null,
									},
								],
							},
							{
								path: 'details/:productId',
								element: (
									<CompanyModuleGuard module={MOD_PRODUCT}>
										<ProductSheet />
									</CompanyModuleGuard>
								),
								children: [
									{
										path: ':tab',
										index: true,
										element: null,
									},
								],
							},
							{
								path: 'not-found',
								element: <ProductNotFound />,
								children: [
									{
										path: ':uid',
										index: true,
										element: null,
									},
								],
							},
						],
					},

					{
						path: FRONT_PATH_NAMES.ingredients,
						children: [
							{
								element: (
									<CompanyModuleGuard module={MOD_INGREDIENT}>
										<Ingredients />
									</CompanyModuleGuard>
								),
								index: true,
							},
							{
								path: ':ingredientId',
								element: (
									<CompanyModuleGuard module={MOD_INGREDIENT}>
										<IngredientSheet />
									</CompanyModuleGuard>
								),
								children: [
									{
										path: ':tab',
										index: true,
										element: null,
									},
								],
							},
						],
					},

					{
						path: 'packagings',
						children: [
							{
								path: 'general',
								element: (
									<CompanyModuleGuard module={MOD_PACKAGING}>
										<PackagingsGeneral />
									</CompanyModuleGuard>
								),
								children: [
									{
										path: ':tab',
										index: true,
										element: null,
									},
								],
							},
							{
								path: 'details/:packagingId',
								element: (
									<CompanyModuleGuard module={MOD_PACKAGING}>
										<PackagingSheet />
									</CompanyModuleGuard>
								),
								children: [
									{
										path: ':tab',
										index: true,
										element: null,
									},
								],
							},
						],
					},

					{
						path: 'suppliers',
						children: [
							{
								path: 'general',
								element: (
									<CompanyModuleGuard module={MOD_SUPPLIER}>
										<SuppliersGeneral />
									</CompanyModuleGuard>
								),
								children: [
									{
										path: ':tab',
										index: true,
										element: null,
									},
								],
							},
							{
								path: 'details/:supplierId',
								element: (
									<CompanyModuleGuard module={MOD_SUPPLIER}>
										<SupplierDetails />
									</CompanyModuleGuard>
								),
								children: [
									{
										path: ':tab',
										index: true,
										element: null,
									},
								],
							},
						],
					},

					{
						path: FRONT_PATH_NAMES.analyse,
						children: [
							{
								path: ':segment',
								children: [
									{
										element: (
											<CompanyModuleGuard module={MOD_ANALYSE}>
												<Analyse />
											</CompanyModuleGuard>
										),
										index: true,
									},
									{
										path: ':value',
										element: (
											<CompanyModuleGuard module={MOD_ANALYSE}>
												<CategoryPage />
											</CompanyModuleGuard>
										),
										children: [
											{
												path: ':tab',
												index: true,
												element: null,
											},
										],
									},
								],
							},
						],
					},
				],
			},
			{
				element: <NotionLayout />,
				children: [
					{
						path: FRONT_PATH_NAMES.sectorSheet,
						element: <SectorSheet />,
					},
					{
						path: FRONT_PATH_NAMES.documentation,
						element: (
							<CompanyModuleGuard module={MOD_TRAIN}>
								<Documentation />
							</CompanyModuleGuard>
						),
					},
					{
						path: FRONT_PATH_NAMES.releaseNotes,
						element: <ReleaseNotes />,
					},
				],
			},
		],
	},
	{
		element: (
			<AuthGuard allowedRolesLabels={['éditeur', 'administrateur']}>
				{/* // require company inside the session */}
				<CompanySessionGuard>
					<MainLayout disableYearSelection />
				</CompanySessionGuard>
			</AuthGuard>
		),
		children: [
			{
				path: FRONT_PATH_NAMES.simulation,
				children: [
					{
						element: (
							<CompanyModuleGuard module={MOD_ECO_CONCEPTION}>
								<EcoConception />
							</CompanyModuleGuard>
						),
						index: true,
					},
					{
						path: ':simulationId',
						element: (
							<CompanyModuleGuard module={MOD_ECO_CONCEPTION}>
								<Scenario />
							</CompanyModuleGuard>
						),
					},
				],
			},
			{
				path: FRONT_PATH_NAMES.quote,
				children: [
					// {
					// 	element: (
					// 		<CompanyModuleGuard module={MOD_QUOTE}>
					// 			{' '}
					// 			<DevisCarbone />
					// 		</CompanyModuleGuard>
					// 	),
					// 	index: true,
					// },
					{
						path: ':quoteId',
						element: (
							<CompanyModuleGuard module={MOD_QUOTE}>
								<QuoteSheet />
							</CompanyModuleGuard>
						),
					},
					// {
					// 	path: 'client/:clientId',
					// 	element: (
					// 		<CompanyModuleGuard module={MOD_QUOTE}>
					// 			<ClientDevisList />
					// 		</CompanyModuleGuard>
					// 	),
					// 	children: [
					// 		{
					// 			path: ':tab',
					// 			element: null,
					// 		},
					// 	],
					// },
				],
			},
		],
	},
	{
		element: (
			<AuthGuard allowedRolesLabels={['administrateur']}>
				{/* // require company inside the session */}
				<MainLayout>
					<CompanySessionGuard />
				</MainLayout>
			</AuthGuard>
		),
		children: [
			{
				path: FRONT_PATH_NAMES.managerSettings,
				element: <ManagerSettings />,
				children: [
					{
						path: ':tab',
						element: null,
						index: true,
					},
				],
			},
		],
	},
];

export default userRoutes;
