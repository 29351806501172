import { useMemo } from 'react';

import { css, cx } from '@emotion/css';
import _ from 'lodash';

import { product, useCompareProduct } from '../../hooks/useCompareProduct';
import { siteColors } from '../../lib/colors';
import { compareProductConfig } from '../../utils/compareProduct';

import BarItem from './BarItem';

const styles = {

	container: css({
		height: '63vh',
		overflow: 'auto',
		paddingRight: '8px',
	}),
};

type CompareProductBarChartProps = {
	products: Array<product>,
};

const CompareProductBarChart = ({ products } : CompareProductBarChartProps) => {

	const { productToCompare, compareType, indicator } = useCompareProduct();

	// config for compare value
	const config = compareProductConfig.get(compareType)[indicator] as any;

	const dataChart = useMemo(() => {



		if (!products?.length) return [];

		const total = (productToCompare as any)[config.field];
		const max = _.maxBy(products, config.field);
		return products.map((product: product) => {
			const diff = (product as any)[config.field] - total;
			return {
				...product,
				percent: ((product as any)[config.field]  / ((max as any)[config.field] ||1)) * 100,
				percentDiff: (productToCompare as any).objectId === product.objectId ? 99 :  diff / total * 100,
			};
		});

	}, [products, productToCompare, config]);

	return (
		<div className={cx('flexColumn gap16 width100', styles.container)}>
			{ dataChart.map((product, index: number) => {
				return (<BarItem product={product} key={index} color={product.color} />);
			})}
		</div>
	);

};

export default CompareProductBarChart;
