import { SyntheticEvent, useEffect, useMemo } from 'react';

import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { pxToRem } from '@carbonmaps/ui/utils/styles';


import { ITab } from '../../../components/layout/page/SheetPageLayout';
import SEO from '../../../components/SEO';
import TabsBar, { TabItem } from '../../../components/TabsBar';
import { useTranslation } from '../../../hooks/useTranslation';
import { TOP_BAR_HEIGHT } from '../../../utils/constants';

import AuditHeader from './AuditsHeader';
import AuditDataSet from './tabs/dataset/AuditDataSet';
import AuditModelizations from './tabs/modelizations/AuditModelizations';
import AuditIngredientLCAs from './tabs/specific-lcas/AuditIngredientLCAs';
import AuditProductLCAs from './tabs/specific-lcas/AuditProductLCAs';

const CONTENT_WIDTH = 'calc(100% - 4rem)';

const AuditsGeneral = () => {
	const { t } = useTranslation();
	const { setBreadcrumbs } = useApp();

	const tabs: Record<string, ITab> = useMemo(() => {
		return {
			dataset: {
				title: t('Jeu de données'),
				element: <AuditDataSet />,
				tabName: t('Jeu de données'),
			},
			modelizations: {
				title: t('Modelizations'),
				element: <AuditModelizations />,
				tabName: t('Modelizations'),
			},
			'product-lcas': {
				title: t('product-lcas', { product: t('product') }),
				element: <AuditProductLCAs />,
				tabName: t('product-lcas', { product: t('product') }),
			},
			'ingredient-lcas': {
				title: t('ingredient-lcas', { ingredient: t('ingredient') }),
				element: <AuditIngredientLCAs />,
				tabName: t('ingredient-lcas', { ingredient: t('ingredient') }),
			},
		};
	}, [t]);

	const params = useParams();
	const currentTab = params.tab || _.keys(tabs)[0];

	// ---- breadCrumbs ---- //
	useEffect(() => {
		const title2 = _.get(tabs[currentTab], 'tabName');
		setBreadcrumbs([{ text: t('audits') }, ...(title2 ? [{ text: title2 }] : [])]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t, params.tab]);

	const navigate = useNavigate();


	const handleTabChange = (_: SyntheticEvent, value: string) => {
		navigate(`${FRONT_PATH_NAMES.audits.general}/${value}`);
	};

	const renderTabs = (
		<TabsBar
			value={currentTab}
			onChange={handleTabChange}
			sticky
			sx={{
				mb: pxToRem(50),
				width: CONTENT_WIDTH,
				mx: 'auto',
				top: TOP_BAR_HEIGHT,
				'&.sticky': { width: '100%', borderRadius: 0 },
				marginTop: 3,
				marginBottom: '0',
			}}
		>
			{_.entries(tabs).map(([tabKey, { title, disabled }]) => {
				return <TabItem key={tabKey} value={tabKey} label={title} disabled={disabled} />;
			})}
		</TabsBar>
	);

	const renderTabContent = (
		<div
			style={{
				width: CONTENT_WIDTH,
				margin: 'auto',
			}}
		>
			{tabs[currentTab]?.element}
		</div>
	);

	return (
		<>
			<SEO title={`${t('audits')  } - CarbonMaps`} />
			<AuditHeader title={t('audits')} />
			{renderTabs}
			{renderTabContent}
		</>
	);
};

export default AuditsGeneral;
