

import { css, cx } from '@emotion/css';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import { product, useCompareProduct } from '../../hooks/useCompareProduct';
import { compareProductConfig } from '../../utils/compareProduct';
import Typography from '../Typography';

import CompareItemValue from './CompareItemValue';
import ProductLink from './ProductLink';



const styles = {
	bar: css({
		height:45,
		width: 'var(--width)',
		backgroundColor: 'var(--bgColor)',
		borderRadius:2,
	}),
	value: css({
		minWidth: 100,
	}),
	link: css({
		textDecoration: 'none',
		':hover': {
			textDecoration: 'underline',
			textDecorationColor: 'var(--underlineColor)',
		},
	}),
};

type BarItemProps = {
	product: product;
	color: string;

}

const BarItem = ({ product, color }: BarItemProps) => {

	const { compareType, indicator } = useCompareProduct();

	const config = compareProductConfig.get(compareType)[indicator] as any;

	const { productToCompare , setProductToCompare } = useCompareProduct();

	return (
		<div className="flexRow gap16 width100 alignCenter">
			<div className="flexColumn gap8 flex1">
					<ProductLink product={product} color={color}>
						<Typography className="grey8 numeric">
							{product.label}
						</Typography>
					</ProductLink>

				<div className='flexRow gap6 alignCenter width100 nowrap'>
					<div className={styles.bar} style={{ ['--width' as any]: `calc(${Math.abs(product.percent)}% - 100px)`, ['--bgColor' as any]: color }} />
					<div className={cx('flexColumn', styles.value)}>
						<Typography className="grey9 numeric">{  formatNumber((product as any)[config?.field ||  ''], undefined , config.precision ) }</Typography>
						{config.unit}
					</div>
				</div>
			</div>
			<CompareItemValue value={product.percentDiff as never} onSelected={()=> {return setProductToCompare(product);}} isCompared={(productToCompare as any)?.objectId === product.objectId }/>
		</div>
	);
};

export default BarItem;
