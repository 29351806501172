import { create } from 'zustand';

import { siteColors } from '../lib/colors';
import { PER_KILO_TYPE } from '../utils/compareProduct';

export type product = {
	objectId: string;
	label: string;
	color: string;
	uid?:string;
	carbonIntensity?: number;
	waterIntensity?: number;
	carbonImpact?:number;
	waterImpact?:number;
	id?:string;
	intensity?: number;
	impact?: number;
	percent: number;
	percentDiff?:number;

}


export const useCompareProduct = create((set: any) => {
	return {
		compareType: PER_KILO_TYPE, // default value
		products: [],
		indicator: 'carbon',
		sortDirection: 'asc',
		productToCompare: null,
		searchParams: {
			input: '',
			facetFilters: [],
		},
		reset: 0,

		setCompareType: (type: string) => {
			return set({ compareType: type });
		},

		setProducts: (products: Array<product>) => {
			return set({ products: products });
		},

		addProduct: (product: product) => {
			return set((state: any) => {
				const productCount = state.products.length;

				return {
					products: [...state.products, { ...product, color: (siteColors.compareProductColors as any)[`color${productCount%5}`] }],
					...!state?.productToCompare && { productToCompare: product },
				};
			});
		},

		removeProduct: (product: product) => {
			return set((state: any) => {
				const currentProducts =  state.products.filter((p: product) => {return p.objectId !== product.objectId;});
				return {
					products: currentProducts,
					...!currentProducts?.length && { productToCompare: null },
				};
			});
		},

		setIndicator: (indicator: 'carbon' | 'water') => {
			return set({ indicator: indicator });
		},

		setSortDirection: (direction: 'asc' | 'desc') => {
			return set({ sortDirection: direction });
		},

		setProductToCompare: (product: product) => {
			return set({ productToCompare: product });
		},

		setSearchParams: (searchParams: any) => {
			return set({ searchParams: searchParams });
		},

		setReset: (reset: number) => {
			return set({  reset: reset });
		},
	};
});
