import { useEffect, useRef, useState } from 'react';

import { css, cx } from '@emotion/css';
import { useQuery } from '@tanstack/react-query';

import { getTotalImpactPerPeriod } from '@carbonmaps/ui/actions/dashboard.actions';

import { useYearSelection } from '../../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useSize } from '../../../hooks/useSize';
import DashboardLifeCycleGraph from '../DashboardLifeCycleGraph';
import Temporality from '../v2/Temporality';

import ImpactPerCategory from './ImpactPerCategory';
import SupplierImpact from './SupplierImpact';

// eslint-disable-next-line react-refresh/only-export-components
export const stylesAnalyze = { //use also in other bloc client
	container: css({
		maxWidth: '100%',
		paddingBottom: 20,
		containerType: 'inline-size',
		containerName: 'dashContent',
		'@container dashContent (max-width: 944px)': {
			'.dashContent-innerContainer': {
				display: 'flex',
				flexDirection: 'column',
			},
		},
	}),
	innerContainer: css({
		maxWidth: '100%',
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		gridColumnGap: 32,
		gridRowGap: 32,
	}),
};

const AnalyzeTab = () => {
	const [searchQueryParams] = useSearchQueryParams();
	const { selectedYear } = useYearSelection();

	const { data, isLoading } = useQuery({
		queryKey: [
			'getTotalImpactPerPeriod',
			{
				facetFilters: searchQueryParams?.facetFilters || [],
				period: selectedYear,
			},
		],
		queryFn: getTotalImpactPerPeriod,
	});

	const [graphWidth, setGraphWidth] = useState('100%');

	const ref = useRef(null);
	const size = useSize(ref as never);

	useEffect(() => {
		if (((size as DOMRectReadOnly | undefined)?.width || 0) > 944) {
			setGraphWidth('445px');
		} else {
			setGraphWidth('100%');
		}
	}, [size]);

	return (
		<>
			{data && data?.length === 1 && (
				<div className={cx('flexRow width100 backgroundWhite nowrap')}>
					<DashboardLifeCycleGraph />
				</div>
			)}
			<div ref={ref} className={cx(stylesAnalyze.container, 'width100')}>
				<div className={cx(stylesAnalyze.innerContainer, 'dashContent-innerContainer')}>
					{((data && data?.length > 1) || isLoading) && (
						<>
							<DashboardLifeCycleGraph iconSize={24} />
							<Temporality graphWidth={graphWidth} />
						</>
					)}
					<ImpactPerCategory />
					<SupplierImpact />
				</div>
			</div>
		</>
	);
};

export default AnalyzeTab;
