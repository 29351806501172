import { css, cx } from '@emotion/css';

import Typography from '../../../../components/Typography';
import Card from '../../../../components/v3/Card';
import { useTranslation } from '../../../../hooks/useTranslation';

const styles = {
	container: css({
		minHeight: '20px !important',
	}),
};

const DescriptionBlock = () => {
	const { t }= useTranslation();
return(
	<Card
			title={''}
			containerClassName={styles.container}
		>
			<div
				dangerouslySetInnerHTML={{
					__html: t('sbti-flag-decription-1'),
				}}
				className={cx('grey8')}
			/>
			<div
				dangerouslySetInnerHTML={{
					__html: t('sbti-flag-decription-2'),
				}}
				className={cx('grey8')}
			/>
		</Card>
);
};

export default DescriptionBlock;
