
import { functionName } from '@carbonmaps/shared/utils/constants';


export type ISupplerContact = {
	supplier: {
		objectId: string;
		uid: string;
		name: string;
	},
	contact: {
		// objectId: string;
		firstName: string;
		lastName: string;
		email: string;
		language: string;
	},
	language: string;
}

export async function createUsersForCampaignAction(params: {
	contacts: ISupplerContact[],
	languageCode: string;
	questionFormId: string;
}) {
	try {
		const { contacts: supplierContacts, ...other } = params;
		return await Parse.Cloud.run(functionName.createUsersForCampaign, { supplierContacts, ...other });
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function saveResponseQuestionnaireAction(params: {
	languageCode: string
}) {
	try {
		await Parse.Cloud.run(functionName.saveResponseQuestionnaire, { ...params });
	} catch (error) {
		return Promise.reject(error);
	}
}

/**
 * Retrieves data for the questionnaire form.
 * @param context - The context object containing query parameters.
 * @returns A Promise resolving to the questionnaire data.
 */
export async function getDataFormQuestionnaireAction(context: any) {
	try {
		const {
			queryKey: { 1: params },
		} = context;

		const result = await Parse.Cloud.run(functionName.getDataFormQuestionnaire);
		return result;
	} catch (error) {
		console.log('--------- getDataFormQuestionnaireAction error: ', error);
		return Promise.reject(error);
	}
}

/**
 * Adds a supplier to the pre-campaign process.
 * @param params - Object containing:
 *  - supplier: Supplier data to be added (structure depends on implementation).
 *  - questionFormId: Identifier of the question form associated with the supplier.
 * @returns Promise<void>
 */

export async function addSupplierPreCampaignAction(params: {
	supplier: any,
	questionFormId: string,
}) {
	try {
		await Parse.Cloud.run(functionName.questions.addSupplierPreCampaign, { ...params });
	} catch (error) {
		return Promise.reject(error);
	}
}

/**
 * Removes a supplier from the pre-campaign process.
 * @param params - Object containing:
 *  - supplier: Supplier data to be removed (structure depends on implementation).
 *  - questionFormId: Identifier of the question form associated with the supplier.
 * @returns Promise<void>
 */

export async function removeSupplierPreCampaignAction(params: {
	supplier: any,
	questionFormId: string,
}) {
	try {
		await Parse.Cloud.run(functionName.questions.removeSupplierPreCampaign, { ...params });
	} catch (error) {
		return Promise.reject(error);
	}
}

/**
 * Adds a supplier selection to the pre-campaign process.
 * @param params - Object containing:
 *  - supplier: Supplier data to be added (structure depends on implementation).
 *  - questionFormId: Identifier of the question form associated with the supplier.
 * @returns Promise<void>
 */
export async function addSuppliersSelectPreCampaignAction(params: {
	supplier: any,
	questionFormId: string,
}) {
	try {
		await Parse.Cloud.run(functionName.questions.addSuppliersSelectPreCampaign, { ...params });
	} catch (error) {
		return Promise.reject(error);
	}
}

/**
 * Updates the custom message for a specific question form.
 * @param params - Object containing:
 *  - langCustomEmail: Translated email content for different languages.
 *  - formattedValues: Processed values to be included in the message.
 *  - questionFormId: Identifier of the question form to update.
 * @returns Promise<void>
 */
export async function updateCustomMessageAction(params: {
	langCustomEmail: any, formattedValues: any, questionFormId: string
}) {
	try {
		const { langCustomEmail, formattedValues, questionFormId } = params;
		// Call cloud function to update the custom message with new content
		return await Parse.Cloud.run(functionName.questions.updateCustomMessage, { questionFormId, langCustomEmail, formattedValues });
	} catch (error) {
		return Promise.reject(error);
	}
}

/**
 * Removes contact from supplier campaign action.
 * @param params - Parameters including email, questionFormId, and supplierUid.
 * @returns Promise<void>
 */
export async function removeContactSupplierCampaignAction(params: {
	email: string, questionFormId: string, supplierUid: string
}) {
	try {
		const { email, questionFormId, supplierUid } = params;
		// Call cloud function to remove Contact for Supplier and one Campaign
		return await Parse.Cloud.run(functionName.questions.removeContactSupplierCampaign, { email, questionFormId, supplierUid });

	} catch (error) {
		return Promise.reject(error);
	}
}
