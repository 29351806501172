import { SyntheticEvent, useCallback, useEffect, useMemo } from 'react';

import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES, ITEM_NATURE_FOOD, ITEM_NATURE_NON_FOOD } from '@carbonmaps/shared/utils/constants';
import { MOD_NON_FOOD_PRODUCT } from '@carbonmaps/shared/utils/module';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { pxToRem } from '@carbonmaps/ui/utils/styles';
import { getPluginCompany } from '@carbonmaps/ui/utils/utils';

import { ITab } from '../../../components/layout/page/SheetPageLayout';
import SEO from '../../../components/SEO';
import TabsBar, { TabItem } from '../../../components/TabsBar';
import { useJuneTrack } from '../../../hooks/useJuneTrack';
import { useTranslation } from '../../../hooks/useTranslation';
import { useGetClientAuthQuery } from '../../../lib/react-query/features/auth/auth.hooks';
import { TOP_BAR_HEIGHT } from '../../../utils/constants';

import ProductsHeader from './ProductsHeader';
import AnalyzeTab from './tabs/analyze/AnalyzeTab';
import ProductsList from './tabs/products/ProductsList';


const CONTENT_WIDTH = 'calc(100% - 4rem)';

const ProductsGeneral = () => {
	const { t } = useTranslation();
	const { setBreadcrumbs } = useApp();
	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const tabs: Record<string, ITab> = useMemo(() => {
		const plugins = getPluginCompany(authData?.session?.company?.plugins ?? [], MOD_NON_FOOD_PRODUCT);

		const baseTabs:any = {
			analyse: {
				title: t('Analyse'),
				element: <AnalyzeTab />,
				tabName: t('Analyse'),
			},
			products: {
				title: plugins?.active ? t('tab-food-products', { products: t('products') }) : t('tab-products', { products: t('products') }),
				element: <ProductsList itemNature={ITEM_NATURE_FOOD} />,
				tabName: t('tab-products', { products: t('products') }),
			},
		};

		if (plugins?.active) {
			baseTabs['non-food-products'] = {
				title: t('tab-non-food-products', { products: t('products') }),
				element: <ProductsList itemNature={ITEM_NATURE_NON_FOOD} />,
				tabName: t('tab-non-food-products', { products: t('products') }),
			};
		}

		return baseTabs;
	}, [t, authData]);

	const params = useParams();
	const currentTab = params.tab || _.keys(tabs)[0];

	// ---- breadCrumbs ---- //
	useEffect(() => {
		const title2 = _.get(tabs[currentTab], 'tabName');
		console.log(_.get(tabs[currentTab], 'tabName'));
		setBreadcrumbs([{ text: t('products') }, ...(title2 ? [{ text: title2 }] : [])]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t, params.tab]);

	const navigate = useNavigate();

	// ---- event june ---- //
	const analytics = useJuneTrack();

	const trackEvent = useCallback(
		(name?: any, options?: any) => {
			if (!analytics) return;
			analytics.track(name, options, {
				context: { category: 'Analyse > product' },
			});
		},
		[analytics],
	);

	const trackEvent2 = useCallback(
		(name: string, options?: any) => {
			if (!analytics) return;
			analytics.track(name, options, {
				context: { category: 'Explore' },
			});
		},
		[analytics],
	);

	// ---- useEffect for track event land page packagings ---- //
	useEffect(() => {
		trackEvent2('Explore Land Products');
	}, [trackEvent]);

	const handleTabChange = (_: SyntheticEvent, value: string) => {
		trackEvent({
			SuppliersGeneralViewTabTarget: (tabs[value] as any)?.tabName,
		});
		navigate(`${FRONT_PATH_NAMES.products.general}/${value}`);
	};

	useEffect(() => {
		trackEvent('Explore dashboard Products');
	}, []);

	const renderTabs = (
		<TabsBar
			value={currentTab}
			onChange={handleTabChange}
			sticky
			sx={{
				mb: pxToRem(50),
				width: CONTENT_WIDTH,
				mx: 'auto',
				top: TOP_BAR_HEIGHT,
				'&.sticky': { width: '100%', borderRadius: 0 },
				marginTop: 3,
				marginBottom: '0',
			}}
		>
			{Object.entries(tabs).map(([tabKey, { title, disabled }]) => {
				return <TabItem key={tabKey} value={tabKey} label={title} disabled={disabled} />;
			})}
		</TabsBar>
	);

	const renderTabContent = (
		<div
			style={{
				width: CONTENT_WIDTH,
				margin: 'auto',
			}}
		>
			{tabs[currentTab]?.element}
		</div>
	);

	return (
		<>
			<SEO title={`${t('Products')} - CarbonMaps`} />
			<ProductsHeader />
			{renderTabs}
			{renderTabContent}
		</>
	);
};

export default ProductsGeneral;
