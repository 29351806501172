import { css, cx } from '@emotion/css';
import _ from 'lodash';

import { getConfigFacets } from '@carbonmaps/shared/utils/utils';
import { getDataImpactByCategoryAction } from '@carbonmaps/ui/actions/dashboard.actions';
import { getSBTiDataFacetAction } from '@carbonmaps/ui/actions/sbtiFlag.actions';

import CategoryCard from '../../../../components/v3/CategoryCard';
import { useTranslation } from '../../../../hooks/useTranslation';
import { useGetClientAuthQuery } from '../../../../lib/react-query/features/auth/auth.hooks';
import { moveItemToFront } from '../../../../utils/utils';

const styles = {
	container: css({
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		gap: 32,
	}),
};


const data = [
	{
		label: 'Crémerie',
		value: 20,
		volume:20,
		objetId: 'objetId',
		category: 'category',
	},
	{
		label: 'Viandes et poussons',
		value: 16.1,
		volume:20,
		objetId: 'objetId',
		category: 'category',
	},
	{
		label: 'Surgelés',
		value: 15.3,
		volume:20,
		objetId: 'objetId',
		category: 'category',
	},
	{
		label: 'Fruits et légumes',
		value: 10.6,
		volume:20,
		objetId: 'objetId',
		category: 'category',
	},
	{
		label: 'Épicerie sucrée',
		value: 4.5,
		volume:20,
		objetId: 'objetId',
		category: 'category',
	},
	{
		label: 'Traiteur',
		value: 3.4,
		volume:20,
		objetId: 'objetId',
		category: 'category',
	},
];

const CategoryContainer = () => {

	// ------------------------------------ //
	// ----------- facets ----------------- //
	// ------------------------------------ //
	const {
			result: { data: authData },
		} = useGetClientAuthQuery();
	const items = getConfigFacets(authData?.session.company, false, 'Product').slice(0, 10);
		const sortedItems = moveItemToFront(
			items,
			_.get(authData, 'session.company.category', _.get(authData, '0.field')) as never,
		);

	const { t }= useTranslation();

	return (
		<div className={cx('width100', styles.container)} >
			{sortedItems.map((item, key: number) => {
				return (
					<CategoryCard
						key={key}
						category={_.get(item, 'field', '---')}
						label={_.get(item, 'label', '---')}
						subtitle="Par impact SBTi FLAG"
						queryKey="getSBTiDataFacet"
						queryFn={getSBTiDataFacetAction}
						emptyMessageData={t('dashboard-empty-category')}
					/>
				);
			})}
		</div>
	);

};

export default CategoryContainer;
