import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { functionName } from '@carbonmaps/shared/utils/constants';
import { getProductsToCompareAction } from '@carbonmaps/ui/actions/product.actions';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import { useCompareProduct } from '../../../hooks/useCompareProduct';
import { useYearSelection } from '../../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { ProductRowData } from '../../quoteSheet/_/ProductsTableSelect';

/**
 *
 * @param param0
 * @returns
 */
export const useSearchProducts = ({ sorting, enabled }: { sorting?: any; enabled: boolean; }) => {
	const { selectedYear } = useYearSelection();
	const { searchParams } = useCompareProduct();
	const { facetFilters, input } = searchParams;
	const { indicator } = useApp();

	const result = useInfiniteQuery({
		queryKey: [
			'findProductsForDevisInfinite',
			{
				sorting, //refetch when sorting changes
				input,
				facetFilters,
				viewMode: indicator,
				selectedYear,
			},
		] as const,
		queryFn: async ({ pageParam = 0, queryKey }) => {
			const { input, facetFilters, viewMode, selectedYear } = queryKey[1];
			const data = await Parse.Cloud.run(functionName.product.searchProducts, { pageParam, period: selectedYear, input, facetFilters, sorting, viewMode }) as {
				products: ProductRowData[];
				meta: {
					totalCount: number;
					totalPages: number;
					currentPage: number;
				};
			};
			return data;
		},

		// options
		enabled,

		keepPreviousData: true,
		getNextPageParam: (lastPage, allPages) => {
			const nextPageCursor = lastPage.meta.currentPage + 1;

			if (nextPageCursor < lastPage.meta.totalPages) {
				return nextPageCursor;
			}

			return null;
		},
		getPreviousPageParam: (firstPage, allPages) => {
			const previousPageCursor = firstPage.meta.currentPage - 1;

			if (previousPageCursor < 0) {
				return null;
			}

			return previousPageCursor;
		},
	});

	return result;
};

/**
 * useProductsToCompare
 */
export const useProductsToCompare = () => {
	const [searchQueryParams] = useSearchQueryParams();
	const { selectedYear } = useYearSelection();

	const queryResult = useQuery({
		queryKey: [
			'getProductsToCompare',
			{
				productIds: searchQueryParams?.productToCompare?.trim().split(',') || [],
				period: selectedYear,
			},
		],
		queryFn: getProductsToCompareAction,
	});

	return queryResult;
};
