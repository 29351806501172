import { css, cx } from '@emotion/css';

import { ReactComponent as PolygonDown } from '@carbonmaps/media/icons/polygonDown.svg';
import { ReactComponent as PolygonUp } from '@carbonmaps/media/icons/polygonUp.svg';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import Typography from '../Typography';

const styles = {
	container: css({
		padding: 4,
		borderRadius: 4,
	}),
	negative: css({
		background: siteColors.jobGreen10,
		color: siteColors.jobGreen100,
	}),
	positive: css({
		background: siteColors.jobOrange10,
		color: siteColors.warning,
	}),
	comparedText: css({
		textTransform: 'uppercase',
		fontSize: '10px !important',
		lineHeight: '13px !important',
	}),
};

type ItemValuePercentProps = {
	value: number;
	isSelected: boolean;
}

const ItemValuePercent = ({ value, isSelected }:ItemValuePercentProps) => {
	const { t } = useTranslation();

	const isNegative = value < 0;

	return (
		isSelected
? <Typography className={cx('grey8', styles.comparedText)}>{t('Comparer à')}</Typography>:
		<div className={cx('flexRow gap4 alignCenter', styles.container, isNegative ? styles.negative : styles.positive)}>
			{ isNegative ? <PolygonUp /> : <PolygonDown /> }

				{formatNumber(Math.abs(value), undefined, 0)} %
		</div>
	);
};

export default ItemValuePercent;
