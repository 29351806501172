import { css, cx } from '@emotion/css';
import { CircularProgress, type PopoverOrigin } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useToggle } from 'react-use';

import LoadingDialog from '../../../../apps/front/src/components/dialogs/LoadingDialog';
import Popover from '../../../../apps/front/src/components/Popover';
import { useTranslation } from '../../../../apps/front/src/hooks/useTranslation';
import { cn, siteColors } from '../../../../apps/front/src/lib/colors';

interface Props {
	title: string;
	children: React.ReactNode;
	onClick: (close?: VoidFunction) => void;
	loadingDelete?: boolean;
	subMessageConfirmDelete?: string;
	className?: string;
	anchorOrigin?: PopoverOrigin;
	transformOrigin?: PopoverOrigin;
	confirmText?: string;
	anchorEl?: Element | undefined;
}
const styles = {
	content: css({
		padding: '24px',
		width: '297px',
		borderRadius: '8px',
		border: cn('1px solid', siteColors.grey500),
		background: '#FFFFFF',
		boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.4), 0px 1px 2px rgba(0, 0, 0, 0.45)',
	}),
};

const DeleteConfirm = ({
	title,
	onClick,
	children,
	loadingDelete = false,
	subMessageConfirmDelete,
	anchorOrigin = {} as never,
	transformOrigin = {} as never,
	confirmText,
	anchorEl,
}: Props) => {
	const [isClose, toggleClose] = useToggle(true);
	const { t } = useTranslation();

	return (
		<Popover
			isClose={isClose}
			anchorEl={anchorEl}
			anchorOrigin={{ vertical: anchorOrigin.vertical || 'bottom', horizontal: anchorOrigin.horizontal || 'right' }}
			transformOrigin={{
				vertical: transformOrigin.vertical || 'top',
				horizontal: transformOrigin.horizontal || 'right',
			}}
			trigger={children as never}
			renderContent={({ close }) => {
				return (
					<div className={cx('combo-box', styles.content)}>
						<Typography
							textAlign={'left'}
							sx={{
								fontWeight: 600,
								fontSize: 16,
							}}
						>
							{title}
						</Typography>

						<Typography textAlign={'left'}>{subMessageConfirmDelete ?? 'Cette action est irréversible'}</Typography>

						<div css={{ display: 'flex', flexDirection: 'column', gap: 20, width: '100%', marginTop: '16px' }}>
							<Button
								onClick={() => {
									onClick?.(close);
									setTimeout(() => {
										toggleClose();
									}, 300);
								}}
								sx={{
									fontSize: '16px',
									fontWeight: 600,
									textTransform: 'none',
									gap: 10,
									color: '#C74600',
									background: '#FFE4CB',
									':hover': {
										background: '#FFCA99',
									},
									'&.Mui-disabled': {
										color: '#C74600',
										background: '#FFE4CB',
									},
									borderRadius: '8px',
								}}
							>
								{loadingDelete
								? (
									<CircularProgress size={22} sx={{ color: '#C74600' }} />
								)
								: confirmText
								? (
									confirmText
								)
								: (
									'Confirmer la suppression'
								)}
							</Button>
						</div>
						<LoadingDialog open={loadingDelete} content={`${t('Suppression en cours')}...`} />
					</div>
				);
			}}
		>
			{/* {children} */}
		</Popover>
	);
};

export default DeleteConfirm;
