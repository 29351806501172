import { useMemo, useRef } from 'react';

import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { alpha } from '@mui/system/colorManipulator';
import _ from 'lodash';
import { RefreshCw } from 'lucide-react';
import { useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES, ITEM_NATURE_NON_FOOD, WORDING_TERMS } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useProductInfo, useProductLifeChart } from '@carbonmaps/ui/hooks/useGetProductSheet';
import { WATER_INDICATOR } from '@carbonmaps/ui/utils/constants';
import { pxToRem } from '@carbonmaps/ui/utils/styles';

import BlockCard from '../../../components/BlockCard';
import BlockTitle from '../../../components/BlockTitle';
import LightTooltipGlossary from '../../../components/LightTooltipGlossary';
import LifeCycleChart from '../../../components/charts/LifeCycleChart';
import LifeCycleChartTooltip from '../../../components/charts/LifeCycleChartTooltip';
import { useGlossary } from '../../../hooks/useGlossary';
import { useQueryParams } from '../../../hooks/useSearchParams';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useSize } from '../../../hooks/useSize';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import { CARBON_INDICATOR } from '../../../utils/constants';
import { WaterUnit } from '../../../utils/graphIndicator';

const LifeCycleBlock = () => {
	const { indicator } = useApp();
	const theme = useTheme();
	const params = useParams();

	const { data: productInfo } = useProductInfo({ objectId: _.toString(params.productId), viewMode: indicator });

	const containerRef = useRef(null);
	const size = useSize(containerRef);
	const svgParentSize = useMemo(() => {
		if (!size)
			return {
				width: 730,
				height: 230,
			};

		const height = 195;

		return {
			width: (size as any)?.width || 730,
			height,
		};
	}, [size]);

	// ---- fetch data chart ---- //
	const [searchQueryParams] = useSearchQueryParams();
	const { data } = useProductLifeChart({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		productId: params.productId!,
		viewMode: indicator,
		uid: searchQueryParams?.uid,
	});

	// translation
	const { t } = useTranslation();
	// glossary
	const { g } = useGlossary();

	// get the values in percentage
	const getGESInPercent = () => {
		if (!data) return Array.from<{ value: number; link?: string }>({ length: 6 }).fill({ value: 0, link: '#' });

		const input = [
			{
				value: data?.gesAgriculture,  // Utilisation de ?? pour une valeur par défaut
				tooltip: <LifeCycleChartTooltip value={data?.gesAgriculture} />,
				link: `${FRONT_PATH_NAMES.products.details}/${params.productId!}/ingredients`,
			},
			{
				value: data?.gesTransformation,
				tooltip: <LifeCycleChartTooltip value={data?.gesTransformation} />,
				link: `${FRONT_PATH_NAMES.products.details}/${params.productId!}/ingredients`,
			},
			{
				value: data?.gesPackaging,
				tooltip: <LifeCycleChartTooltip value={data?.gesPackaging} />,
				link: `${FRONT_PATH_NAMES.products.details}/${params.productId!}/packaging`,
			},
			{
				value: data?.gesTransport,
				tooltip: <LifeCycleChartTooltip value={data?.gesTransport} />,
			},
			{
				value: data?.gesDistribution,
				tooltip: <LifeCycleChartTooltip value={data?.gesDistribution} />,
			},
			{
				value: data?.gesConsumption,
				tooltip: <LifeCycleChartTooltip value={data?.gesConsumption} />,
			},
		];

		// Calculer la somme des valeurs avec valeur par défaut
		const totalValue = _.sumBy(input, item => { return item.value ?? 0; });

		return input.map((e) => {
			return {
				...e,
				//value: ((e.value || 0) * 100) / (data?.gesAgriculture || 1), // to avoid percent sup 100
				value: totalValue !== 0 ? ((e.value || 0) * 100) / totalValue : 0,
				link: (productInfo as any)?.['itemNature'] !== ITEM_NATURE_NON_FOOD ? e.link : undefined,
			};
		});
	};



	const getWaterUseInPercent = () => {
		if (!data) {
			return Array.from<{ value: number; link?: string }>({ length: 6 }).fill({ value: 0, link: '#' });
		}

		const input = [
			{
				value: data?.waterUseAgriculture,
				tooltip: (
					<LifeCycleChartTooltip value={data?.waterUseAgriculture} unit={WaterUnit} label={WORDING_TERMS.INTENSITY} />
				),
				link: `${FRONT_PATH_NAMES.products.details}/${params.productId!}/ingredients`,
			},
			{
				value: data?.waterUseTransformation,
				tooltip: (
					<LifeCycleChartTooltip
						value={data?.waterUseTransformation}
						unit={WaterUnit}
						label={WORDING_TERMS.INTENSITY}
					/>
				),
				link: `${FRONT_PATH_NAMES.products.details}/${params.productId!}/ingredients`,
			},
			{
				value: data?.waterUsePackaging,
				tooltip: (
					<LifeCycleChartTooltip value={data?.waterUsePackaging} unit={WaterUnit} label={WORDING_TERMS.INTENSITY} />
				),
				link: `${FRONT_PATH_NAMES.products.details}/${params.productId!}/packaging`,
			},
			{
				value: data?.waterUseTransport,
				tooltip: (
					<LifeCycleChartTooltip value={data?.waterUseTransport} unit={WaterUnit} label={WORDING_TERMS.INTENSITY} />
				),
			},
			{
				value: data?.waterUseDistribution,
				tooltip: (
					<LifeCycleChartTooltip value={data?.waterUseDistribution} unit={WaterUnit} label={WORDING_TERMS.INTENSITY} />
				),
			},
			{
				value: data?.waterUseConsumption,
				tooltip: (
					<LifeCycleChartTooltip value={data?.waterUseConsumption} unit={WaterUnit} label={WORDING_TERMS.INTENSITY} />
				),
			},
		];
		// Calculer la somme des valeurs avec valeur par défaut
		const totalValue = _.sumBy(input, item => { return item.value ?? 0; });
		return input.map((e) => {
			return {
				...e,
				//	value: ((e.value || 0) * 100) / (data?.waterIntensity || 1),
				value: totalValue !== 0 ? ((e.value || 0) * 100) / totalValue : 0,
				link: (productInfo as any)?.['itemNature'] !== ITEM_NATURE_NON_FOOD ? e.link : undefined,
			};
		});
	};

	return (
		<>
			<BlockTitle icon={<RefreshCw color={siteColors.grey700} size={20} />}>
				<LightTooltipGlossary title={g('cycle-de-vie')}>
					<span className={'underline'}>{t('product-life-cycle-title')}</span>
				</LightTooltipGlossary>
				<Typography display="inline" ml={pxToRem(7)} fontWeight={500} color={theme.palette.grey[900]}>
					{t('product-life-cycle-subtitle')}
				</Typography>
			</BlockTitle>
			<div ref={containerRef}>
				<BlockCard>
					<LifeCycleChart
						datas={indicator === CARBON_INDICATOR ? getGESInPercent() : getWaterUseInPercent()}
						viewBox={`0 0 ${svgParentSize.width} ${svgParentSize.height}`}
						withLabel={true}
						iconColor={siteColors.grey700}
						colors={
							indicator === WATER_INDICATOR
								? {
									main: siteColors.water500,
									polygonGradient: [
										siteColors.water100,
										siteColors.water100,
									],
								}
								: undefined
						}
						areaHeight={svgParentSize.height}
						areaWidth={svgParentSize.width}
						iconSize={24}
						labelColor={indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500}
						strokeWidth={1.5}
						lineWidth={2}
						fontStyles={{
							title: 12,
							subtitle: 12,
						}}
						topValueContainer={{
							width: 70,
							height: 30,
						}}
						itemNature={(productInfo as any)?.['itemNature']}
					/>
				</BlockCard>
			</div>
		</>
	);
};

export default LifeCycleBlock;
