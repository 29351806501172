import { useEffect } from 'react';

import { css, cx } from '@emotion/css';

import { useCompareProduct } from '../../../hooks/useCompareProduct';
import { siteColors } from '../../../lib/colors';

import CompareProductContent from './CompareProductContent';
import CompareProductHeader from './CompareProductHeader';
import CompareProductMenu from './CompareProductMenu';
import { useProductsToCompare } from './hooks';

const styles = {
	container: css({
		position: 'fixed',
		top: 0,
		left: 2,
		right: 0,
		bottom: 0,
		zIndex: 1300,
		width: '95%',
		// height: '100vh',
		background: siteColors.backgroundDrawer,
		boxShadow: '0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.08)',
	}),
};

type CompareProductLayoutProps = {
	onClose: () => void;
}

const CompareProductLayout = ({ onClose }: CompareProductLayoutProps) => {

	const { setProducts, setProductToCompare } = useCompareProduct();

 const { data } = useProductsToCompare();

 // --------------------------------------- //
 // --------- set compare to products ----- //
 // --------------------------------------- //
 useEffect(() => {
	if (!data?.length) return;

	setProductToCompare(data[0]);
	setProducts(data.map((p: any, index: number) => {
		return {
			...p,
			color: (siteColors.compareProductColors as any)[`color${index%5}`],
		};
	}));
 }, [data, setProducts, setProductToCompare]);


	return (
		<div className={cx('flexRow width100', styles.container)}>
			<CompareProductHeader onClick={onClose} />
			<CompareProductMenu />
			<CompareProductContent/>
		</div>
	);
};

export default CompareProductLayout;
