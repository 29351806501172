import { useCallback, useEffect, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { createColumnHelper, type SortingState } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { type TFunction } from 'i18next';
import _ from 'lodash';

import { FRONT_PATH_NAMES, functionName } from '@carbonmaps/shared/utils/constants';
import { getIsoCountryCode } from '@carbonmaps/ui/utils/utils';

import { SupplierIcon } from '../../../../../../components/_comp/ItemIcon';
import Table from '../../../../../../components/_comp/Table';
import TableHeadCell from '../../../../../../components/_comp/TableHeadCell';
import TableHeadCellSection from '../../../../../../components/_comp/TableHeadCellSection';
import LabelLogo from '../../../../../../components/question/LabelLogo';
import Skeleton from '../../../../../../components/Skeleton';
import SupplierScore from '../../../../../../components/supplier/SupplierScore';
import { useJuneTrack } from '../../../../../../hooks/useJuneTrack';
import { useSearchQueryParams } from '../../../../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../../../../hooks/useTranslation';
import { siteColors } from '../../../../../../lib/colors';
import { useFindQuestionFormsTabs, useFindRseTableData, useRseQFormTabQueryParam } from '../hooks/rse.hooks';

type GetColumnsProps = {
	t: TFunction;
	sections: { code: string; labelFr: string; labelEn: string }[];
	locale: string;
	trackEvent?: (...args: any[]) => void;
	qFormId?: string;
};

const tableStyles = {
	main: css({
		width: '100%',
		borderCollapse: 'collapse',
		'& tr': {
			height: '60px',
			alignSelf: 'stretch',
		},
		'& tbody tr': {
			borderTop: '1px solid #E2DDEB',
		},

		marginBottom: '54px',

		'th:nth-child(n+3), td:nth-child(n+3)': {
			width: '175px',
		},

		'.table-header-component-label-section': {
			maxWidth: '150px',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			marginLeft: '.5rem',
		},
		'th:nth-of-type(2), td:nth-of-type(2)': {
			paddingRight: '20px',
		},

		'tr:has([data-row-type="answers-delimiter"])': {
			'& td:hover': {
				background: 'unset!important',
			},
			'& td:nth-of-type(n+2)': {
				display: 'none',
			},
		},
		thead:{
			backgroundColor: '#fff',
			zIndex: 100,
			position: 'sticky',
			top: '118px',
		},
	}),
	hiddenVisibility: css({
		visibility: 'hidden',
	}),
};

const columnHelper = createColumnHelper<any>(); //todo type

const getColumns = ({ t, sections, locale, trackEvent, qFormId }: GetColumnsProps) => {
	const sectionColumns = sections.map((section) => {
		return columnHelper.accessor(section.code, {
			header: (info) => {
				const siteLocaleMap = {
					en: 'En',
					fr: 'Fr',
				};

				return (
					<TableHeadCellSection
						info={info}
						label={t(_.get(section, `label${_.get(siteLocaleMap, locale, '---')}`, '---'))}
						valueType="number"
					/>
				);
			},
			cell: (info) => {
				if (info.row.original.unAnswered) {
					return null;
				}

				return <SectionPercent percentValue={info.row.original[`${section.code}_percent`]} />;
			},
		});
	});

	return [
		columnHelper.accessor('supplierName', {
			header: (info) => {
				return <TableHeadCell info={info} label={t('supplier-label')} valueType="string" />;
			},
			cell: (info) => {
				if (_.isEqual(info.row.original.objectId, 'csr-answers-delimiter')) {
					return <div data-row-type="answers-delimiter" className={cx('grey700')}>
						{t('answers-separator-text')}
					</div>;
				}

				return (
					<LabelLogo
						label={info.row.original.supplierName}
						uid={info.row.original.supplierUid}
						icon={<SupplierIcon />}
						withLink
						getLink={() => {
							return (
								`${FRONT_PATH_NAMES.suppliers.details}/${info.row.original.objectId}/rse-performance` +
								`?rse:questionFormId=${qFormId}`
							);
						}}
						onClick={() => {
							trackEvent?.('Supplier Performance Land', { SupplierLabel: info.row.original.supplierName });
						}}
						popoverContent={
							info.row.original.unAnswered
? null
: (<AnswerDatePopoverContent
								answerFromUserId={info.row.original.answerFromUserId}
								submitDate={_.get(info.row.original, 'formattedSubmitDate')}
							/>)
						}
					/>
				);
			},
		}),

		columnHelper.accessor('score', {
			header: (info) => {
				return <TableHeadCell info={info} label={t('supplier-note')} valueType="number" />;
			},
			cell: (info) => {
				return <SupplierScore score={info.row.original.note} awaitingResponse={info.row.original.unAnswered} />;
			},
		}),

		...sectionColumns,
	];
};

const SupplierRSETable = () => {
	const { t, i18n } = useTranslation();
	const [qFormId] = useRseQFormTabQueryParam();
	const [sorting, setSorting] = useState<SortingState>([]);

	// all about pagination
	const [pageSize, setPageSize] = useState(100);
	const [currentPage, setCurrentPage] = useState(1);

	const { data: tabsData } = useFindQuestionFormsTabs();

	const { data: tableData, isLoading: isLoadingTableData } = useFindRseTableData({
		questionFormId: _.toString(qFormId),
		sorting,
		page: currentPage,
		pageSize,
	});

	const locale = getIsoCountryCode(i18n.language);

	const analytics = useJuneTrack();

	//---- track event page ---- //
	const trackEvent = useCallback(
		(evenName: string, options?: any) => {
			if (!analytics) return;
			analytics.track(evenName, options, {
				context: { category: 'Explore' },
			});
		},
		[analytics],
	);

	const trackNextPage = useCallback(() => {
		trackEvent('Explore Suppliers CSR Performance Next Page');
	}, [trackEvent]);

	const trackPreviousPage = useCallback(() => {
		trackEvent('Explore Suppliers CSR Performance Previous Page');
	}, [trackEvent]);

	const columns = useMemo(() => {
		const sections = _.get(tabsData, `${qFormId}.sections`, []);
		return getColumns({
			t: t as never,
			sections,
			locale,
			trackEvent,
			qFormId: qFormId as never,
		});
	}, [tabsData, qFormId, t, locale, trackEvent]);

	const totalPageCount = _.get(tableData, 'meta.totalPageCount', 0);

	useEffect(() => {
		const element = document.querySelector('td:has([data-row-type="answers-delimiter"])');

		if (element) {
			element.setAttribute('colSpan', _.toString(columns.length));
		}

		return () => {
			if (element) {
				element.removeAttribute('colSpan');
			}
		};
	}, [columns.length, tableData, qFormId]);

	return (
		<div className="flexColumn width100">
			<Typography variant="h3" className="">
				{t('performance-per-supplier')}
			</Typography>

			<Table
				isLoading={isLoadingTableData}
				columns={columns}
				data={_.map(_.get(tableData, 'data', []), (item: any) => {
					let formattedSubmitDate = '-';

					const submittedAt = _.get(item, 'submittedAt');

					if (submittedAt && _.isDate(submittedAt)) {
						formattedSubmitDate = dayjs(submittedAt).format('DD/MM/YYYY');
					}

					return {
						...item,
						formattedSubmitDate,
					};
				})}
				tableProps={{
					className: tableStyles.main,
				}}
				manualSorting
				sortingState={sorting}
				onSortingChange={setSorting}
				// = pagination props
				manualPagination
				pagination
				pageSize={pageSize}
				setPageSize={setPageSize}
				currentPage={currentPage}
				pageCount={totalPageCount}
				canNextPage={currentPage < totalPageCount}
				nextPage={() => {
					trackNextPage();
					setCurrentPage((prev) => {
						return prev + 1;
					});
				}}
				canPreviousPage={currentPage > 1}
				previousPage={() => {
					trackPreviousPage();
					setCurrentPage((prev) => {
						return prev - 1;
					});
				}}
				gotoPage={(pageNum) => {
					setCurrentPage(pageNum);
				}}
			/>
		</div>
	);
};

export default SupplierRSETable;

const cellColors = {
	100: '#d5f1e9',
	90: '#dbf0dd',
	70: '#eaf2d8',
	50: '#fcf2d9',
	30: '#fcf2d9',
	15: '#fbdfd5',
};

const getColor = (value: number) => {
	const keys = _.chain(cellColors).keys().map(Number).sortBy().reverse().value();

	// Find the first key that is less than or equal to the value
	const key = _.find(keys, (k) => {
		return value >= k;
	});

	// Return the corresponding color or a default value if none is found
	return (cellColors as any)[key as any] || siteColors.grey300;
};

const cellStyles = {
	grabColor: css({
		backgroundColor: `var(--percent-color, ${siteColors.grey300})`,
	}),
	fullHeight: css({
		height: '33px !important',
	}),
};

const SectionPercent = ({ percentValue }: { percentValue: any }) => {
	const _value = _.toNumber(percentValue);
	const displayValue = _.isNaN(_value) ? 0 : _value;

	const bgColor = getColor(displayValue);

	return (
		<div
			className={cx('flexRow width100 justifyCenter alignCenter', cellStyles.fullHeight, cellStyles.grabColor)}
			style={{ ['--percent-color' as never]: bgColor }}
		>
			<div>{displayValue} %</div>
		</div>
	);
};

const AnswerDatePopoverContent = ({
	answerFromUserId,
	submitDate,
}: {
	answerFromUserId?: string;
	submitDate: string;
}) => {
	const { t } = useTranslation();

	const { data, isLoading } = useQuery({
		queryKey: [functionName.suppliers.rse.getAnswerDateInfo, { objectId: answerFromUserId }] as const,
		queryFn: async (ctx) => {
			try {
				const { objectId } = ctx.queryKey[1];

				if (!answerFromUserId) {
					return {
						fullName: '---',
					};
				}

				return Parse.Cloud.run(functionName.suppliers.rse.getAnswerDateInfo, { objectId });
			} catch (error) {
				return Promise.reject(error);
			}
		},
	});

	if (isLoading) {
		return (
			<div css={{ padding: 16 }}>
				<Skeleton width={120} height={40} />
			</div>
		);
	}

	const fName = _.get(data, 'fullName');
	const ok = !_.isEqual(fName, '---');
	return (
		<div css={{ padding: 16 }}>
			{ok
? (
				<div css={{ marginBottom: 6 }}>
					{t('Répondu par')} <span css={{ fontWeight: 'bold' }}>{fName}</span>
				</div>
			)
: null}
			<div>
				{t('Réponse reçue le')} {submitDate}
			</div>
		</div>
	);
};
