import { useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MessageCircle } from 'lucide-react';

// import { AddCommentAction } from '@carbonmaps/ui/actions/apbo.actions';

import { useComments, useYearOptions } from '../../containers/apbo/useApbo.hook';
import { siteColors } from '../../lib/colors';
import { AddCommentAction } from '../../lib/react-query/features/apbo/apbo.actions';
import Typography from '../Typography';

import CommentFormModal from './CommentFormModal';

const styles = {
	container: css({
		cursor: 'pointer',
	}),
};

type CommentProps = {
	messageKey: string;
};

const Comment = ({ messageKey }: CommentProps) => {
	const [open, setOpen] = useState(false);
	const { value: year } = useYearOptions();

	const queryClient = useQueryClient();

	const { data } = useComments();

	const comment = useMemo(() => {
		if (!data) return [];

		return data.find((item: any) => {
			return item?.key === messageKey;
		});
	}, [data, messageKey]);

	// ---- add comment ---- //
	const { mutate: addComment } = useMutation({
		mutationKey: ['addComment'],
		mutationFn: AddCommentAction,
		onSuccess: async () => {
			queryClient.invalidateQueries({ queryKey: ['getComments'] });
			setOpen(false);
		},
		onError: (error: any) => {
			setOpen(false);
		},
	});

	const onSaveComment = (values: { message: string }) => {
		addComment({ ...values, key: messageKey, year });
	};

	return (
		<>
			<div
				className={cx('flexRow alignCenter nowrap gap8', styles.container)}
				onClick={() => {
					return setOpen(true);
				}}
			>
				<MessageCircle color={siteColors.grey700} size={20} />
				<Typography className="grey700 fontWeight600">Commentaire</Typography>
			</div>
			<CommentFormModal
				open={open}
				onSubmit={onSaveComment}
				onClose={() => {
					return setOpen(false);
				}}
				value={comment?.message}
			/>
		</>
	);
};

export default Comment;
