import { useCallback, useEffect, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { AlertCircle, ArrowLeft, CheckCircle2, Circle, FileDown } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';
import { read, utils } from 'xlsx';

import { InfoMessageContact, UserContact } from '@carbonmaps/shared/types/supplier.types';
import { FRONT_PATH_NAMES, functionName } from '@carbonmaps/shared/utils/constants';
import { formatDataSupplier, isValidEmail, isWhitespace } from '@carbonmaps/shared/utils/utils';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import BDropdown from '@carbonmaps/ui/components/saas/BDropdown';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { getIsoCountryCode } from '@carbonmaps/ui/utils/utils';

import AlertContactUpload from '../../../../../../components/dialogs/AlertContactUpload';
import Dialog from '../../../../../../components/dialogs/Dialog';
import ImportDialog from '../../../../../../components/dialogs/ImportDialog';
import AlertStatic from '../../../../../../components/Toast/AlertStatic';
import { useTranslation } from '../../../../../../hooks/useTranslation';
import { cn, siteColors } from '../../../../../../lib/colors';
import { getClientAuthKey } from '../../../../../../lib/react-query/features/auth/auth.actions';
import { useGetClientAuthQuery } from '../../../../../../lib/react-query/features/auth/auth.hooks';
import { createUsersForCampaignAction } from '../../../../../../lib/react-query/features/question/question.actions';
import { fetchSuppliersAndContact, useGetQuestionFormById, useQuestionFormStore } from '../../../../questionForms.hooks';

import QuestionFormSuppliersTable, {
	getContactStringValue,
	SupplierContactRowData,
} from './QuestionFormSuppliersTable';
import QuestionFormSuppliersTableSelect from './QuestionFormSuppliersTableSelect';

const flattenContacts = (supplierContacts: SupplierContactRowData[]) => {
	return supplierContacts
		.map((e) => {
			return e.subRows.map((i) => {
				return {
					supplier: {
						objectId: e.id,
						uid: e.supplierUid,
						name: e.supplierName,
					},
					contact: {
						firstName: _.toString(i.contactFirstName),
						lastName: _.toString(i.contactLastName),
						email: _.toString(i.contactEmail),
						language: _.toString(i.contactLanguage),
						emailLanguage: _.toString(i.contactLanguage),
					},
					language: _.toString(i.contactLanguage),
				};
			});
		})
		.flat();
};

const styles = {
	root: css({
		//
		width: 700,
		margin: '0 auto',
		padding: '48px 32px',

		gap: 32,
	}),

	text1: css({
		color: siteColors.text,
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '24px',
		fontStyle: 'normal',
		fontWeight: 600,
		lineHeight: 'normal',

		marginLeft: 16,
	}),
	component1: css({
		height: '72px',
		padding: '24px',
		gap: '8px',
		alignSelf: 'stretch',

		borderRadius: '16px',
		border: cn('1px solid', siteColors.grey500),
		cursor: 'pointer',
	}),

	text2: css({
		flexGrow: 1,
	}),

	text3: css({
		marginLeft: 8,
	}),

	btnDisabled: css({
		// backgroundColor: siteColors.grey500,
		opacity: '0.5 !important',
		cursor: 'not-allowed !important',
	}),

	mr8: css({
		marginRight: 8,

		color: siteColors.grey800,
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: 600,
		lineHeight: '150%',
	}),
};

export const stylesPrepareQuestion = {
	main: css({
		padding: '24px 32px',
		gap: 32,
	}),
	hidden: css({
		visibility: 'hidden',
	}),
	link: css({
		fontSize: '14px !important',
		color: cn(siteColors.primary, '!important'),
	}),
	fileInput: css({
		input: {
			opacity: 0,
			position: 'absolute',
			width: '402px',
			height: '200px',
			cursor: 'pointer',
		},
		fontSize: '14px !important',
		color: cn(siteColors.primary, '!important'),
		width: '402px',
		height: '200px',
		justifyContent: 'center !important',
		alignItems: 'center !important',
		textAlign: 'center',
		padding: '8px 16px',
		border: cn('1px dashed', siteColors.grey500),
		borderRadius: '8px',
		transition: 'all .2s',
		'&:hover': {
			border: cn('1px dashed', siteColors.primary),
			background: siteColors.primary10,
		},
	}),
};


const QuestionFormSuppliers = () => {
	const { t, i18n } = useTranslation();
	const [screen, setScreen] = useState(0);
	const [count, setCount] = useState(0);
	const { setBreadcrumbs } = useApp();
	const languageCode = getIsoCountryCode(i18n.language);
	const params = useParams();
	const { data: questionForm } = useGetQuestionFormById({ id: _.toString(params?.questionFormId) });

	const { setSuppliersContacts } = useQuestionFormStore();
	const navigate = useNavigate();
	const handleClick = useCallback(() => {
		setSuppliersContacts([]);
		navigate(`${FRONT_PATH_NAMES.questionForms}/general`);
	}, [navigate, setSuppliersContacts]);

	useEffect(() => {
		setBreadcrumbs([
			{
				text: t('Collecter'),
				link: `${FRONT_PATH_NAMES.questionForms}/general`,
			},
			{
				text: _.get(questionForm, `translation.${languageCode || 'fr'}.name`) || '---',
			},
		]);
	}, [t, setBreadcrumbs, questionForm]);

	return (
		<div className={cx('')}>
			<TopBar screen={screen} setScreen={setScreen} setCount={setCount} />
			<div className={cx('flexColumn', stylesPrepareQuestion.main)}>
				<Note screen={screen} />
				{screen === 2 ?
					<div className={cx('flexColumn', styles.root)}>
						<div className={cx('alignCenter width100 textAlignCenter')}>
							<CheckCircle2 size={33} color={siteColors.green500} />
						</div>
						<div className={cx('flexColumn alignCenter width100 textAlignCenter gap16')}>
							<div className={cx('', styles.text1)}>{t('Questionnaire envoyé')}</div>
							<div>
								{' '}
								{count} {t('questionnaire-info-send')}
							</div>
						</div>
						<div className={cx('textCenter width100')}>
							<BButton onClick={handleClick} label={t('Terminer')} variant="secondary" />
						</div>
					</div> :

					<QuestionFormSuppliersTable />
				}

			</div>
		</div>
	);
};

export default QuestionFormSuppliers;

export const topStyles = {
	main: css({
		height: 80,
		borderBottom: cn('1px solid', siteColors.grey500),
		paddingLeft: '24px',
		paddingRight: '24px',
	}),
	text1: css({
		color: siteColors.text,
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: 600,
		lineHeight: 'normal',
		marginBottom: 4,
	}),
	text2: css({
		color: siteColors.grey900,
		leadingTrim: 'both',
		textEdge: 'cap',
		fontFamily: 'Inter',
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: '140%',
	}),
	leftGroup: css({
		flexGrow: 1,
	}),
	text3: css({
		marginLeft: 12,
		color: siteColors.grey900,
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: '150%',
	}),
	text4: css({
		fontWeight: 'bold',
	}),
};

const TopBar = ({ screen, setScreen, setCount }: { screen: number, setScreen: (screen: number) => void, setCount: (screen: number) => void }) => {
	const { t } = useTranslation();
	const { supplierContacts } = useQuestionFormStore();
	const params = useParams();
	const [isError, setIsError] = useState<boolean>(false);

	const navigate = useNavigate();
	const handleClick = useCallback(() => {
		navigate(`${FRONT_PATH_NAMES.questionForms}/details/${_.toString(params.questionFormId)}/overview`);
	}, [navigate, params]);

	const contacts = supplierContacts
		.map((c) => {
			return c.subRows;
		})
		.flat();

	const filledContacts = contacts.filter((e) => {
		return !isWhitespace(getContactStringValue(e));
	});

	const allComplete = filledContacts.length === contacts.length;

	return (
		<>
			{isError &&
				<div css={{
					padding: '0 32px',
				}}>
					<AlertStatic text={t('question-forms.info-not-complete-error')} type="error" icon={<AlertCircle css={{ color: '#C74600' }} />} />
				</div>
			}
			<div className={cx('flexRow alignCenter', topStyles.main)}>

				<div className={cx('flexRow alignCenter', topStyles.leftGroup)}>
					<div
						css={{
							marginRight: 21,
						}}
					>
						<IconButton
							disableRipple
							onClick={handleClick}
						>
							<ArrowLeft color={siteColors.grey700} size={20} />
						</IconButton>
					</div>

					{supplierContacts.length <= 0
						? (
							<div className={cx(topStyles.text1)}>{t('Fournisseurs à contacter')}</div>
						)
						: (
							<div>
								<div className={cx(topStyles.text1)}>{t('Fournisseurs à contacter')}</div>
								<div
									className={cx('flexRow alignCenter', supplierContacts.length <= 0 ? stylesPrepareQuestion.hidden : '')}
								>
									{allComplete
										? (
											<CheckCircle2 color={siteColors.green500} size={20} />
										)
										: (
											<Circle size={20} color="#F0BF41" className={cx('')} />
										)}

									<div className={cx('', topStyles.text3)}>
										<span className={cx('', topStyles.text4)}>
											{filledContacts.length}/{contacts.length}
										</span>{' '}
										{t('contact renseignés')}
									</div>
								</div>
							</div>
						)}
				</div>

				<div className={cx('flexRow alignCenter')}>
					<ContentRenderer screen={screen} setScreen={setScreen} setCount={setCount} setIsError={setIsError} />
				</div>

			</div></>
	);
};

const noteStyles = {
	main: css({
		// background: siteColors.grey200,
		padding: 24,
		// border: cn('1px solid', siteColors.grey500),
		// borderRadius: 16,
	}),
};

const Note = ({ screen }: { screen: number }) => {
	const { t } = useTranslation();

	const { supplierContacts } = useQuestionFormStore();

	// if questionnaire sent or all info is completed don't display the note message
	if (screen === 2 || (supplierContacts.length > 0 && supplierContacts.every(obj => { return obj.subRows.length > 0; }))) {
		return null;
	}

	return (
		<div
			className={cx('flexRow alignCenter width100', noteStyles.main)}
			// dangerouslySetInnerHTML={{
			// 	__html:
			// 		supplierContacts.length > 0
			// 			? t('question-forms.complete-suppliers-info')
			// 			: t('question-forms.start-to-add-suppliers'),
			// }}
			dangerouslySetInnerHTML={{ __html: t('question-forms.start-to-add-suppliers') }}
		></div>
	);
};


const ContentRenderer = ({ screen, setScreen, setCount, setIsError }: { screen: number, setScreen: (screen: number) => void, setCount: (screen: number) => void, setIsError: (screen: boolean) => void, }) => {
	const [open, setOpen] = useState(false);
	const [openImport, setOpenImport] = useState(false);
	const { t, i18n } = useTranslation();
	const [data, setData] = useState<UserContact[]>([]);
	const [error, setError] = useState<string | null>(null);
	const languageCode = getIsoCountryCode(i18n.language);
	const [loading, setLoading] = useState(false);
	const [fileName, setFileName] = useState<string | null>(null);
	const [openInfo, setOpenInfo] = useState(false);
	const queryClient = useQueryClient();
	const params = useParams();
	const { supplierContacts, setSuppliersContacts } = useQuestionFormStore();

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();


	//---------------------------------------------------------//
	//----------------- default value for supplierContacts-----//
	//---------------------------------------------------------//


	const {
		mutate: createUsersForCampaign,
		// isError,
		isLoading,
		isSuccess,
	} = useMutation({
		mutationKey: ['createUsersForCampaign'],
		mutationFn: createUsersForCampaignAction,
		onSuccess: async (e) => {
			setCount(supplierContacts.length);
			setSuppliersContacts([]);
			queryClient.invalidateQueries(['FindQuestionForm']);
			queryClient.invalidateQueries(['getQuestionFormById', { id: _.toString(params.questionFormId) }]);
			queryClient.invalidateQueries(['findCampaign']);
			queryClient.invalidateQueries({ queryKey: getClientAuthKey });

			queryClient.invalidateQueries([functionName.questions.getReceivedResponsesCount]);
			queryClient.invalidateQueries([functionName.questions.getContactedSuppliersCount]);
			queryClient.invalidateQueries([functionName.questions.getSentCampaignsCount]);

			setScreen(2);
		},
		onError: (error: any) => {
			if (error.message) {
				//
			}
		},
	});

	const contacts = flattenContacts(supplierContacts);

	const onSubmit = async () => {
		if (contacts.length === 0) {
			setIsError(true);
		}
		else {
			createUsersForCampaign({
				contacts,
				languageCode,
				questionFormId: _.toString(params.questionFormId),
			});
			setIsError(false);
		}
	};

	const notReadyContacts = useMemo(() => {
		return supplierContacts
			.map((e) => {
				return e.subRows;
			})
			.flat()
			.filter((contact) => {
				return isWhitespace(getContactStringValue(contact));
			});
	}, [supplierContacts]);


	const [infoMessage, setInfoMessage] = useState<InfoMessageContact>(
		{
			nbSupplier: 0,
			nbContact: 0,
			nbContactNotImported: 0,
			supplierNotExists: [],
			emailsInvalid: [],
			existingContact: [],
		},
	);

	const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0]; // Retrieve the first file from the input
		if (!file) return; // Exit if no file is selected

		const fileName = file.name; // Extract the file name
		setFileName(fileName); // Save the file name in state

		// Ensure the file is a CSV file
		if (file.type !== 'text/csv') {
			setError(t('import-contact-error-not-csv')); // Set error if the file is not a CSV
			setData([]); // Clear any existing data
			return;
		}

		// If a valid file is provided, initialize FileReader
		const reader = new FileReader();

		// Handle the file once it's loaded
		reader.onload = (e) => {
			if (!e.target?.result) return; // Exit if no result is found

			// Decode the file content as UTF-8 text
			const textDecoder = new TextDecoder('utf-8');
			const decodedText = textDecoder.decode(new Uint8Array(e.target.result as ArrayBuffer));

			// Read the decoded text as a workbook
			const workbook = read(decodedText, { type: 'string' });

			// Extract the first sheet from the workbook
			const sheetName = workbook.SheetNames[0];
			const sheet = workbook.Sheets[sheetName];

			// Convert the sheet data into a JSON array
			const parsedData = utils.sheet_to_json<UserContact>(sheet, { header: 1 });

			// Check if the number of rows (excluding the header) exceeds the maximum allowed
			const maxRows = 300;

			if (parsedData.length - 1 > maxRows) {
				setError(t('import-error-too-many-rows', { varMaxRow: maxRows })); // Set error for too many rows
				setData([]); // Clear data
				return;
			}

			// Format the parsed data, excluding the header row
			const formattedData = parsedData.slice(1).map((row: any) => {
				return {
					supplierName: row[0], // Column 1: Supplier Name
					contactFirstName: row[1], // Column 2: Contact First Name
					contactName: row[2], // Column 3: Contact Last Name
					contactEmail: row[3]?.toLowerCase(), // Column 4: Contact Email to lower case
					contactLanguage: getIsoCountryCode(row[4]), // Column 5: Contact Language
				};
			});

			setData(formattedData); // Update state with the formatted data
		};

		// Read the file as an ArrayBuffer for decoding
		reader.readAsArrayBuffer(file);
	};

	const handleSaveData = async () => {
		if (error) return; // Exit if there is an existing error

		setLoading(true); // Activate the loading state for the UI

		if (!data || data.length === 0) {
			// If no data is available, stop loading and exit
			setLoading(false);
			return;
		}

		// Convertir tous les emails en minuscule
		const normalizedData = data.map(item => {
			return {
				...item,
				contactEmail: item?.contactEmail?.toLowerCase() || '',
			};
		});

		const allContactsEmail = _.uniq(normalizedData.map((s) => { return s.contactEmail; }).filter(Boolean));
		// Fetch existing suppliers and associated contacts from an API or database
		const { suppliers, existingContact } = await fetchSuppliersAndContact({
			nameSuppliers: data.map((s) => { return s.supplierName; }).filter(Boolean),
			contactsEmail: allContactsEmail,
			questionFormId: params?.questionFormId,
		}) as never;

		// Create a Map for quick access to suppliers by their names
		const mapSuppliers = new Map<string, any>(
			(suppliers as any).map((result: any) => { return [result.name, result]; }),
		);

		// Initialize structures to store valid results and errors
		const _newData: Record<string, any>[] = []; // Holds valid data for saving
		const supplierNotExists: any[] = []; // Tracks suppliers not found in the database
		const supplierOutsider: any[] = [];// Tracks suppliers outside of the campaign
		const emailsInvalid: any[] = []; // Tracks invalid email addresses
		let countErrorContact = 0; // Counter for invalid contact entries


		// Regrouper les contacts par email
		const emailToSuppliers = _.mapValues(
			_.groupBy(normalizedData, 'contactEmail'),
			items => { return _.uniq(items.map(e => { return e.supplierName; })); }, // Extraire les supplierName uniques
		);

		// Filtrer les emails ayant plusieurs supplierName
		const identicalEmails = _.pickBy(emailToSuppliers, suppliers => { return suppliers.length > 1; }) || null;

		const filteredData = normalizedData.filter(item => {
			return !_.keys(identicalEmails).includes(item.contactEmail); // On garde seulement si l'email est unique à un seul supplierName
		});

		// Iterate through the data to validate and transform each entry
		filteredData.forEach((s) => {
			if (!s.supplierName && !s.contactEmail) return; // Skip empty rows

			const itemMap = mapSuppliers.get(s.supplierName); // Look up supplier in the Map

			//-------------------------------------------------------------------------//
			//------------- If the supplier doesn't exist in the database--------------//
			//-------------------------------------------------------------------------//

			if (!itemMap) {
				// If the supplier doesn't exist in the database
				supplierNotExists.push({
					uid: s.supplierName,
					email: s.contactEmail,
				});
			}

			//-------------------------------------------------------------------------//
			//----------------------- If the email is invalid or missing --------------//
			//-------------------------------------------------------------------------//

			if (!s.contactEmail || !isValidEmail(s.contactEmail)) {
				countErrorContact++; // Increment the error counter
				emailsInvalid.push({ email: s.contactEmail }); // Add to invalid emails list
				return; // Stop processing this entry, no further action needed
			}

			//-------------------------------------------------------------------------//
			//------------------------If we find the supplier -------------------------//
			//-------------------------------------------------------------------------//

			if (itemMap) {
				//--------------------------------------------------//
				//---- if supplier outside of the questionnaire-----//
				//--------------------------------------------------//
				if (itemMap?.outsider === true) {
					supplierOutsider.push({
						uid: s.supplierName,
						email: s.contactEmail,
					});
				}
				//-------------------------------------------------------------------------//
				//----If the supplier exists and the email is valid, build a valid entry --//
				//-------------------------------------------------------------------------//
				else {
					_newData.push({
						id: itemMap.id,
						supplierName: s.supplierName || '---', // Default if supplier name is missing
						supplierUid: itemMap.uid || '---', // Default if supplier UID is missing
						contactEmail: s.contactEmail?.toLowerCase(),
						contactFirstName: s.contactFirstName,
						contactLastName: s.contactName,
						contactLanguage: s.contactLanguage ?? languageCode, // Default language if not provided
					});
				}
			}
		});

		// Final formatting of the data for saving
		const fData = formatDataSupplier(_newData);

		// Compute statistics for informational messages
		const uniqueSuppliers = _.uniqBy(_newData, 'supplierUid'); // Unique suppliers

		const uniqueContacts = _.uniqBy(_newData, 'contactEmail'); // Unique contacts


		setSuppliersContacts(fData); // Update the state with valid contacts

		// Build the information message for the user
		setInfoMessage({
			supplierOutsider,
			supplierNotExists, // List of suppliers not found
			emailsInvalid, // List of invalid email addresses
			existingContact, // Existing contacts from the database
			nbSupplier: uniqueSuppliers.length, // Count of unique suppliers
			nbContact: uniqueContacts.length, // Count of unique contacts
			nbContactNotImported: countErrorContact + (_.keys(identicalEmails).length ?? 0), // Count of contacts not imported, // Count of contacts not imported
			identicalEmails, // List of identical emails
		} as never);

		setOpenImport(false); // Close the import modal
		setLoading(false); // Deactivate the loading state
		setError(null); // Reset any errors
		setOpenInfo(true); // Open the informational modal for the user
	};

	// if questionnaire sent or all info is completed don
	if (screen === 2) {
		return null;
	}

	return (
		<>
			<div className="flexRow gap16">
				<BDropdown
					menus={[{
						title: 'Importer des contacts',
						value: '',
						customComponent: 'Filtrer',
					}]}
					renderItem={(menu) => {
						return (
							<Box width="200px">
								<BButton
									label={t('Importer des contacts')}
									variant="tertiary"
									onClick={() => {
										setOpenImport(true);
									}}
								/>
								<Divider />
								<BButton
									label={t('Ajouter des fournisseurs')}
									variant="tertiary"
									onClick={() => {
										setOpen(true);
									}}
								/>

							</Box>
						);
					}}
				>
					<BButton
						label={t('btn-modify-send-list')}
						variant="secondary"
					/>
				</BDropdown>

				<BButton
					label={t('btn-send-campaign')}
					onClick={onSubmit}
					variant={'primary'}
				/>
			</div>

			<Dialog
				open={open}
				withCancelButton={false}
				closeButton
				toggle={() => {
					setOpen(!open);
				}}
				paperProps={{
					maxWidth: 1200,
					width: 1200,
					minHeight: 600,
				}}
			>
				<QuestionFormSuppliersTableSelect />
			</Dialog>
			<ImportDialog
				title={t('import-contact-title')}
				error={error}
				isLoading={loading}
				open={openImport}
				withCancelButton={false}
				closeButton
				toggle={() => {
					setOpenImport(!openImport);
				}}
				paperProps={{
					maxWidth: 1200,
					width: 482,
					minHeight: 250,
				}}
				description={
					<div>
						<Typography variant="body1" textAlign="left">
							{t('import-contact-description')}
						</Typography>
						<div>
							<a href="/template_contact.csv" download="template_contact.csv" className={stylesPrepareQuestion.link}>
								{t('import-contact-download-model')}
							</a>
						</div>
					</div>
				}
				primaryButtonText={t('import-contact-to-validate')}
				handlePrimaryButtonAction={handleSaveData}
			>
				<div className={cx('flexColumn gap16', stylesPrepareQuestion.fileInput)}>
					<input type="file" accept=".csv" onChange={handleFileUpload} />
					<FileDown color={siteColors.grey700} />
					<Typography color={siteColors.grey700}>{t('import-contact-choose-file')}</Typography>
					{fileName && <div>{fileName}</div>}
				</div>
			</ImportDialog>

			<AlertContactUpload
				title={t('import-contact-title')}
				openInfo={openInfo} setOpenInfo={setOpenInfo} infoMessage={infoMessage} />

		</>
	);
};





