import { Fragment, ReactNode, createContext, useContext, useRef, useState } from 'react';

import { css, cx } from '@emotion/css';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { lighten } from '@mui/system/colorManipulator';
import _ from 'lodash';
import { Atom, Factory, PackageOpen, Store, Truck, Utensils, Wheat } from 'lucide-react';
import { Link } from 'react-router-dom';

import { ITEM_NATURE_NON_FOOD } from '@carbonmaps/shared/utils/constants';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import { useQueryParams } from '../../hooks/useSearchParams';
import { useTranslation } from '../../hooks/useTranslation';
import { cn, siteColors } from '../../lib/colors';
import LightTooltip from '../LightTooltip';

const styles = {
	tooltip: css({
		display: 'none',
		position: 'absolute',
		zIndex: 100,
	}),
	tooltipContainer: css({
		borderRadius: '8px',
		border: cn('1px solid', siteColors.grey500),
		background: siteColors.common.white,
		boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.45), 0px 4px 16px 0px rgba(0, 0, 0, 0.40)',
		padding: '24px',
		paddingBottom: '12px',
		gap: '24px',
		minWidth: '200px',
		maxWidth: '100%',
	}),
};

type Point = {
	x: number;
	y: number;
};

type ChartContextType = {
	tickWidth: number;
	bottomBase: number;
	pointCircleRadius: number;
	topValueContainer: {
		width: number;
		height: number;
	};
	xAxisLabelContainer: {
		width: number;
		height: number;
	};
	colors?: Colors;
};

const ChartContext = createContext<ChartContextType>({
	tickWidth: 4,
	bottomBase: 75,
	pointCircleRadius: 6,
	topValueContainer: {
		width: 100,
		height: 50,
	},
	xAxisLabelContainer: {
		width: 100,
		height: 75,
	},
	colors: undefined,
});

const useChart = () => {
	return useContext(ChartContext);
};

type Colors = {
	main: string;
	polygonGradient: [string, string];
	line?: string;
};

type Props = {
	datas: {
		value: number;
		tooltip?: React.ReactNode;
		link?: string;
	}[];
	isDisabled?: boolean;
	colors?: Colors;
	viewBox?: string;
	withLabel?: boolean;
	iconColor?: string;
	areaHeight?: number;
	iconSize?: number;
	labelColor?: string;
	areaWidth?: number;
	bottomPosition?: number;
	strokeWidth?: number;
	lineWidth?: number;
	topValueContainer?: {
		width: number;
		height: number;
	};
	fontStyles?: {
		title: number;
		subtitle: number;
	};
	axisClassName?: string;
	itemNature?: string;
};

const LifeCycleChart = ({
	datas,
	isDisabled,
	colors,
	iconColor,
	strokeWidth,
	lineWidth,
	viewBox = '0 0 1000 300',
	withLabel = true,
	areaHeight = 300,
	areaWidth = 1000,
	iconSize = 24,
	bottomPosition = 75,
	labelColor = siteColors.text,
	topValueContainer = {
		width: 100,
		height: 50,
	},
	fontStyles = {
		title: 32,
		subtitle: 24,
	},

	axisClassName,
	itemNature,
}: Props) => {
	const theme = useTheme();

	const { t } = useTranslation();

	const tooltipRef = useRef<any>();
	const svgRef = useRef<any>();
	const [tooltip, setTooltip] = useState<any>();

	const tickWidth = 4;
	const bottomBase = bottomPosition;
	const pointCircleRadius = 6;

	const xAxisLabelContainer = {
		width: 100,
		height: bottomPosition,
	};

	const xAxisLineHeight = 1.5;

	const areaYPlage = { min: bottomBase, max: areaHeight - topValueContainer.height - pointCircleRadius };
	const areaYPlageWidth = areaYPlage.max - areaYPlage.min;
	const spaceBeforeFirstTick = 50;
	const spaceBetweenTicks = (areaWidth - spaceBeforeFirstTick * 2) / (datas.length - 1);

	const getPointYCoordinate = (percentage: number) => {
		return (percentage * areaYPlageWidth) / 100 + bottomBase;
	};

	const points: Point[] = [];

	const maxValue = _.maxBy(datas, 'value')?.value;

	datas.forEach((data, index) => {
		points.push({
			x: spaceBeforeFirstTick + spaceBetweenTicks * index,
			y: getPointYCoordinate(maxValue ? (100 * data.value) / maxValue : data.value),
		});
	});

	const xAxisLabels = [
		{
			icon: (
				itemNature === ITEM_NATURE_NON_FOOD ?
					<Atom
						width={iconSize}
						height={iconSize}
						color={iconColor ? iconColor : colors ? colors?.main : siteColors.carbon500}
					/> :
					<Wheat
						width={iconSize}
						height={iconSize}
						color={iconColor ? iconColor : colors ? colors?.main : siteColors.carbon500}
					/>
			),
			text: itemNature === ITEM_NATURE_NON_FOOD ? t('matière-premiere') : t('agriculture'),
		},
		{
			icon: (
				<Factory
					width={iconSize}
					height={iconSize}
					color={iconColor ? iconColor : colors ? colors?.main : siteColors.carbon500}
				/>
			),
			text: t('transformation'),
		},
		{
			icon: (
				<PackageOpen
					width={iconSize}
					height={iconSize}
					color={iconColor ? iconColor : colors ? colors?.main : siteColors.carbon500}
				/>
			),
			text: t('emballage'),
		},
		{
			icon: (
				<Truck
					width={iconSize}
					height={iconSize}
					color={iconColor ? iconColor : colors ? colors?.main : siteColors.carbon500}
				/>
			),
			text: t('transport'),
		},
		{
			icon: (
				<Store
					width={iconSize}
					height={iconSize}
					color={iconColor ? iconColor : colors ? colors?.main : siteColors.carbon500}
				/>
			),
			text: t('distribution'),
		},
		{
			icon: (
				<Utensils
					width={iconSize}
					height={iconSize}
					color={iconColor ? iconColor : colors ? colors?.main : siteColors.carbon500}
				/>
			),
			text: t('consommation'),
		},
	];

	// ---- event for popover graph ---- //
	const onMouseEnter = (evt: any, point: any, itemSelected: any) => {
		const CTM = svgRef.current.getScreenCTM();
		const mouseX = evt.clientX - CTM.e;

		tooltipRef.current.setAttributeNS(null, 'top', 0);
		tooltipRef.current.setAttributeNS(
			null,
			'style',
			`bottom: ${point.y + 20}px; left: ${mouseX}px;  position: absolute; display:block`,
		);

		setTooltip(itemSelected);
	};

	return (
		<Box
			sx={{
				flex: 1,
				position: 'relative',
				// '&:hover .custom-tooltip': { display: 'block !important' },
			}}
			onMouseEnter={(e: any) => {
				tooltipRef.current.setAttributeNS(null, 'style', ' position: absolute; display:none');
			}}
			onMouseLeave={(e: any) => {
				tooltipRef.current.setAttributeNS(null, 'style', ' position: absolute; display:none');
			}}
		>
			<ChartContext.Provider
				value={{
					tickWidth,
					bottomBase,
					pointCircleRadius,
					topValueContainer,
					xAxisLabelContainer,
					colors,
				}}
			>
				<svg
					viewBox={viewBox}
					// transform="scale(1,-1)"
					css={{
						transform: 'scale(1,-1)',
					}}
					ref={svgRef}
					onMouseEnter={(e: any) => {
						tooltipRef.current.setAttributeNS(null, 'style', ' position: absolute; display:none');
					}}
				>
					<Polygon
						points={points}
						onMouseEnter={(e: any) => {
							tooltipRef.current.setAttributeNS(null, 'style', ' position: absolute; display:none');
						}}
						width={areaWidth}
						bottomPosition={bottomPosition}
					/>

					<rect
						x={0}
						y={bottomBase - xAxisLineHeight}
						width={areaWidth} // viewBox Width
						height={xAxisLineHeight}
						fill={lighten(
							isDisabled ? siteColors.grey400 : !colors ? theme.palette.primary.main : colors?.line || colors.main,
							0.2,
						)} // !===============
					/>

					{points.map((point, index) => {

						return (
							<Fragment key={index}>
								<TopValueLabel
									point={point}
									value={datas[index].value}
									onMouseEnter={onMouseEnter}
									// onMouseOut={(e: any) => {
									// 	return tooltipRef.current.setAttributeNS(null, 'style', ' position: absolute; display:none');
									// }}
									item={{ ...datas[index], label: xAxisLabels[index]?.text }}
									color={labelColor}
									fontStyles={fontStyles}
								/>
								<XAxisLAbel
									point={point}
									value={xAxisLabels[index]}
									isDisabled={isDisabled}
									link={datas[index].link}
									item={{ ...datas[index], label: xAxisLabels[index]?.text }}
									// onMouseEnter={(e: any) => {
									// 	tooltipRef.current.setAttributeNS(null, 'style', ' position: absolute; display:none');
									// }}
									withLabel={withLabel}
									onMouseEnter={onMouseEnter}
									className={axisClassName}
								/>
								<Tick
									point={point}
									isDisabled={isDisabled}
									item={{ ...datas[index], label: xAxisLabels[index]?.text }}
									onMouseEnter={onMouseEnter}
									strokeWidth={strokeWidth}
									lineWidth={lineWidth}
								/>
							</Fragment>
						);
					})}
				</svg>
			</ChartContext.Provider>
			<div className={cx('custom-tooltip', styles.tooltip)} ref={tooltipRef}>
				<div className={cx('flexColumn', styles.tooltipContainer)}>
					{tooltip && (
						<div className="flexColumn">
							{/* remove step name */}
							{tooltip.tooltip}
						</div>
					)}
				</div>
			</div>
		</Box>
	);
};

const Polygon = ({
	points,
	onMouseEnter,
	width,
	bottomPosition,
}: {
	points: Point[];
	onMouseEnter?: (e: any) => void;
	width?: number;
	bottomPosition?: number;
}) => {
	const { /* bottomBase, */ colors } = useChart();

	let polygonPoints = '';

	for (const point of points) {
		polygonPoints = `${polygonPoints} ${point.x},${point.y}`;
	}

	return (
		<Fragment>
			<defs xmlns="http://www.w3.org/2000/svg">
				<linearGradient
					id="paint0_linear_449_50677"
					x1="435.005"
					y1="0"
					x2="435.005"
					y2="155"
					gradientUnits="userSpaceOnUse"
				>
					<stop
						offset="0.661153"
						stopColor={!colors ? siteColors.carbon100 : colors.polygonGradient[0]}
					// stopOpacity="30%"
					/>
					<stop
						offset="1"
						stopColor={!colors ? siteColors.carbon100 : colors.polygonGradient[1]} /* stopOpacity="18.6%" */
					/>
				</linearGradient>
			</defs>
			<polygon
				points={`0,${bottomPosition} ${polygonPoints} ${width},${bottomPosition}`}
				fill="url(#paint0_linear_449_50677)"
				onMouseEnter={onMouseEnter}
			/>
		</Fragment>
	);
};

const Tick = ({
	point,
	value,
	lineWidth,
	strokeWidth = 4,

	isDisabled,
	item,
	onMouseEnter,
	onMouseOut,
}: {
	point: Point;
	value?: string;
	item?: any;
	isDisabled?: boolean;
	strokeWidth?: number;
	lineWidth?: number;
	onMouseEnter?: (e: any, point: any, tooltip: any) => void;
	onMouseOut?: (e: any) => void;
}) => {
	const { tickWidth, bottomBase, pointCircleRadius, colors } = useChart();
	const theme = useTheme();

	const ref = useRef<any>();

	const tickHeight = point.y - bottomBase - pointCircleRadius;
	const lineTickWidth = lineWidth || tickWidth;

	return (
		<Fragment>
			<rect
				x={point.x - lineTickWidth / 2}
				y={bottomBase}
				width={lineTickWidth}
				height={tickHeight < 0 ? 0 : tickHeight}
				fill={isDisabled ? siteColors.grey400 : !colors ? theme.palette.primary.main : colors.main}
			/>
			<circle
				ref={ref}
				cx={point.x}
				cy={point.y}
				r={pointCircleRadius}
				fill="white"
				stroke={isDisabled ? siteColors.grey400 : !colors ? theme.palette.primary.main : colors.main}
				strokeWidth={strokeWidth}
				onMouseMove={(event: any) => {
					onMouseEnter && onMouseEnter(event, point, item);
				}}
				onMouseOut={(event: any) => {
					onMouseOut && onMouseOut(event);
				}}
			/>
		</Fragment>
	);
};

const stylesValues = {
	container: css({
		position: 'relative',
		'&:hover .custom-tooltip': { display: 'block !important' },
	}),
	text: css({
		textAlign: 'center !important',
	} as any),
};

const TopValueLabel = ({
	point,
	value,
	item,
	onMouseEnter,
	// onMouseOut,
	color,
	fontStyles,
}: {
	point: Point;
	value: number;
	item?: any;
	color?: string;
	onMouseEnter?: (e: any, point: any, tooltip: any) => void;
	onMouseOut?: (e: any) => void;
	fontStyles?: {
		title: number;
		subtitle: number;
	};
}) => {
	const { pointCircleRadius, topValueContainer } = useChart();

	const num = formatNumber(value, undefined, 1).split(',');
	const mainNum = num[0];
	const firstDecimal = num[1];

	return (
		<foreignObject
			key={point.y}
			transform="scale(1,-1)"
			x={point.x - topValueContainer.width / 2}
			y={-(point.y + topValueContainer.height + pointCircleRadius - 10)}
			width={topValueContainer.width}
			height={topValueContainer.height}
		>
			<Typography
				className={cx(stylesValues.text, 'number')}
				onMouseMove={(event: any) => {
					onMouseEnter && onMouseEnter(event, point, item);
				}}
				color={color}
				fontSize={fontStyles?.title}
			>
				{mainNum}
				{!isNaN(Number(firstDecimal)) && Number(firstDecimal) > 0 && (
					<Fragment>
						{','}
						<Box component="span" fontSize={fontStyles?.subtitle} fontWeight={500} color={color}>
							{firstDecimal}
						</Box>
					</Fragment>
				)}
				{''}
				<Box component="span" fontSize={fontStyles?.subtitle} ml="1px" fontWeight={500} color={color}>
					{' %'}
				</Box>
			</Typography>
		</foreignObject>
	);
};

const stylesAxis = {
	container: css({
		height: '100%',
		paddingTop: '16px',
		textAlign: 'center',
	}),
	axisTitle: css({
		maxWidth: '250px',
		textOverflow: 'ellipsis',
		textAlign: 'center',
		fontSize: '12px!important',
		overflow: 'hidden',
	}),
};

const XAxisLAbel = ({
	point,
	value,
	isDisabled,
	link,
	onMouseEnter,
	withLabel,
	item,
	className,
}: {
	point: Point;
	value: { icon: ReactNode; text: string };
	isDisabled?: boolean;
	link?: string;
	onMouseEnter?: (e: any, point: any, tooltip: any) => void;
	withLabel?: boolean;
	item?: any;
	className?: string;
}) => {
	const { xAxisLabelContainer, colors } = useChart();
	const theme = useTheme();

	const renderBox = () => {
		return (
			<foreignObject
				transform="scale(1,-1)"
				x={point.x - xAxisLabelContainer.width / 2}
				y={-xAxisLabelContainer.height}
				width={xAxisLabelContainer.width}
				height={xAxisLabelContainer.height}
				css={{
					cursor: link ? 'pointer' : 'unset',
					borderBottom: 'solid 1px transparent',
					...(link
						? {
							'&:hover': {
								borderBottomColor: !colors ? theme.palette.primary.main : colors.main,
								'& .lifecycle-x-label-text': {
									color: !colors ? theme.palette.primary.main : colors.main,
								},
							},
						}
						: {}),
				}}
			>
				<div
					className={cx(stylesAxis.container, className)}
					onMouseMove={(event: any) => {
						onMouseEnter && onMouseEnter(event, point, item);
					}}
				>
					<Box
						css={{
							width: '42px',
							margin: '0 auto',
							...(isDisabled && { opacity: 0.2 }),
						}}
					>
						{value.icon}
					</Box>
					{withLabel && (
						<LightTooltip title={value.text}>
							<Typography className={cx(stylesAxis.axisTitle, 'lifecycle-x-label-text noWrap')} noWrap>
								{value.text}
							</Typography>
						</LightTooltip>
					)}
				</div>
			</foreignObject>
		);
	};

	return link ? <Link to={link}>{renderBox()}</Link> : renderBox();
};

export default LifeCycleChart;
