import { useEffect, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import _ from 'lodash';
import { BarChart2 } from 'lucide-react';
import { useParams } from 'react-router-dom';

import { CARBON_INDICATOR } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import BlockCard from '../../components/BlockCard';
import BlockTitle from '../../components/BlockTitle';
import Skeleton from '../../components/Skeleton';
import Unit from '../../components/units/Unit';
import { useTranslation } from '../../hooks/useTranslation';
import { GLOBAL_INDICATOR_CONFIG } from '../../utils/graph';
import { getGlobalIndicator } from '../../utils/simulation.utils';
import IndicatorItem from '../scenario/IndicatorItem';

import { useQuoteStore } from './quote.hooks';

// import IndicatorItem from './IndicatorItem';
// import { useScenario } from './scenario.hooks';
// import { useDataSimulation } from './useDataSimulation.hooks';

const styles = {
	block: css({
		background: 'white',
		border: 'none!important',
	}),
	flexSpacing: css({
		gap: '10%',
		// margin: '10%',
	}),

	skeleton: css({
		height: '165px !important',
		width: '100% !important',
	} as any),
};

const GlobalIndicator = ({ abbreviatedNumbers }: any) => {
	const params = useParams();
	// const { isLoading } = useScenario({
	// 	simulationId: params.simulationId || '',
	// });

	// const { simulation } = useDataSimulation();
	const isLoading = false;

	// translation
	const { t } = useTranslation();

	const { quote } = useQuoteStore();

	const { indicator } = useApp();

	const content = useMemo(() => {
		const perDevis = (quote as any)?.getEstimatedImpact(indicator);
		const perPrestation = (quote as any)?.getTotalImpactPerGroup(indicator);
		return (
			<>
				<div>
					<IndicatorItem
						text={t('Par devis')}
						unit={
							<Unit className="grey700" measure={indicator === CARBON_INDICATOR ? 'carbonImpact' : 'waterImpact'} />
						}
						value={perDevis}
						icon={GLOBAL_INDICATOR_CONFIG.get(indicator)?.intensityIcon}
						withDifference={false}
						// precision={perDevis < 100 ? 2 : 0}
						precision={_.isInteger(perDevis) ? 0 : 2}
						abbreviatedNumbers={false}
					/>
				</div>
				<div>
					<IndicatorItem
						icon={GLOBAL_INDICATOR_CONFIG.get(indicator)?.impactPortionIcon}
						text={t('Par prestation')}
						value={perPrestation}
						unit={
							<Unit
								className="grey700"
								measure={indicator === CARBON_INDICATOR ? 'carbonImpactPortion' : 'waterImpactPortion'}
							/>
						}
						difference={parseFloat(formatNumber(0)?.replace(',', '.'))}
						withDifference={false}
						// precision={perDevis < 100 ? 2 : 0}
						precision={_.isInteger(perPrestation) ? 0 : 2}
					/>
				</div>
			</>
		);
	}, [quote, indicator, t]);

	return (
		<>
			<BlockTitle icon={<BarChart2 className="grey700" width={20} height={20} />}>
				{indicator === CARBON_INDICATOR ? t('Indicateurs carbone') : t('Indicateurs eau')}
			</BlockTitle>
			<BlockCard className={cx(styles.block, styles.flexSpacing, 'flexRow width100 nowrap')}>
				{isLoading ?
				(
					<Skeleton width="100%!important" className={styles.skeleton}>
						{content}
					</Skeleton>
				)
				: (
					content
				)}
			</BlockCard>
		</>
	);
};

export default GlobalIndicator;
