import { css, cx } from '@emotion/css';
import { ChevronDown } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { useCompareProduct } from '../../hooks/useCompareProduct';
import { cn, siteColors } from '../../lib/colors';
import SelectField from '../form/fields/SelectField';
import Typography from '../Typography';

const styles = {
	container: css({
		padding: '16px 0',
		borderTop: `1px solid ${siteColors.grey5}`,
		borderBottom: `1px solid ${siteColors.grey5}`,
	}),

	selectContainer: css({
			background: 'none !important',
			padding: '0px !important',
			lineHeight: '1',
			minWidth: 65,
			'.MuiInputBase-input': {
				minHeight: '0 !important',
				padding: '0px !important',
				paddingRight: '0px !important',
				minWidth: '0',
				'.MuiSelect-select': {
					paddingRight: '0px !important',
				},
			},
			'.MuiSelect-select': {
				paddingRight: '0px !important',
			},
			svg: {
				width: '12px !important',
				color: cn(siteColors.grey7, '!important'),
			},
		}),
};

type ImpactSelectedProps = {
	options: Array<{
		label: string;
		value: string | number
	}>
	value: string | number;
	className?: string;
	renderValue?: (item: any) => React.ReactNode;
	onChange: (v: any) => void;
	ExpandMoreRoundedIcon?: any;
}

const ImpactSelected = ({ options, value, className, ExpandMoreRoundedIcon,  onChange, renderValue }: ImpactSelectedProps ) => {

	const { t } = useTranslation();

	return (
		<div className={cx('width100', styles.container)}>
			<SelectField
			value={value}
			items={options?.map((item: any) => {
				return {
					...item,
					label: t(item?.label),
				};
			})}
			renderValue={(item: any) => {
				if (renderValue) return renderValue(item);
				return (
					<Typography  className={'grey8'}>
						{t(item?.label)}
					</Typography>
				);
			}}
			onChange={onChange}
			className={styles.selectContainer}
			paddingRight={24}
			ExpandMoreRoundedIcon={ExpandMoreRoundedIcon || ChevronDown}
		/>
		</div>
	);

};

export default ImpactSelected;
