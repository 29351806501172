import { useMemo } from 'react';

import WaterfallChart, { ChartData } from '../../../../components/charts/WaterfallChart';
import Card from '../../../../components/v3/Card';
import { useTranslation } from '../../../../hooks/useTranslation';
import { siteColors } from '../../../../lib/colors';
import { useSBTiFlagKpiData } from '../../sbtiFlag.hooks';

import SBTiCardTitle from './SBTiCardTitle';

const SBTiEmissionsDetail = () => {

	// ---- translation ---- //
	const { t }= useTranslation();

	// ---- fetch data ---- //
		const { data, isLoading } = useSBTiFlagKpiData();

		const dataGraph = useMemo(() => {

			const items: Array<ChartData>  = [
				{ name: ('Land Use & Land Use Change Emissions (LULUC)'), y: data?.gesFlagLulucImpact || 0 , color: siteColors.sbtiFlag.gesFlagLuluc },
				{ name: ('CO2 Land Management'), y: data?.gesFlagLmCo2Impact ||0, color: siteColors.sbtiFlag.gesFlagLmCo2 },
				{ name: ('Non-CO2 Land Management'), y: data?.gesFlagLmNonCo2Impact ||0, color: siteColors.sbtiFlag.gesFlagLmNonCo2  },
				{ name:'Total net FLAG emissions & removals',  isSum: true, y: Math.round(data?.gesFlagImpact || 0), color: siteColors.sbtiFlag.gesFlag },

			];
			return items;
		}, [ data]);



	return (
	<Card
		title={<SBTiCardTitle title={t('Détail des émissions SBTi de l’organisation')}/>}
		loading={isLoading}
	>
		<WaterfallChart data= {dataGraph} maxYAxis={data?.gesFlagImpact}/>
	</Card>
	);

};

export default SBTiEmissionsDetail;
