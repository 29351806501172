import { useEffect, useRef, useState } from 'react';

import { css, cx } from '@emotion/css';

import { useSize } from '../../../../hooks/useSize';

import CategoryContainer from './CategoryContainer';
import DescriptionBlock from './DescriptionBlock';
import KPIReport from './KPIReport';
import LandUseManagementChart from './LandUseManagementChart';
import SBTiEmissionsDetail from './SBTiEmissionsDetail';
import SBTiFLAGTrajectory from './SBTiFLAGTrajectory';

const styles = {
	container: css({
		paddingTop: 32,
	}),
	graphContainer: css({
		maxWidth: '100%',
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		gridColumnGap: 32,
		gridRowGap: 32,
		['@media (max-width: 1284px)' as never]: {
			display: 'flex',
			flexDirection: 'column-reverse !important' as never,
		},
	}),
};

const Analyze = () => {

	const [graphWidth, setGraphWidth] = useState('100%');

	const ref = useRef(null);
		const size = useSize(ref as never);

		useEffect(() => {
			if (((size as DOMRectReadOnly | undefined)?.width || 0) > 944) {
				setGraphWidth('445px');
			} else {
				setGraphWidth('100%');
			}
		}, [size]);

	return (
		<div className={cx('flexColumn width100 gap32', styles.container)}>
			<DescriptionBlock />
			<KPIReport />
			<SBTiEmissionsDetail />
			<div ref={ref} className={cx('width100', styles.graphContainer)}>
				<LandUseManagementChart />
				<SBTiFLAGTrajectory graphWidth={graphWidth} />
			</div>
			<CategoryContainer />
		</div>
	);
};

export default Analyze;
