import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { create } from 'zustand';

import {
	getGroupQuoteAction,
	getQuoteSheetAction,
	reorderQuoteGroupAction,
	saveQuoteGroupAction,
} from '@carbonmaps/ui/actions/quote.actions';

import QuoteModel from '../../models/Quote.model';

type useQuoteSheetType = {
	quoteId: string;
	viewMode?: string;
};

// -------------------------------------------- //
// ----------- get quote by Id ---------------- //
// -------------------------------------------- //
export const useQuoteSheet = ({ quoteId, viewMode }: useQuoteSheetType) => {
	const queryResult = useQuery({
		queryKey: ['getQuoteSheet', { quoteId, viewMode }],
		queryFn: getQuoteSheetAction,
	});

	return queryResult;
};

export const useQuoteGroup = ({ quoteId, viewMode }: useQuoteSheetType) => {
	const queryResult = useQuery({
		queryKey: ['getGroupQuote', { quoteId, viewMode }],
		queryFn: getGroupQuoteAction,
	});

	return queryResult;
};

// ------------------------------------------------------ //
// -------------------- add group to quote -------------- //
// ------------------------------------------------------ //
export const useAddGroupQuote = () => {
	const queryClient = useQueryClient();

	const mutation = useMutation({
		mutationKey: ['saveQuoteGroup'],
		mutationFn: saveQuoteGroupAction,
		onSuccess: async () => {
			queryClient.invalidateQueries({ queryKey: ['getQuoteSheet'] });
			queryClient.invalidateQueries({ queryKey: ['getQuoteDataAction'] });
			queryClient.invalidateQueries({ queryKey: ['findDevisForClientTable'] });
			queryClient.invalidateQueries({ queryKey: ['findReportsClientTable'] });
			queryClient.invalidateQueries({ queryKey: ['findQuoteReportsClientTable'] });
			queryClient.invalidateQueries({ queryKey: ['findQuoteReportsClientGraph'] });
			queryClient.invalidateQueries({ queryKey: ['getGroupQuote'] });
		},
		onError: (error: any) => {
			queryClient.invalidateQueries({ queryKey: ['getQuoteSheet'] });
			queryClient.invalidateQueries({ queryKey: ['getQuoteDataAction'] });
			queryClient.invalidateQueries({ queryKey: ['findDevisForClientTable'] });
			queryClient.invalidateQueries({ queryKey: ['findReportsClientTable'] });
			queryClient.invalidateQueries({ queryKey: ['findQuoteReportsClientTable'] });
			queryClient.invalidateQueries({ queryKey: ['findQuoteReportsClientGraph'] });
			queryClient.invalidateQueries({ queryKey: ['getGroupQuote'] });
		},
	});

	return mutation;
};

// ------------------------------------------------- //
// ------------------ store ------------------------ //
// ------------------------------------------------- //

export const useQuoteStore = create((set: any) => {
	return {
		quote: null,
		cloneQuote: null,
		setQuote: (quote: any) => {
			return set((prevState: any) => {
				return {
					quote: new QuoteModel(quote),
				};
			});
		},
	};
});

// ------------------------------------------------------ //
// ---------- change order  group for one to quote ------ //
// ------------------------------------------------------ //
export const useReorderGroupQuote = () => {
	const queryClient = useQueryClient();

	const mutation = useMutation({
		mutationKey: ['reorderQuoteGroup'],
		mutationFn: reorderQuoteGroupAction,
		onSuccess: async () => {
			queryClient.invalidateQueries({ queryKey: ['getQuoteSheet'] });
			queryClient.invalidateQueries({ queryKey: ['getQuoteDataAction'] });
			queryClient.invalidateQueries({ queryKey: ['findDevisForClientTable'] });
			queryClient.invalidateQueries({ queryKey: ['findReportsClientTable'] });

		},
		onError: (error: any) => {
			queryClient.invalidateQueries({ queryKey: ['getQuoteSheet'] });
			queryClient.invalidateQueries({ queryKey: ['getQuoteDataAction'] });
			queryClient.invalidateQueries({ queryKey: ['findDevisForClientTable'] });
			queryClient.invalidateQueries({ queryKey: ['findReportsClientTable'] });

		},
	});

	return mutation;
};
