import { css, cx } from '@emotion/css';
import { Minus } from 'lucide-react';

import { siteColors } from '../../lib/colors';

const styles = {

	container: css({
		justifyContent: 'space-between', /* Optionnel si vous souhaitez un espace entre les éléments */
		alignItems: 'center',
		display: 'flex',
		width: '100%',
		color: siteColors.grey800,
	}),

	label: css({
	color: 'var(--color)',
	}),
};

type TrajectoryLegendProps = {
	items: Array<{
		color: string;
		label: string;
		className?: string;
	}>
};

const TrajectoryLegend = (items: TrajectoryLegendProps) => {

	return (
		<div className="flexRow alignCenter gap12">
			{items.items.map((item, index) => {
				return (
					<ItemDisplay
						key={index}
						color={item.color}
						label={item.label}
						className={item.className}
					/>
				);
			})}
		</div>
	);
};

export default TrajectoryLegend;



type ItemDisplayProps = {
	color: string;
	label: React.ReactNode;
	className?: string;
};


export const ItemDisplay = ({ color, label, className }: ItemDisplayProps) => {

	return (
		<div className={cx('flexRow nowrap', className)}>
			<Minus color={color} />
			<div className={styles.label} style={{ ['--color' as any]: color }}>{label}</div>
		</div>
	);
};

