import { useCallback } from 'react';

import { css, cx } from '@emotion/css';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { getDataImpactByCategoryAction } from '@carbonmaps/ui/actions/dashboard.actions';
import { getProductTableDataAction } from '@carbonmaps/ui/actions/product.actions';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { WATER_INDICATOR } from '@carbonmaps/ui/utils/constants';

import Typography from '../../../../../components/Typography';
import Card from '../../../../../components/v3/Card';
import CategoryTable from '../../../../../components/v3/CategoryTable';
import { useYearSelection } from '../../../../../hooks/useImpactYear';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { siteColors } from '../../../../../lib/colors';

const styles = {
	title: css({
		fontSize: '16px !important',
		lineHeight: '1.2 !important',
	}),
	subtitle: css({
		lineHeight: '1.2 !important',
		color: `${siteColors.grey7} !important`,
	}),
	button: css({
		width: 'auto !important',
		minWidth: '50px !important',
		color: `${siteColors.blackButton} !important`,
		padding: '8px 16px !important',
		fontWeight: '500 !important',
	}),
};

const TopProducts = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { selectedYear } = useYearSelection();
	const { indicator } = useApp();

	// const {} = useQuery({
	// 	queryKey: ['useGetTopProducts', { selectedYear }] as const,
	// })

	const paramsTable = {
		sortingBy: {
			id: 'waterImpactPercent',
			desc: true,
		},
	};

	// ---- fetch data --- //
		const { data: tableResultData, isLoading: isTableLoading } = useQuery({
			queryKey: [
				'getProductTableData',
				{
					limit: 6,
					...indicator === WATER_INDICATOR ? paramsTable : {},
					viewMode: indicator,
					selectedYear,
					itemNature: undefined,
				},
			],
			queryFn: getProductTableDataAction,
		});

	const goToAnalyzeList = useCallback(() => {
		window.scrollTo(0, 0);
		navigate('products');
	}, []);

	const _d = _.map(tableResultData?.data, (e) => {
		return {
			...e,
			value: indicator === 'carbon' ? _.get(e, 'carbonImpactPercent') : _.get(e, 'waterImpactPercent'),
		};
	}).slice(0, 6);

	return (
		<Card
			title={
				<div className="flexColumn gap6">
					<Typography className={cx('fontWeight600', styles.title)}>
						{t('Produits les plus impactants')}
					</Typography>
					<Typography className={styles.subtitle}>{t('Par impact')}</Typography>
				</div>
			}
			footer={
				<BButton
					variant="secondary"
					label={t('Voir tous les produits')}
					onClick={goToAnalyzeList}
					className={cx(styles.button)}
				/>
			}
			loading={isTableLoading}
		>
			<CategoryTable data={_d} />
		</Card>
	);
};

export default TopProducts;
