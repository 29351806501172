import { ReactNode } from 'react';

import { css, cx } from '@emotion/css';

import Beta from '../../../layouts/_common/Beta';
import Typography from '../../Typography';

type ListHeaderProps = {
	title: string;
	subtitle?: React.ReactNode;
	icon: React.ReactNode;
	sx?: any;
	className?: string;
	isBeta?: boolean;
	idk?: ReactNode;
};

const styles = {
	container: css({
		padding: '8px 0px 0px !important',
		width: 'calc(100% - 4rem)',
		margin: '0 auto',
		gap: '24px',
		// background:
		// 	'linear-gradient(90deg, #EEF1FF 0.53%, #EAE6FF 27.26%), linear-gradient(90deg, #EAE6FF 0.53%, #EFE0FF 99.99%)',
		// background: siteColors.primary10,
	}),

	content: css({
		left: 0,
		gap: 20,
	}),
	gap: css({ gap: 4 }),
	gap4: css({
		gap: 4,
	}),
};

const ListHeader = ({ isBeta = false, title, subtitle, icon, sx = {}, className, idk }: ListHeaderProps) => {
	return (
		<div className={cx('flexRow alignCenter containerListWidth justifyBetween', styles.container, className, css(sx))}>
			<div className={cx('flexRow alignCenter justifyCenter relative', styles.content)}>
				{icon}
				<div>
					<div className={cx('flexColumn', styles.gap)}>
						<div className={cx('flexRow alignCenter', styles.gap4)}>
							<Typography className="grey900" variant="h2">
								{title}
							</Typography>
							{isBeta && <Beta />}
						</div>
						{subtitle && (
							<Typography className="grey800" variant="Body2">
								{subtitle}
							</Typography>
						)}
					</div>
				</div>
			</div>
			{idk ? <div>{idk}</div> : null}
		</div>
	);
};

export default ListHeader;
