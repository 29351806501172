
import { useCallback, useRef, useState } from 'react';

import { css, cx } from '@emotion/css';
import { Plus } from 'lucide-react';

import BButton from '@carbonmaps/ui/components/saas/BButton';

import ProductModalSelect from '../../../components/compareProduct/ProductModalSelect';
import Typography from '../../../components/Typography';
import { product, useCompareProduct } from '../../../hooks/useCompareProduct';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';

const styles = {
	button: css({
		background: 'transparent !important',
		minWidth: '0px !important',
		padding: '0px!important',
	}),
};

const MenuTitle = () => {

	const { t } = useTranslation();

	const [openDialog, setOpenDialog] = useState(false);

	// the compared products
	const { products, addProduct, removeProduct } = useCompareProduct();

	// handle add product click
	const handleAddProduct = useCallback(() => {
		setOpenDialog(true);
	}, []);

	return (
		<div className={cx('flexRow spaceBetween width100')}  >
			<div className="flexColumn">
				<Typography variant="h3" className='grey9'>{t('Comparaison de produits')}</Typography>
				<Typography variant="caption" className='grey7'>{t('product-selected-count', { count: products.length })}</Typography>
			</div>
				<BButton
					label=""
					variant="tertiary"
					iconLeft={<Plus size={20} color={siteColors.grey7} />}
					className={styles.button}
					onClick={handleAddProduct}
				/>
			<ProductModalSelect
				open={openDialog}
				onClose={() => {return setOpenDialog(false);}}
				onAddProduct={(product: product) => {addProduct(product);}}
				onRemoveProduct={(product: product) => {removeProduct(product);}}
			/>
		</div>
	);

};

export default MenuTitle;
