import { useCallback, useEffect, useMemo, useState } from 'react';

import { css } from '@emotion/css';
import { useTheme } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { default as _ } from 'lodash';
import { PackageOpen, Plus } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';

import { CARBON_INDICATOR } from '@carbonmaps/shared/utils/constants';
import { deletePackagingAction, updatePackagingAction } from '@carbonmaps/ui/actions/simulation.actions';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import { TableComponent } from '@carbonmaps/ui/components/saas/Table/TableComponent';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { SMALL_DESKTOP_DOWN, XL_DESKTOP_DOWN } from '@carbonmaps/ui/utils/Responsive';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import AccordionContainer from '../../../../components/AccordionContainer';
import LoadingDialog from '../../../../components/dialogs/LoadingDialog';
import TableHeaderCell from '../../../../components/table/TableHeaderCell';
import { useJuneTrack } from '../../../../hooks/useJuneTrack';
import { useTranslation } from '../../../../hooks/useTranslation';
import { siteColors } from '../../../../lib/colors';
import { WATER_INDICATOR } from '../../../../utils/constants';
import { getRecycledArray, manageEndOfLife } from '../../../../utils/packaging';
import { calculateIntensityRawMaterial, checkErrorPackaging } from '../../../../utils/simulation.utils';
import { useCmapsModelization } from '../../../packagings/packagings.hooks';
import { useScenario, useShowDeleted } from '../../scenario.hooks';
import { useDataSimulation } from '../../useDataSimulation.hooks';
import ErrorData from '../ErrorData';

import PackagingsModalSelect from './add/PackagingsModalSelect';
import CreationPackagingTable from './creation/CreationPackagingTable';

const classes = (theme: any, showDeleted: boolean) => {
	return {
		table: {
			border: 'none!important',
		},

		'.bordered td, .bordered th': {
			borderLeft: 'none!important',
			borderBottom: `1px solid ${theme.palette.grey[400]}`,
		},
		'.bordered tr th:nth-of-type(1), .bordered tr td:nth-of-type(1)': {
			width: 370,
			paddingLeft: '0px !important',
			paddingRight: '0px !important',
		},
		'.bordered th': {
			background: theme.palette.common.white,
		},
		'.bordered tr th:not(:nth-of-type(1))  div': {
			justifyContent: 'flex-end!important',
			paddingRight: '0!important',
			alignItems: 'right',
		},
		'.bordered tr td:nth-of-type(2),.bordered tr td:nth-of-type(3),.bordered tr td:nth-of-type(4)': {
			textAlign: 'right !important',
		},
		'.bordered tr th:nth-of-type(3), .bordered tr th:nth-of-type(4)': {
			width: 150,
		},

		// hide or show deleted original
		'& tr:has( [data-original-disabled-ingredient="true"])': {
			display: showDeleted ? 'table-row' : 'none!important',
		},
	};
};

const classNames = {
	addButton: css({
		padding: '16px !important',
		minHeight: '42px !important',
		height: 'auto !important',
	}),
	input: css({
		maxWidth: 150,

		'.MuiInputBase-root': {
			minWidth: 100,
		},
	}),
	label: css({
		[XL_DESKTOP_DOWN]: {
			maxWidth: '125px !important',
		},
		[SMALL_DESKTOP_DOWN]: {
			maxWidth: '90px !important',
		},
	}),
};

const Packaging = () => {
	// ---- theme and style ---- //
	const theme = useTheme();

	const { showDeleted } = useShowDeleted();
	const { data: isVersion2x } = useCmapsModelization({});

	// translation

	const { t } = useTranslation();

	const styles = useMemo(() => {
		return classes(theme, showDeleted);
	}, [showDeleted, theme]);

	const params = useParams();
	const { indicator } = useApp();

	const analytics = useJuneTrack();
	// ---- track event ---- //
	const trackEvent = useCallback(
		(eventName: string, options?: any) => {
			if (!analytics) return;
			analytics.track(eventName, options, {
				context: { category: 'Simulate' },
			});
		},
		[analytics],
	);

	const { cloneSimulation, simulation, onPackagingDataChange, onRowChange } = useDataSimulation();
	const queryClient = useQueryClient();
	const [sortField, setSortField] = useQueryParam('sortField', StringParam);
	const [sortOrder, setSortOrder] = useQueryParam('sortOrder', StringParam);
	const [dataTable, setDataTable] = useState([]);
	const [dataError, setDataError] = useState<any>();

	// ---- ingredient selected when clic replace button ---- //
	const [packagingSelected, setPackagingSelected] = useState<any>(null);
	const [openModal, setOpenModal] = useState(false);

	const { data, isLoading } = useScenario({
		simulationId: params.simulationId || '',
	});

	const updateMyData = (rowIndex: number, columnId: string, value: any) => {
		setDataTable((old: any) => {
			return old.map((row: any, index: number) => {
				if (index === rowIndex) {
					onRowChange({ objectId: old[rowIndex]?.objectKey, value: value }, 'packaging');

					if (columnId === 'weight') {
						trackEvent('Simulate Eco Design Edit Packaging Weight', {
							ScenarioName: (simulation as any)?.name,
							Type: 'Packaging',
							ItemLabel: old[rowIndex]?.label,
						});

						return {
							...old[rowIndex],
							[columnId]: value,
							gesProductPerPortion: (old[rowIndex].gesPackaging * parseFloat(value)) / old[rowIndex].netWeight,
							waterProductPerPortion: (old[rowIndex].waterPackaging * parseFloat(value)) / old[rowIndex].netWeight,
						};
					}

					if (columnId === 'recycled') {
						trackEvent('Simulate Eco Design Edit Packaging Recycled', {
							ScenarioName: (simulation as any)?.name,
							Type: 'Packaging',
							ItemLabel: old[rowIndex]?.label,
						});

						const item = old[rowIndex];

						let gesPackaging = item?.gesPackaging || 0;
						let waterPackaging = item?.waterPackaging || 0;

						const materials = item?.packaging?.get('sim_modelization')?.materials || [];

						if (item?.recyclable) {
							const recycled = (item?.recycled ?? 0) / 100;

							const gesRawMaterial = calculateIntensityRawMaterial(materials, CARBON_INDICATOR, recycled, console.log);
							const waterRawMaterial = calculateIntensityRawMaterial(materials, WATER_INDICATOR, recycled);

							const sim_modelization = item?.packaging?.get('sim_modelization');

							const gesTransformation = _.get(sim_modelization, 'gesTransformation', 0);
							const gesTransport = _.get(sim_modelization, 'gesTransport', 0);
							const gesEndOfLife = _.get(sim_modelization, 'gesEndOfLife', 0);
							const waterUseTransformation = _.get(sim_modelization, 'waterUseTransformation', 0);
							const waterUseTransport = _.get(sim_modelization, 'waterUseTransport', 0);
							const waterUseEndOfLife = _.get(sim_modelization, 'waterUseEndOfLife', 0);

							gesPackaging = gesRawMaterial + gesTransformation + gesTransport + gesEndOfLife;
							waterPackaging = waterRawMaterial + waterUseTransformation + waterUseTransport + waterUseEndOfLife;
						}

						return {
							...old[rowIndex],
							[columnId]: value,
							gesPackaging,
							waterPackaging,
							gesProductPerPortion: (gesPackaging * parseFloat(item.weight)) / item.netWeight,
							waterProductPerPortion: (waterPackaging * parseFloat(item.weight)) / item.netWeight,
						};
					}

					return {
						...old[rowIndex],
						[columnId]: value,
					};
				}

				return row;
			});
		});
	};

	// ---- update packaging ---- //
	const { mutate: updatePackaging, isLoading: isLoadingUpdate } = useMutation({
		mutationKey: ['updatePackaging'],
		mutationFn: updatePackagingAction,
		onSuccess: async () => {
			queryClient.invalidateQueries({ queryKey: ['getScenario'] });
			queryClient.invalidateQueries({ queryKey: ['getProductSimulationDataAction'] });
			handleCloseModal();
		},
		onError: (error: any) => {
			queryClient.invalidateQueries({ queryKey: ['getScenario'] });
			queryClient.invalidateQueries({ queryKey: ['getProductSimulationDataAction'] });
		},
	});

	// ---- delete packaging ---- //
	const { mutate: deletePackaging, isLoading: isLoadingDelete } = useMutation({
		mutationKey: ['deletePackaging'],
		mutationFn: deletePackagingAction,
		onSuccess: async () => {
			queryClient.invalidateQueries({ queryKey: ['getScenario'] });
			queryClient.invalidateQueries({ queryKey: ['getProductSimulationDataAction'] });
			handleCloseModal();
		},
		onError: (error: any) => {
			queryClient.invalidateQueries({ queryKey: ['getScenario'] });
			queryClient.invalidateQueries({ queryKey: ['getProductSimulationDataAction'] });
		},
	});

	const savePackaging = (currentId: string, replaceId: string) => {
		updatePackaging({ simulationId: params.simulationId, currentId, replaceId });
	};

	const handleDeleteRow = (row: any) => {
		deletePackaging({ simulationId: params.simulationId, packagingId: row?.packaging?.id });

		// track delete event
		trackEvent('Simulate Eco Design Remove Item', {
			ScenarioName: (simulation as any)?.name,
			Type: 'Packaging',
			ItemLabel: row?.packaging?.label,
		});
	};

	// ---- handle edit ingredient ---- //
	const handleEditRow = useCallback((row: any) => {
		setPackagingSelected(row?.packaging);

		trackEvent('Simulate Eco Design Replace Item', {
			ScenarioName: (simulation as any)?.name,
			Type: 'Packaging',
			ItemLabel: row?.packaging?.label,
		});

		// open modal
		setOpenModal(true);
	}, []);

	const handleRestoreRow = useCallback((row: any) => {
		updatePackaging({
			simulationId: params.simulationId,
			currentId: row?.packaging?.id,
			isRestore: true,
			packagingToRestoreData: _.assign(_.omit(row, ['isOriginal', 'isPresent', 'packaging']), { isRestored: true }),
		});
	}, []);

	// ---- handle add ingredient ---- //
	const handleAddIngredient = useCallback(() => {
		// remove ingredient selected when add  item
		setPackagingSelected(null);
		// open modal
		setOpenModal(true);
	}, []);

	// ---- handle close modal ---- //
	const handleCloseModal = useCallback(() => {
		setOpenModal(false);
	}, []);

	// ---- define table columns ---- //
	const columns = useMemo(() => {
		return [
			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t('Emballages')}
							error={
								dataError?.isError && !isLoading && <ErrorData text={t('simulation-no-packaging-message')} withIcon />
							}
						/>
					);
				},
				accessor: 'label',
				editable: true,
				sortDescFirst: true,
				component: 'TagLabel',
				type: 'string',
				icon: <PackageOpen size={16} color={siteColors.grey700} />,
				props: {
					gap: 8,
					style: {
						color: siteColors.text,
						fontWeight: '600 !important',
						fontSize: '14px !important',
						marginBottom: '0px !important',
					},

					onClick: (row: any) => {
						handleEditRow(row);
					},
					page: 'simulation-scenario',
					className: classNames.label,
				},
			},

			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t('netWeight-packaging')}
							variant="measure"
							measure="weight"
						/>
					);
				},
				accessor: 'weight',
				editable: true,
				sortDescFirst: true,
				type: 'percent',
				component: 'InputField',
				props: {
					suffix: 'gr',
					cloneData: (cloneSimulation as any)?.packaging,
					page: 'simulation-scenario',
					className: classNames?.input,
					prevValueKey: 'weight',
				},
			},

			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('Recyclé')} variant="measure" measure={'percent'} />;
				},
				accessor: 'recycled',
				editable: true,
				sortDescFirst: true,
				type: 'percent',
				component: 'InputField',
				props: {
					suffix: '%',
					cloneData: (cloneSimulation as any)?.packaging,
					page: 'simulation-scenario',
					className: classNames?.input,
					prevValueKey: 'recycled',
					indicator,
					log: console.log,
				},
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('Fin de vie')} />;
				},
				component: 'BarField',
				accessor: 'endOfLife',
				type: 'percent',
				sortDescFirst: true,
				editable: true,
			},

			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t('per_kilo_of_packaging', { per_kilo: t('per_kilo'), packaging: toLowerCase(t('packaging')) })}
							variant="measure"
							measure={indicator === CARBON_INDICATOR ? 'carbonIntensityKg' : 'waterIntensity'}
						/>
					);
				},
				accessor: indicator === CARBON_INDICATOR ? 'gesPackaging' : 'waterPackaging',
				editable: true,
				sortDescFirst: true,
				component: 'PortionField',
				type: 'number',
				props: {
					color: indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500,
				},
			},

			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t('per_kilo_of_product', {
								per_kilo: t('per_kilo'),
								product: toLowerCase(t('product')),
								of_product: t('of_product'),
							})}
							variant="measure"
							measure={indicator === CARBON_INDICATOR ? 'carbonIntensityKg' : 'waterIntensity'}
						/>
					);
				},
				accessor: indicator === CARBON_INDICATOR ? 'gesProductPerPortion' : 'waterProductPerPortion',
				editable: true,
				sortDescFirst: true,
				component: 'PortionField',
				type: 'number',
				props: {
					color: indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500,
				},
			},
		];
	}, [dataTable, cloneSimulation, dataError?.isError, handleEditRow, indicator, isLoading, t]);

	useEffect(() => {
		if (!data) return;

		let dataSorted = (_.orderBy(data?.emballages, [sortField], [sortOrder as any]) as any) || [];

		dataSorted = dataSorted?.map((item: any) => {
			const sim_modelization = _.get(item, 'packaging')?.get('sim_modelization');
			return {
				...item,
				isVersion2x,
				recycledValues: item?.recycled ? getRecycledArray(item?.recycled) : [],
				endOfLife: handleEndOfLife(_.get(sim_modelization, 'end_of_life', _.get(sim_modelization, 'endOfLife', []))),
			};
		});

		setDataTable(dataSorted || []);
	}, [data, sortField, sortOrder, isVersion2x]);

	// ---- updata ingredients store ---- //
	useEffect(() => {
		onPackagingDataChange(dataTable);

		const dataError = checkErrorPackaging(dataTable);
		setDataError(dataError);
	}, [dataTable, onPackagingDataChange, params.simulationId]);

	// ---- handle sorting  table ---- //
	const handleSortingTable = useCallback((value: any) => {
		queryClient.invalidateQueries({ queryKey: ['getScenario'] });
		setSortField(value.id);
		setSortOrder(value.desc === true ? 'desc' : 'asc');
	}, []);

	return (
		<div className="width100">
			<AccordionContainer
				showHeader={false}
				title={t('simulation-packaging-card-title')}
				content={
					<TableComponent
						skeleton={isLoading}
						pageCount={1}
						pageSize={1000}
						columns={columns}
						data={dataTable}
						// data={((data: any) => {
						// 	return _.map(data, (item: any) => {
						// 		let gesPackaging = item?.gesPackaging || 0;
						// 		let waterPackaging = item?.waterPackaging || 0;

						// 		const materials = item?.packaging?.get('sim_modelization')?.materials || [];

						// 		if (item?.recyclable) {
						// 			const recycled = (item?.recycled ?? 0) / 100;

						// 			const gesRawMaterial = calculateIntensityRawMaterial(materials,CARBON_INDICATOR, recycled,  console.log);
						// 			const waterRawMaterial = calculateIntensityRawMaterial(materials, WATER_INDICATOR, recycled);

						// 			const sim_modelization = item?.packaging?.get('sim_modelization');

						// 			const gesTransformation = _.get(sim_modelization, 'gesTransformation', 0);
						// 			const gesTransport = _.get(sim_modelization, 'gesTransport', 0);
						// 			const gesEndOfLife = _.get(sim_modelization, 'gesEndOfLife', 0);
						// 			const waterUseTransformation = _.get(sim_modelization, 'waterUseTransformation', 0);
						// 			const waterUseTransport = _.get(sim_modelization, 'waterUseTransport', 0);
						// 			const waterUseEndOfLife = _.get(sim_modelization, 'waterUseEndOfLife', 0);

						// 			gesPackaging = gesRawMaterial + gesTransformation + gesTransport + gesEndOfLife;
						// 			waterPackaging = waterRawMaterial + waterUseTransformation + waterUseTransport + waterUseEndOfLife;
						// 		}

						// 		return {
						// 			...item,
						// 			gesPackaging,
						// 			waterPackaging,
						// 		};
						// 	});
						// })(dataTable)}
						fetchData={(q: any) => {
							/*  */
						}}
						isSelectable={false}
						pagination={false}
						loading={false}
						manualPagination={false}
						addStyles={styles}
						updateMyData={updateMyData}
						onEditRow={handleEditRow}
						onDeleteRow={handleDeleteRow}
						onRestoreRow={handleRestoreRow}
						withActionToggle
						withEmptyData={false}
						onSortingColumn={handleSortingTable}
						manualSortBy={true}
						dialogTitle={t('simulation-packaging-delete-modal-title')}
						transFunc={t}
					/>
				}
			/>
			<div>
				<BButton
					variant="tertiary"
					label={t('simulation-packaging-add-button')}
					iconLeft={<Plus />}
					className={classNames.addButton}
					onClick={handleAddIngredient}
				/>
			</div>

			<PackagingsModalSelect
				open={openModal}
				onClose={handleCloseModal}
				title={
					packagingSelected ? t('simulation-packaging-modal-replace-title') : t('simulation-packaging-modal-add-title')
				}
				subTitle={
					packagingSelected
						? `${t('simulation-packaging-modal-replace-subtitle')} ${packagingSelected?.get('label')?.toUpperCase()}`
						: t('simulation-packaging-modal-add-subtile')
				}
			>
				<CreationPackagingTable packaging={packagingSelected} emballages={dataTable} onSavePackaging={savePackaging} />
			</PackagingsModalSelect>
			<LoadingDialog open={isLoadingDelete} content={`${t('Suppression en cours')}...`} />
			<LoadingDialog open={isLoadingUpdate} content={`${t('Modification en cours')}...`} />
		</div>
	);
};

export default Packaging;

const handleEndOfLife = (endOfLife?: any[]) => {
	if (!endOfLife) return [];

	const myMap = new Map();

	endOfLife?.forEach((eol) => {
		const labelCmaps: string = _.get(eol, 'labelCmaps');
		const composition = _.get(eol, 'composition', 0);

		if (labelCmaps.includes('Recycla')) {
			const val = myMap.get('Recyclable') || {
				composition: 0,
				type: 'Recyclable',
			};

			myMap.set('Recyclable', {
				...val,
				composition: _.get(val, 'composition', 0) + composition,
			});
		}

		if (labelCmaps.includes('Décharge')) {
			const val = myMap.get('Décharge') || {
				composition: 0,
				type: 'Décharge',
			};

			myMap.set('Décharge', {
				...val,
				composition: _.get(val, 'composition', 0) + composition,
			});
		}

		if (labelCmaps.includes('Incinération')) {
			const val = myMap.get('Incinération') || {
				composition: 0,
				type: 'Incinération',
			};

			myMap.set('Incinération', {
				...val,
				composition: _.get(val, 'composition', 0) + composition,
			});
		}
	});

	return manageEndOfLife(Array.from(myMap.values()));
};
