import { useMemo, useRef } from 'react';

import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_More from 'highcharts/highcharts-more';
import _ from 'lodash';
import ReactDOMServer from 'react-dom/server';

import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import { siteColors } from '../../lib/colors';
HC_More(Highcharts);

export type ChartData= {
	name: string;
	y?: number;
	isIntermediateSum?: boolean;
	isSum?: boolean;
	color?:string;
};

type WaterfallChartProps = {
	data: Array<ChartData>,
	maxYAxis?: number,
}

const WaterfallChart = ({ data, maxYAxis }: WaterfallChartProps) => {


	const maxYAxisValue = useMemo(() => {
		if(maxYAxis) return maxYAxis;
		const sumY = _.sumBy(data, 'y');
		return sumY;
	}, [data, maxYAxis]);

	// --------------------------------------------------- //
	// ------------- tooltipFormatter -------------------- //
	// --------------------------------------------------- //
	const tooltipFormatter: any = function (this: any) {
		return ReactDOMServer.renderToString(
			<div className="flexColumn gap4">
				<span style={{ fontSize: '14px', fontStyle: 'normal', fontWeight: 500, marginBottom: 0, lineHeight: 1.2 }}>{this.point.name}</span>
				<span style={{ fontSize: '14px', fontStyle: 'normal', fontWeight: 400, marginBottom: 0, lineHeight: 1.2, color: siteColors.chartGrey }}>{formatNumber(this.point?.y, undefined, 0)} tCO<sub>2</sub>e</span>
			</div>,
		);
	};

	const options = {
    chart: {
      type: 'waterfall',
			responsive: true,
    },
    title: {
      text: '',
    },
    xAxis: {
      type: 'category',
			labels: {
				formatter: function (v: any) {
					// ----- get value ---- //
					const key =  v.pos;
					// Customizing labels: add ' - Custom' to each label
					return `<span style="fill:${siteColors.chartGrey}; font-weight: 500 !important">${ v.value}</span></br><div class="chartGrey" style="fill:${siteColors.chartGrey}; font-size: 12px !important">${formatNumber(data[key]?.y, undefined, 0)} tCO<sub>2</sub>e</div>`;
				},

			},
    },
    yAxis: {
      title: {
        text: '',
      },
			max: maxYAxisValue,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
			useHTML: true,
			formatter: tooltipFormatter,
    },
    series: [{
			upColor: '#DDE7CB',
        color: '#D8E0F3',
				data,
      dataLabels: {
        enabled: false,
        format: '{point.y:.2f}',
      },
      pointPadding: 0,
			borderColor: 'transparent',
    }],
		credits: {
			enabled: false, // Désactiver le logo Highcharts
		},
  };
	const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
		return (
			<div className='width100'>
				<HighchartsReact
					highcharts={Highcharts}
					options={options}
					ref={chartComponentRef}
					containerProps={{
						style: {
							height:'195px',
						},
					}}
				/>
			</div>
	);
};

export default WaterfallChart;
