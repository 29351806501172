import { useEffect, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import _ from 'lodash';
import { RefreshCw } from 'lucide-react';
import { useParams } from 'react-router-dom';

import { CARBON_INDICATOR } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import BlockCard from '../../components/BlockCard';
import BlockTitle from '../../components/BlockTitle';
import LightTooltipGlossary from '../../components/LightTooltipGlossary';
import Skeleton from '../../components/Skeleton';
import Unit from '../../components/units/Unit';
import { useGlossary } from '../../hooks/useGlossary';
import { useTranslation } from '../../hooks/useTranslation';
import { cn, siteColors } from '../../lib/colors';
import * as simulationUtils from '../../utils/simulation.utils';

import LineDisplay from './LineDisplay';
import { useScenario } from './scenario.hooks';
import { useDataSimulation } from './useDataSimulation.hooks';

const styles = {
	block: css({
		background: 'white',
		border: 'none!important',
	}),
	skeleton: css({
		height: '300px !important',
		width: '100% !important',
	} as any),

	title: css({
		gap: 8,
		'& span': {
			color: cn(siteColors.grey700, '!important'),
			fontSize: '12px !important',
		},
	}),
};

const LifeCycleBlock = () => {
	const params = useParams();

	const { isLoading } = useScenario({
		simulationId: params.simulationId || '',
	});

	const { indicator } = useApp();

	// translation
	const { t } = useTranslation();
	// glossary
	const { g } = useGlossary();
	const { simulation } = useDataSimulation();
	const [dataGraphMap, setDataGraphMap] = useState<any>(null);

	const content = useMemo(() => {
		if (!dataGraphMap) return;
		return (
			<>
				{[
					{
						text: t('agriculture'),
						value: dataGraphMap.get('gesAgriculture')?.value,
						difference: dataGraphMap.get('gesAgriculture')?.diff,
						percentValue: dataGraphMap.get('gesAgriculture')?.percentValue,
						percentDiff: dataGraphMap.get('gesAgriculture')?.percentDiff,
					},
					{
						text: t('transformation'),
						value: dataGraphMap.get('gesTransformation')?.value,
						difference: dataGraphMap.get('gesTransformation')?.diff,
						percentValue: dataGraphMap.get('gesTransformation')?.percentValue,
						percentDiff: dataGraphMap.get('gesTransformation')?.percentDiff,
					},
					{
						text: t('emballage'),
						value: dataGraphMap.get('gesPackaging')?.value,
						difference: dataGraphMap.get('gesPackaging')?.diff,
						percentValue: dataGraphMap.get('gesPackaging')?.percentValue,
						percentDiff: dataGraphMap.get('gesPackaging')?.percentDiff,
					},
					{
						text: t('transport'),
						value: dataGraphMap.get('gesTransport')?.value,
						difference: dataGraphMap.get('gesTransport')?.diff,
						percentValue: dataGraphMap.get('gesTransport')?.percentValue,
						percentDiff: dataGraphMap.get('gesTransport')?.percentDiff,
					},
					{
						text: t('distribution'),
						value: dataGraphMap.get('gesDistribution')?.value,
						difference: dataGraphMap.get('gesDistribution')?.diff,
						percentValue: dataGraphMap.get('gesDistribution')?.percentValue,
						percentDiff: dataGraphMap.get('gesDistribution')?.percentDiff,
					},
					{
						text: t('consommation'),
						value: dataGraphMap.get('gesConsumption')?.value,
						difference: dataGraphMap.get('gesConsumption')?.diff,
						percentValue: dataGraphMap.get('gesConsumption')?.percentValue,
						percentDiff: dataGraphMap.get('gesConsumption')?.percentDiff,
					},
				].map((e, index) => {
					return (
						<LineDisplay
							key={index}
							text={e.text}
							difference={e.difference}
							value={e.value}
							percentValue={e?.percentValue}
							percentDiff={e?.percentDiff}
							withEquals={false}
							showZero={true}
							showLte={true}
						/>
					);
				})}
			</>
		);
	}, [dataGraphMap, t]);

	// ---- trigger render when change ingredients or packaging ---- //
	useEffect(() => {
		if (!simulation) return;
		const dataGraphMap = simulationUtils.getDataLifeCycle(simulation, indicator);
		setDataGraphMap(dataGraphMap);
	}, [simulation, indicator]);

	return (
		<>
			<BlockTitle
				icon={<RefreshCw className="grey700" width={20} height={20} />}
				className={cx('flexRow alignCenter', styles.title)}
			>
				<LightTooltipGlossary title={g('cycle-de-vie')}>
					<div className="underline">{t('life-cycle-graph-title')}</div>
				</LightTooltipGlossary>{' '}
				<Unit measure={indicator === CARBON_INDICATOR ? 'carbonIntensityKg' : 'waterIntensity'} />
			</BlockTitle>
			<BlockCard className={cx(styles.block)}>
				{isLoading
? (
					<Skeleton width="100%!important" className={styles.skeleton}>
						{content}
					</Skeleton>
				)
: (
					content
				)}
			</BlockCard>
		</>
	);
};

export default LifeCycleBlock;
