import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { create } from 'zustand';

export const useGetSectorSheetById = ({ sheetId }: { sheetId: string }) => {
	const key = ['getSectorSheetById', { sheetId }] as const;

	const result = useQuery({
		queryKey: key,
		queryFn: async (ctx) => {
			try {
				const p = ctx.queryKey[1];

				const sheet = await Parse.Cloud.run('getSectorSheetById', { sheetId: p.sheetId });

				if (sheet.notFound) {
					return null;
				}

				return sheet;
			} catch (error) {
				console.log('----- getSectorSheetById error', error);
				return Promise.reject(error);
			}
		},
	});

	return {
		key,
		result,
	};
};

export const useSaveSheetGroup = ({ onSuccess }: { onSuccess: any }) => {
	const key = ['saveSheetGroup'] as const;
	const queryClient = useQueryClient();
	const { setGlobalLoading } = useGlobalLoading();

	const result = useMutation({
		mutationKey: key,
		mutationFn: async (p: { categoryId?: string; name_en: string; name_fr: string }) => {
			try {
				return Parse.Cloud.run('saveSectorSheetCategory', p);
			} catch (error) {
				console.log('------ saveSheetGroup error', error);
				return Promise.reject(error);
			}
		},
		onSuccess(data, variables, context) {
			onSuccess?.(data, variables, context);
		},
		onError(_error, _variables, _context) {
			queryClient.invalidateQueries(['findSectorSheetGroups']);
			setGlobalLoading(false);
		},
	});

	return {
		key, result,
	};
};

export const useGlobalLoading = create((set: any) => {
	return {
		isGlobalLoading: false,
		setGlobalLoading: (newValue: boolean) => {
			return set((state: any) => {
				return {
					...state,
					isGlobalLoading: newValue,
				};
			});
		},
	};
});

export const useMoveGroupOrder = () => {
	const { setGlobalLoading } = useGlobalLoading();
	const key = ['moveGroupOrder'] as const;

	const result = useMutation({
		mutationKey: key,
		mutationFn: async (p: { oldIndex: number; newIndex: number, categoryId: string }) => {
			try {
				//
				await Parse.Cloud.run('moveSectorSheetCategoryOrder', p);
			} catch (error) {
				console.log('----- moveGroupOrder error', error);
				return Promise.reject(error);
			}
		},
		onSettled(data, error, variables, context) {
			setGlobalLoading(false);
		},
	});

	return { key, result };
};
