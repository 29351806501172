import _ from 'lodash';
import { create } from 'zustand';

/**
 * use zustand
 * hooks to store rows table selected
 */
export const useRowTableSelection = create((set: any) => {
	return {
		selectedRows: [] as any,
		onSelectionChange: (selection: any) => {
			if (!selection) return;
			return set((state: any) => {
				let value = selection;

				if (_.isFunction(selection)) {
					value = selection(state.selectedRows);
				}

				return {
					selectedRows: value,
				};
			});
		},
		removeAllSelectedRows: () => {
			return set({ selectedRows: [] });
		},
	};
});

/**
 * use zustand
 * hooks to store option selected in table
 */

export const useOptionTableSelection = create((set: any) => {
	return {
		selectedOption: {} as any,
		setSelectedOption: (selectedOption: any) => {
			return set({ selectedOption: selectedOption });
		},
	};
});

/**
 * use zustand
 * hooks to store rows table expanded
 */
export const useRowTableExpandedOpen = create((set: any) => {
	return {
		expandedRows: [] as any,
		onSelectionExpandedOpen: (selection: any) => {
			return set(() => {
				return {
					expandedRows: selection,
				};
			});
		},
	};
});
