import { create } from 'zustand';

//use in When we don't have the water KPI, we freeze the switch
export const useImpactWaterSwitch = create((set: any) => {
	return {
		switchWaterActive: true,
		setSwitchWaterActive: (value: boolean) => {
			return set({ switchWaterActive: value });
		},
	};
});
