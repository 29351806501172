import { ReactNode, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';

import { siteColors } from '../../lib/colors';

const calculateEndAngle = (startAngle: number, percentage: number) => {
	return startAngle + (percentage / 100) * (2 * Math.PI);
};

type CircularProgressProps = {
	percent?: number;
	width?: number;
	innerRadius?: number;
	content?: React.ReactNode;
	containerWidth?: string;
	fillColor?: string;
	otherColor?: string;
	withBorder?: boolean;
	withTooltip?: boolean;
	renderTooltip?: (item: any, indicator: any) => ReactNode;
	indicator?: any;
	data?: any[];
	// tooltipPosition?: number;
};

const CircularProgress = ({
	percent = 70,
	width = 220,
	innerRadius = 42,
	content,
	containerWidth,
	fillColor,
	otherColor = siteColors.grey500,
	withBorder = false,
	withTooltip = false,
	renderTooltip,
	indicator,
	data = [],
}: // tooltipPosition = 180,
CircularProgressProps) => {
	const viewBoxWidth = width;
	const viewBoxHeight = width;
	const radius = viewBoxWidth / 2;
	const _innerRadius = (viewBoxWidth * innerRadius) / 100;
	const innerBoxWidth = _innerRadius * Math.sqrt(2); /* - 1 */
	const innerBoxHeight = innerBoxWidth;

	const startPercentAngle = -Math.PI / 2;

	const getPath = (startAngle: number, endAngle: number) => {
		const x1 = viewBoxWidth / 2 + radius * Math.cos(startAngle);
		const y1 = viewBoxHeight / 2 + radius * Math.sin(startAngle);
		const x2 = viewBoxWidth / 2 + radius * Math.cos(endAngle);
		const y2 = viewBoxHeight / 2 + radius * Math.sin(endAngle);
		const largeArcFlag = endAngle - startAngle <= Math.PI ? '0' : '1';

		const path = `M${viewBoxWidth / 2},${
			viewBoxHeight / 2
		} L${x1},${y1} A${radius},${radius} 0 ${largeArcFlag} 1 ${x2} ${y2} Z`;

		return path;
	};

	const endPercentAngle = calculateEndAngle(startPercentAngle, percent === 100 ? 99.99 : !percent ? 0.01 : percent);
	const fillPath = getPath(startPercentAngle, endPercentAngle);

	const endAngleEmpty = calculateEndAngle(endPercentAngle, 100 - percent);
	const emptyPath = getPath(endPercentAngle, endAngleEmpty);

	const tooltipRef = useRef<any>();
	const svgRef = useRef<any>();
	const [selectedItem, setSelectedItem] = useState(null);

	const onMouseEnter = (evt: any, data: any) => {
		const CTM = svgRef.current.getScreenCTM();

		const mouseX = (evt.clientX - CTM.e) / CTM.a;
		const mouseY = (evt.clientY - CTM.f) / CTM.d;
		const top = /* tooltipPosition ? mouseY - tooltipPosition.y : */ mouseY - /* topPosition */ 55;

		tooltipRef.current.setAttributeNS(null, 'visibility', 'visible');

		tooltipRef.current.setAttributeNS(
			null,
			'style',
			`left: ${mouseX + 6 / CTM.a}px; top: ${top}px; position: absolute; zIndex: 100000; display:none`,
		);
		setSelectedItem(data);
	};

	return (
		<Box
			className="flexColumn"
			sx={{
				width: containerWidth || '200px',

				// flex: 1,
				position: 'relative',
				'&:hover .custom-tooltip': { display: 'block !important', zIndex: 100000 },
			}}
		>
			{withTooltip && (
				<Box
					className="custom-tooltip"
					ref={tooltipRef}
					style={{ display: 'none', /*  background: 'red', */ position: 'absolute', left: 100 }}
				>
					{
						renderTooltip ? renderTooltip(selectedItem, indicator) : null /* (
						<TooltipChart item={selectedItem} indicator={indicator} viewModeSelected={viewModeSelected} />
					) */
					}
				</Box>
			)}
			<svg
				viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
				width={'100%'}
				height={'auto'}
				xmlns="http://www.w3.org/2000/svg"
				ref={svgRef}
			>
				<path
					d={fillPath}
					fill={fillColor || siteColors.water500}
					strokeLinecap="round"
					strokeWidth={withBorder ? 3 : 0}
					stroke={siteColors.common.white}
					onMouseEnter={(evt: any) => {
						onMouseEnter(evt, data[0] /* null */);
					}}
				/>
				<path
					d={emptyPath}
					fill={otherColor}
					strokeWidth={withBorder ? 3 : 0}
					stroke={siteColors.common.white}
					onMouseEnter={(evt: any) => {
						onMouseEnter(evt, data[1] /*  null*/);
					}}
				/>
				<circle cx={viewBoxWidth / 2} cy={viewBoxHeight / 2} r={_innerRadius} fill="#ffffff" />
				<foreignObject
					// fill="red"
					x={viewBoxWidth / 2 - innerBoxWidth / 2}
					y={viewBoxHeight / 2 - innerBoxHeight / 2}
					width={innerBoxWidth}
					height={innerBoxHeight}
				>
					<Box width="100%" height="100%" className="flexCenter">
						{content || `${percent} %`}
					</Box>
				</foreignObject>
			</svg>
		</Box>
	);
};

export default CircularProgress;
