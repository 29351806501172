import { css, cx } from '@emotion/css';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import dayjs from 'dayjs';
import _ from 'lodash';
import { Carrot, Wallet } from 'lucide-react';
import { Link } from 'react-router-dom';

import { ReactComponent as SupplierIcon } from '@carbonmaps/media/icons/supplier.svg';

import BlueCheck from '../../../../../apps/front/src/components/BlueCheck';
import { useRowTableExpandedOpen } from '../../../hooks/useRowTableSelection';
import { toLowerCase } from '../../../utils/utils';

import { IndeterminateCheckbox } from './TableComponent';

type Props = {
	value: any;
	row: any;
	column: any;
	updateMyData: (...params: any[]) => void;
};

const styles = {
	link: css({
		textDecoration: 'none',
	}),
	container: css({
		gap: 16,
		paddingTop: 8,
		paddingBottom: 8,
		paddingLeft: 'var(--padding-left)',
	}),

	IconContent: css({
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: '8px',
		width: 36,
		height: 36,
	}),

	modelizationContent: css({
		gap: '8px',
		marginBottom: 3,
	}),

	marginText: css({
		marginLeft: 'var(--margin-text)',
	}),

	// text0: css({
	// 	// whiteSpace: 'nowrap',
	// 	marginBottom: '6px',
	// }),
	text1: css({
		whiteSpace: 'nowrap',
		fontSize: '12px !important',
		marginLeft: '111px',
		marginTop: '-10px',
		marginBottom: '8px',
		fontWeight: 400,
	}),

	text2: css({
		fontWeight: '400!important',
		fontSize: '12px!important',
	}),
};

const getIcon = ({ theme, column, itemType }: any) => {
	if (column?.props?.page === 'devisCarbone') {
		return <Wallet color={theme.palette.grey[700]} />;
	}

	return column?.props?.page !== 'simulation'
? (
		itemType === 'supplier'
? (
			<SupplierIcon css={{ '& path': { fill: theme.palette.grey[700] } }} width={20} height={20} />
		)
: (
			<Carrot color={theme.palette.grey[700]} />
		)
	)
: null;
};

const AcvLabel = ({ value, row, column }: Props) => {
	const theme = useTheme();
	const itemType = row.original?.itemType;
	const { onSelectionExpandedOpen, expandedRows } = useRowTableExpandedOpen();

	const isTagAdvancedModelization = row?.original?.tagAdvancedModelization === 'true';

	const transFunction = () => {
		console.log('test');
	};

	const t = column?.props?.trans || transFunction;
	const enableSelection = column?.props?.enableSelection ?? true;

	const icon = getIcon({
		column,
		theme,
		itemType,
	});
	return (
		<Link
			to={
				row.original?.subRowLink
					? row.original.subRowLink
					: row.original?.link
					? `${row.original.link}/${row.original.objectId || row.original._id}/synthesis`
					: '#'
			}
			className={styles.link}
			target={column?.link?.target ? '_blank' : '_self'}
			onClick={() => {
				row.toggleRowExpanded();

				if (column?.props?.onClick) column?.props?.onClick(row.original);

				// add row in the list of expanded rows else remove from the expanded rows
				if (row.canExpand && !row.isExpanded) {
					onSelectionExpandedOpen([...expandedRows, row?.original?.objectId]);
				} else {
					onSelectionExpandedOpen([
						...expandedRows.filter((item: any) => {
							return item !== row?.original?.objectId;
						}),
					]);
				}
			}}
		>
			<div className={cx('flexRow alignCenter nowrap', styles.container)}>
				{row.depth > 0 ? <span css={{ width: 15 }} /> : null}
				{enableSelection ? <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} stopPropagation /> : null}
				{row?.original?.level === 2 && icon
? (
					<div className={cx('bgGrey400', styles.IconContent)}>{icon}</div>
				)
: column?.icon && row?.original?.level !== 2
? (
					<div className={cx('bgGrey400', styles.IconContent)}>{column?.icon}</div>
				)
: null}

				<div className={styles.marginText}>
					<div className={cx('flexRow alignCenter', styles.modelizationContent)}>
						<BlueCheck
							iconSize={16}
							title={value}
							fontSize={14}
							withIcon={column?.props?.page !== 'devisCarbone' ? isTagAdvancedModelization : false}
							fontWeight={400}
							ok={isTagAdvancedModelization}
							variant="body1"
						/>
					</div>

					{row.original.level === 2 &&
					(column?.props?.page === 'simulation' || column?.props?.page === 'devisCarbone')
? (
						<Typography className={cx('grey700', styles.text2)}>
							{_.capitalize(t('modify-day', { dayCount: row.original?.daysUpdateDifference }))}
						</Typography>
					)
: row.original.reference || row.original.uid
? (
						<Typography variant="h5" className="grey700">
							{row.original.reference
								? row.original.reference?.replace('devis', `${toLowerCase(t('quote'))}(s)`)
								: row.original.uid}
						</Typography>
					)
: null}
				</div>
			</div>

			{row?.original?.level !== 2 && column?.props?.page === 'simulation'
? (
				<div className={cx(styles.text1, 'grey700')}>
					{t('date-import')} {dayjs(row.original?.originalProduct?.createdAt).format('D/MM/YYYY')}{' '}
				</div>
			)
: null}
		</Link>
	);
};

export default AcvLabel;
