import { Presentation } from 'lucide-react';

import { ALL_YEAR_OPTION_VALUE } from '@carbonmaps/shared/utils/constants';

import IconContainer from '../../components/layout/list/IconContainer';
import ListHeader from '../../components/layout/list/ListHeader';
import Typography from '../../components/Typography';
import { useYearSelection } from '../../hooks/useImpactYear';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';

type SBTiFlagHeaderProps= {
	className?: string;
}

const SBTiFlagHeader = ({ className }: SBTiFlagHeaderProps) => {
	const { t }= useTranslation();
	const { selectedYear } = useYearSelection();

	return (
		<ListHeader
				title={t('SBTi FLAG')}
				icon={
					<IconContainer
						color={siteColors.sbtiFlag.gesFlag}
						element={<Presentation  size={32} color={siteColors.common.white}/>}
					/>
				}
				subtitle={<Typography className="grey7 fontWeight500">{selectedYear !== ALL_YEAR_OPTION_VALUE ? t('rapport-sbti-flag-header', { period: selectedYear }): t('rapport-sbti-flag-header-all-year')}</Typography>}
				className={className}
		/>
	);
};

export default SBTiFlagHeader;
