
import { Calendar } from 'lucide-react';

import { ReactComponent as PerKiloIcon } from '@carbonmaps/media/icons/perKiloChart.svg';
import { CARBON_INDICATOR, WATER_INDICATOR } from '@carbonmaps/shared/utils/constants';

import Unit from '../components/units/Unit';

// ------------------------------------------------ //
// ------------ available compare types ----------- //
// ------------------------------------------------ //
export const PER_KILO_TYPE = 'per_kilo';
export const PER_PERIOD_TYPE = 'per_period';

export const compareTypeOptions = [
	{
		value: PER_KILO_TYPE,
		label: 'Par kilo',
		icon: <PerKiloIcon />,

	},
	// {
	// 	value: 'per_period',
	// 	label: 'Par period',
	// 	icon: <Calendar size={16} />,
	// },
];

// ----------------------------------------------------- //
// -------------- compare product config --------------- //
// ----------------------------------------------------- //

export const compareProductConfig = new Map();

compareProductConfig.set(PER_KILO_TYPE, {
	[CARBON_INDICATOR]: {
		field: 'carbonIntensity',
		unit: <Unit measure="carbonIntensityKg" className="grey7"/>,
		precision: 2,
	},
	[WATER_INDICATOR]: {
		field: 'waterIntensity',
		unit: <Unit measure="waterIntensity" className="grey7"/>,
		precision: 2,
	},
});

compareProductConfig.set(PER_PERIOD_TYPE, {
	[CARBON_INDICATOR]: {
		field: 'carbonImpact',
		unit: <Unit measure="carbonImpactTon" className="grey7"/>,
		precision: 0,
	},
	[WATER_INDICATOR]: {
		field: 'waterImpact',
		unit: <Unit measure="waterImpact" className="grey7"/>,
		precision: 0,
	},
});
