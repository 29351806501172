import { useCallback, useState } from 'react';

import { css, cx } from '@emotion/css';
import AddIcon from '@mui/icons-material/Add';
import { IconButton, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import _ from 'lodash';
import { AlertTriangle, Building, Edit, Info, MoreHorizontal, Send, Trash2, UserMinus } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { useToggle } from 'react-use';

import { classNames, functionName, questionsStatusType } from '@carbonmaps/shared/utils/constants';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import BPopover from '@carbonmaps/ui/components/saas/BPopover';
import DeleteConfirm from '@carbonmaps/ui/components/saas/DeleteConfirm';

import FlashMessage from '../../../../../../../components/bo/FlashMessage';
import Popover from '../../../../../../../components/Popover';
import Skeleton from '../../../../../../../components/Skeleton';
import { useJuneTrack } from '../../../../../../../hooks/useJuneTrack';
import { useTranslation } from '../../../../../../../hooks/useTranslation';
import { cn, siteColors } from '../../../../../../../lib/colors';
import { removeContactSupplierCampaignAction } from '../../../../../../../lib/react-query/features/question/question.actions';
import StatusChip from '../StatusChip';

import SuperModal from './modal/SuperModal';

const styles = {
	popCount: css({
		padding: '8px',
	}),
};

const ContactsList = ({ objectIds, supplier }: { objectIds: string[]; supplier: Record<string, any> }) => {
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);
	const [openAlert, setOpenAlert] = useToggle(false);
	const [messageToast, setMessageToast] = useState<string | null>(null);

	const supplierName = _.get(supplier, 'name');
	const supplierUid = _.get(supplier, '_id');

	const queryKey = ['contacts', { objectIds }] as const;

	const { data, isLoading } = useQuery({
		queryKey,
		queryFn: async (ctx) => {
			try {
				const { objectIds } = ctx.queryKey[1];
				return Parse.Cloud.run(functionName.questions.findContactsListForSupplierTracking, { objectIds });
			} catch (error) {
				console.log('--- fetchContacts action error ----', error);
				return Promise.reject(error);
			}
		},
		enabled: _.isArray(objectIds) && !_.isEmpty(objectIds),
	});

	const params = useParams();
	const qFormId = _.get(params, 'questionFormId');

	const queryClient = useQueryClient();

	const { mutate: reOpenSupplier } = useMutation({
		mutationKey: ['reOpenSupplier'],
		mutationFn: async ({ email, languageCode, qFormId, firstName, lastName, supplierUid, supplierName }: { languageCode: string; email: string; qFormId: string; supplierUid: string; supplierName: string; firstName: string; lastName: string; }) => {
			try {
				await Parse.Cloud.run('reOpenSupplier', { email, languageCode, qFormId, supplierUid, firstName, lastName, supplierName });
			} catch (error) {
				return Promise.reject(error);
			}
		},
		onSuccess: (data, { email, qFormId, supplierUid }) => {
			queryClient.invalidateQueries({ queryKey: ['relaunchCount', { supplierUid, email, qFormId }] });
		},
	});

	// ---- remove contact from question ---- //
	const { mutate: removeContactSupplierCampaign } = useMutation({
		mutationKey: ['removeContactSupplierCampaign'],
		mutationFn: removeContactSupplierCampaignAction,
		onSuccess: async (e) => {
			if (e?.isLast === true) {
				await queryClient.invalidateQueries(['findSuppliersTracking']);
			}

			await queryClient.invalidateQueries(['contacts']);
			setMessageToast(t('toast-contact-removed', { email: e.email }));
			setOpenAlert(true);
		},
		onError: (error: any) => {
			//	setOpen(false);
		},
	});

	const analytics = useJuneTrack();

	const trackEvent = useCallback(
		(name: string) => {
			if (!analytics) return;
			analytics.track(name);
		},
		[analytics],
	);

	const renderModal = <SuperModal open={isOpen} setOpen={setIsOpen} supplier={supplier} data={data} />;

	if (isLoading) {
		return (
			<>
				<Skeleton height={40} />
				{renderModal}
			</>
		);
	}

	return (
		<>
			<div>
				<FlashMessage open={openAlert} onClose={setOpenAlert} icon={<AlertTriangle />} message={messageToast} />
			</div>
			<div className="flexRow" css={{ gap: 12 }}>
				{(objectIds.length === 1 && _.get(data[0], 'statusType') === questionsStatusType.NO_CONTACT) ||
					data.length === 1 && _.get(data[0], 'statusType') === questionsStatusType.NO_CONTACT
					? (
						<BButton
							onClick={() => {
								setIsOpen(true);
							}}
							iconLeft={<AddIcon />}
							variant="tertiary"
							label={
								<Typography color={siteColors.grey9} fontWeight={400} fontSize={14}>
									{t('Ajouter un contact')}
								</Typography>
							}
							addStyles={{
								flexGrow: 1,
								border: cn('1px solid', siteColors.grey400),
								boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
								borderRadius: '4px',
								justifyContent: 'left',
								['& > .contact-item' as never]: {
									padding: '6px 6px 6px 12px',
									borderBottom: cn('1px solid', siteColors.grey400),
									'&:last-child': {
										borderBottom: 'none',
									},
								},
							}}
						/>
					)
					: (
						<div
							css={{
								flexGrow: 1,
								border: cn('1px solid', siteColors.grey400),
								boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
								borderRadius: '4px',
								['& > .contact-item' as never]: {
									padding: '6px 6px 6px 12px',
									borderBottom: cn('1px solid', siteColors.grey400),
									'&:last-child': {
										borderBottom: 'none',
									},
								},
							}}
						>
							{_.map(data, (item, index) => {
								const email = _.get(item, 'contact.email');
								const firstName = _.get(item, 'contact.firstName');
								const lastName = _.get(item, 'contact.lastName');

								return (
									<div className={cx('contact-item flexRow spaceBetween alignCenter')} key={index}>
										<div>{email}</div>
										<div className={cx('flexRow alignCenter grey700')} css={{ gap: 16 }}>
											{_.get(item, 'isError')
												? (
													<div css={{ position: 'relative', top: 4 }}>
														<Info color={siteColors.orange500} size={20} />
													</div>
												)
												: null}
											<RelaunchCount supplierUid={supplierUid} email={email} qFormId={String(qFormId)} />
											<div>{dayjs(_.get(item, 'actionDate')).format('DD/MM/YYYY')}</div>
											<div>
												<StatusChip statusType={_.get(item, 'statusType')} />
											</div>
											<div>
												<Popover
													anchorOrigin={{
														horizontal: 'left',
														vertical: 'top',
													}}
													transformOrigin={{
														horizontal: 'right',
														vertical: 'top',
													}}
													trigger={
														<IconButton disableRipple>
															<MoreHorizontal size={20} />
														</IconButton>
													}
													renderContent={({ close }) => {
														return (
															<div
																css={{
																	padding: '8px',
																}}
															>
																<div css={{ paddingLeft: 10, paddingRight: 10, marginBottom: 10 }}>
																	<div css={{ color: siteColors.text, fontSize: 14, fontWeight: 600 }}>{email}</div>
																	<div className="grey700" css={{ fontSize: 14, fontWeight: 500 }}>
																		{supplierName}
																	</div>
																</div>
																<DeleteConfirm
																	title={''}
																	subMessageConfirmDelete={t('Envoyer un mail de demande de réponse au questionnaire à', {
																		email,
																	})}
																	confirmText={t('send')}
																	onClick={(close2?: VoidFunction) => {
																		reOpenSupplier({
																			email,
																			languageCode: _.get(item, 'contact.language', 'fr'),
																			qFormId: String(qFormId),
																			supplierUid,
																			firstName,
																			lastName,
																			supplierName,
																		});
																		trackEvent('Individual form sending');
																		close2 && close2();
																		close && close();
																	}}
																	anchorOrigin={{
																		horizontal: 'center',
																		vertical: 'bottom',
																	}}
																	transformOrigin={{
																		horizontal: 'center',
																		vertical: 'top',
																	}}
																>
																	<div>
																		<BButton
																			variant="tertiary"
																			iconLeft={<Send size={20} />}
																			disableRipple
																			label={t('Relancer ce contact')}
																		/>
																	</div>
																</DeleteConfirm>
																<div>
																	<BButton
																		variant="tertiary"
																		iconLeft={<UserMinus size={20} />}
																		disableRipple
																		label={`${t('Retirer ce contact de la campagne')}`}
																		onClick={() => {
																			removeContactSupplierCampaign({
																				email,
																				questionFormId: String(qFormId),
																				supplierUid,
																			});
																			close && close();
																		}}
																	/>
																</div>

															</div>
														);
													}}
												/>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					)}

				<Popover
					css={{ marginRight: 34 }}
					anchorOrigin={{
						horizontal: 'left',
						vertical: 'top',
					}}
					transformOrigin={{
						horizontal: 'right',
						vertical: 'top',
					}}
					trigger={
						<IconButton disableRipple css={{ position: 'relative', top: 6.5 }}>
							<Edit size={20} />
						</IconButton>
					}
					renderContent={({ close }) => {
						return (
							<div
								css={{
									padding: '8px',
								}}
							>
								<div css={{ color: siteColors.text, fontSize: 16, fontWeight: 600, paddingLeft: 10 }}>
									{supplierName}
								</div>
								<BButton
									variant="tertiary"
									iconLeft={<Edit size={20} />}
									disableRipple
									label={t('Modifier les contacts du fournisseur')}
									onClick={() => {
										close();
										setIsOpen(true);
									}}
								/>
							</div>
						);
					}}
				/>
			</div>

			{renderModal}
		</>
	);
};

export default ContactsList;

const RelaunchCount = ({ supplierUid, email, qFormId }: { supplierUid: string; email: string; qFormId: string }) => {
	const { data: count, isLoading } = useQuery({
		queryKey: ['relaunchCount', { supplierUid, email, qFormId }] as const,
		queryFn: async (ctx) => {
			try {
				const { supplierUid, email, qFormId } = ctx.queryKey[1];

				const qForm = new Parse.Object(classNames.QUESTION_FORM);
				qForm.id = qFormId;

				const query = new Parse.Query(classNames.QUESTION_FORM_STATUS_UPDATE)
					.equalTo('questionForm', qForm)
					.containedIn('statusType', [questionsStatusType.SUPPLIER_CONTACTED, questionsStatusType.RELAUNCH])
					.equalTo('supplier.uid', supplierUid)
					// .equalTo('contact.email', email);
					.containedIn('contact.email', [email, _.toLower(email)]);

				const count = await query.count();
				return count;
			} catch (error) {
				return Promise.reject(error);
			}
		},
	});

	const { t } = useTranslation();

	if (isLoading) {
		return <Skeleton width={40} height={40} />;
	}

	return (
		<div>
			<BPopover
				modeHover
				content={(onClose: () => void) => {
					return (
						<div className={cx(styles.popCount)}>
							<div>{t('relaunch-count', { count })}</div>
						</div>
					);
				}}
			>
				<div className="flexRow alignCenter">
					<div className="grey700" css={{ marginRight: 6 }}>
						{count}
					</div>
					<Send size={20} color={siteColors.grey500} />
				</div>
			</BPopover>
		</div>
	);
};
