import { useCallback, useEffect, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

import {
	FRONT_PATH_NAMES,
	FULFILLED_MODELIZATION_TYPE,
	PARTIAL_DATA_MODELIZATION_TYPE,
} from '@carbonmaps/shared/utils/constants';
import { COLOR_SCORING } from '@carbonmaps/shared/utils/scoring';
import { getTopSupplier } from '@carbonmaps/ui/actions/dashboard.actions';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import Typography from '../../../components/Typography';
import Button from '../../../components/v3/Button';
import Card from '../../../components/v3/Card';
import CategoryTable from '../../../components/v3/CategoryTable';
import DashboardSelect from '../../../components/v3/DashboardSelect';
import { useYearSelection } from '../../../hooks/useImpactYear';
import { useJuneTrack } from '../../../hooks/useJuneTrack';
import { useTranslation } from '../../../hooks/useTranslation';
import { cn, siteColors } from '../../../lib/colors';
import { useGetClientAuthQuery } from '../../../lib/react-query/features/auth/auth.hooks';

const styles = {
	content: css({
		minHeight: 164,
	}),

	emptyText: css({
		color: cn(siteColors.grey700, '!important'),
		textAlign: 'center',
		width: '100%',
		height: '164px',
		justifyContent: 'center',
		display: 'flex',
		alignItems: 'center',
	}),
};

const SupplierImpact = () => {
	// ---- hooks ---- //
	const { indicator } = useApp();
	const {
		result: { data: authData },
	} = useGetClientAuthQuery();
	const { t } = useTranslation();

	const navigate = useNavigate();
	// ------------------------------------ //
	// --------- state -------------------- //
	// ------------------------------------ //
	const [currentSegment, setCurrentSegment] = useState('perSupplier');

	// ------------------------------------ //
	// ---- fetch data impact category ---- //
	// ------------------------------------ //
	// period selected
	const { selectedYear } = useYearSelection();

	// ---- fetch data suppliers ---- //
	const { data: dataSuppliers, isLoading } = useQuery({
		queryKey: [
			'getTopSupplier',
			{
				period: selectedYear,
				viewMode: indicator,
				currentSegment,
			},
		],
		queryFn: getTopSupplier,
	});

	// ---- repartition graph data ---- //
	const items = useMemo(() => {
		if (!dataSuppliers || !currentSegment) return [];

		let allSuppliers = dataSuppliers;

		// ---- only display suppliers with specific lca ----//
		if (currentSegment === 'perAcv') {
			allSuppliers = dataSuppliers.filter((o: any) => {
				return o.onboarding === PARTIAL_DATA_MODELIZATION_TYPE || o.onboarding === FULFILLED_MODELIZATION_TYPE;
			});
		}

		const data = allSuppliers.map((obj: any) => {
			const color = _.get(COLOR_SCORING, _.get(obj, 'note'), siteColors.grey400);
			return {
				...obj,
				_id: obj?.name,
				label: obj?.name,
				value: obj[`${indicator}ImpactPercent`],
				volume: obj[`${indicator}Impact`],
				link: `${FRONT_PATH_NAMES.suppliers.details}/${obj?.objectId}/synthesis`,

				color,
			};
		});

		return data;
	}, [dataSuppliers, currentSegment, indicator, authData?.session.company]);

	// ---- event june ---- //
	const analytics = useJuneTrack();

	const trackEvent = useCallback(
		(name: string, options?: any) => {
			if (!analytics) return;
			analytics.track(name, options, {
				context: { category: 'Dashboard' },
			});
		},
		[analytics],
	);

	const goToSuppliers = useCallback(() => {
		trackEvent('Dashboard Suppliers See All');
		navigate(`${FRONT_PATH_NAMES.suppliers.general}`);
	}, []);

	return (
		<Card
			title={
				<DashboardSelect
					options={
						dataSuppliers
							? [
									{ value: 'perSupplier', label: t('supplier-impact-label') },
									// { value: 'perAcv', label: t('per-specific-acv') },
							  ]
							: []
					}
					onChange={(v: any) => {
						setCurrentSegment(v);
					}}
					value={currentSegment as any}
					className="lineHeight1"
				/>
			}
			footer={<Button label={t('dashboard-supplier-card-button')} handleClick={goToSuppliers} />}
			loading={isLoading}
		>
			<div className={cx(styles.content, 'width100')}>
				{items?.length ? <CategoryTable data={items || []} /> : null}
				{!isLoading && !items?.length
? (
					<Typography className={styles.emptyText}>{t('dashboard-empty-supplier')}</Typography>
				)
: null}
			</div>
		</Card>
	);
};

export default SupplierImpact;
