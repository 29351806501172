import { useMemo } from 'react';

import { Cloud, Fingerprint, Flame, Trees } from 'lucide-react';

import { MESSAGE_KEY_APBO } from '@carbonmaps/shared/utils/constants';

import GraphIndicatorCard from '../../components/apbo/card/GraphIndicatorCard';

import { useIndicatorStat } from './useApbo.hook';

const config = [
	{
		title: 'Empreinte carbone brute',
		icon: Cloud,
		messageKey: MESSAGE_KEY_APBO.grossEmissionIndicator,
		key: 'grossEmission',
		indicator: {
			impact: 0,
			min: 0,
			q1: 0,
			q3: 0,
			mean: 0,
			max: 0,
		},
		labelImpact: 'Impact total brut',
	},
	{
		title: 'Empreinte carbone nette',
		icon: Fingerprint,
		messageKey: MESSAGE_KEY_APBO.gesNetIndicator,
		key: 'gesNet',
		labelImpact: 'Impact total net',
		indicator: {
			impact: 0,
			min: 0,
			q1: 0,
			q3: 0,
			mean: 0,
			max: 0,
		},
	},
	{
		title: 'Stockage carbone',
		icon: Trees,
		messageKey: MESSAGE_KEY_APBO.carbonStorageIndicator,
		key: 'carbonStorage',
		labelImpact: 'Stockage total',
		indicator: {
			impact: 0,
			min: 0,
			q1: 0,
			q3: 0,
			mean: 0,
			max: 0,
		},
	},
	{
		title: 'Empreinte méthane',
		icon: Flame,
		messageKey: MESSAGE_KEY_APBO.methaneIndicator,
		key: 'gesMethane',
		labelImpact: 'Impact total méthane',
		indicator: {
			impact: 0,
			min: 0,
			q1: 0,
			q3: 0,
			mean: 0,
			max: 0,
		},
	},
];

const IndicatorView = () => {
	const { data } = useIndicatorStat();

	const dataIndicator = useMemo(() => {
		if (!data) return config;
		return config.map((item: any) => {
			return {
				...item,
				indicator: {
					impact: data[`${item.key}Impact`],
					min: data[`${item.key}Min`],
					q1: data[`${item.key}Q1`],
					q3: data[`${item.key}Q3`],
					mean: data[`${item.key}Mean`],
					max: data[`${item.key}Max`],
					median: data[`${item.key}Median`],
				},
			};
		});
	}, [data]);
	return (
		<>
			{dataIndicator.map((item, index) => {
				return (
					<GraphIndicatorCard
						title={item?.title}
						messageKey={item?.messageKey}
						indicator={item?.indicator}
						icon={item.icon}
						labelImpact={item.labelImpact}
						key={index}
					/>
				);
			})}
		</>
	);
};

export default IndicatorView;
