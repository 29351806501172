import { css, cx } from '@emotion/css';
import { Tab, Tabs } from '@mui/material';
import { Leaf, Wallet } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import { useJuneTrack } from '../../../hooks/useJuneTrack';
import { useQueryParams } from '../../../hooks/useSearchParams';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import AnalyzeTab from '../v3/AnalyzeTab';
import CarbonFootprint from '../v3/carbon_footprint/CarbonFootprint';

const items = [
	{
		label: 'Analyse produits',
		value: 'impact-tab',
		icon: Wallet,
		element: <AnalyzeTab />,
		tabName: 'Analyse produits',
	},
	{
		label: 'Ma performance extra-financière',
		value: 'carbon-footprint',
		icon: Leaf,
		element: <CarbonFootprint />,
		tabName: 'Ma performance extra-financière',
	},
];

const styles = {
	container: css({
		width: '100%',
		background: siteColors.grey300,
		borderRadius: '16px',
		padding: '8px',
		'& .MuiTabs-flexContainer': {
			gap: 8,
			justifyContent: 'center !important',
		},
		'& .MuiButtonBase-root': {
			textTransform: 'none',
			display: 'flex',
			flex: '1 0 0 ',
			alignItems: 'center',
			minHeight: '52px !important',
			gap: 8,
			fontSize: '16px !important',
			maxWidth: '100% !important',
		},
		'& .MuiTabs-scroller': {
			overflow: 'initial !important',
		},
		'& .Mui-selected': {
			borderRadius: '8px',
			background: siteColors.common.white,
			boxShadow: '0px 2px 1px 0px rgba(93, 17, 230, 0.18), 0px 1px 1px 0px rgba(0, 0, 0, 0.25)',
		},
		'& .MuiTabs-indicator': {
			background: 'none !important',
			//height: '2px !important',
		},
	} as any),
};

const DashboardTabs = () => {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const analytics = useJuneTrack();

	const queryParams = useQueryParams();
	const tab = queryParams?.tab ? queryParams?.tab[0] : 'impact-tab';

	const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
		const tab = items.find((item) => {
			return item.value === newValue;
		});

		analytics.track(
			tab?.tabName || 'Dashboard Switch Tab',
			{},
			{
				context: { category: 'Dashboard' },
			},
		);

		navigate(`${FRONT_PATH_NAMES.home}?tab=${newValue}`);
	};

	return (
		<div className="flexColumn width100 gap32">
			<Tabs onChange={handleTabChange} aria-label="lab API tabs example" value={tab} className={cx(styles.container)}>
				{items.map((item: any, index: number) => {
					const IconComponent = item?.icon;
					const iconColor = tab === item.value ? siteColors.primary : siteColors.grey700;
					return (
						<Tab
							label={
								<div className="flexRow alignCenter gap8 nowrap textAlignLeft">
									<IconComponent color={iconColor} size={20} />
									{t(item.label, {
										products: toLowerCase(t('products')),
									})}
								</div>
							}
							value={item.value}
							key={index}
							disabled={item?.disabled}
						/>
					);
				})}
			</Tabs>

			{items?.map((item: any) => {
				return item.value === tab ? item.element : null;
			})}
		</div>
	);
};

export default DashboardTabs;
