import { useMemo } from 'react';

import { css } from '@emotion/css';
import { orderBy } from 'lodash';
import { Calendar } from 'lucide-react';

// import { APBO_CURRENT_YEAR } from '@carbonmaps/shared/utils/constants';

import SelectField from '../../components/form/fields/SelectField';
import Typography from '../../components/Typography';
import { cn, siteColors } from '../../lib/colors';
import { useGetClientAuthQuery } from '../../lib/react-query/features/auth/auth.hooks';

import { useYearOptions } from './useApbo.hook';

const styles = {
	selectContainer: css({
		backgroundColor: cn(siteColors.grey400, '!important'),
		minHeight: '42px !important',

		svg: {
			color: cn(siteColors.text, '!important'),
		},
	}),
};

const ApboFilter = () => {
	// ---- fetch options ---- //
	const {
		result: { data },
	} = useGetClientAuthQuery();

	const { value, setValue, setOptions } = useYearOptions();

	const yearOptions = useMemo(() => {
		if (!data?.yearOptions) return [];

		const sortYears = orderBy(data?.yearOptions, 'asc');
		setValue(2024);

		const options = sortYears.map((year: number) => {
			return {
				value: year,
				label: `Année ${year}`,
			};
		});

		setOptions(options);

		return [
			{
				value: 2024,
				label: 'Année 2024',
			},
			{
				value: 2023,
				label: 'Année 2023',
			},
		];
	}, [data]);

	return (
		<div>
			<SelectField
				items={yearOptions as any}
				// value={APBO_CURRENT_YEAR as any}
				value={value}
				onChange={(v: any) => {
					setValue(v);
				}}
				renderValue={(v: any) => {
					return (
						<div className="flexRow alignCenter gap8">
							<Calendar color={siteColors.grey700} size={20} />
							<Typography className="black fontWeight600">{v?.label}</Typography>
						</div>
					);
				}}
				className={styles.selectContainer}
			/>
		</div>
	);
};

export default ApboFilter;
