import { Fragment, ReactNode, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MUILink from '@mui/material/Link';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import {
	ArrowLeftRight,
	BookOpen,
	BoxIcon,
	ClipboardCheck,
	Database,
	FilePen,
	GraduationCap,
	Home,
	Leaf,
	Megaphone,
	PackageOpen,
	PencilLine,
} from 'lucide-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as BuildingIcon } from '@carbonmaps/media/icons/building.svg';
import { ReactComponent as Carrots } from '@carbonmaps/media/icons/carrots2.svg';
import { ReactComponent as EnIcon } from '@carbonmaps/media/icons/en.svg';
import { ReactComponent as FrIcon } from '@carbonmaps/media/icons/fr.svg';
import { ReactComponent as LogoutIcon } from '@carbonmaps/media/icons/log-out.svg';
import { ReactComponent as SupplierIcon } from '@carbonmaps/media/icons/supplier-small.svg';
import { ReactComponent as UserIcon } from '@carbonmaps/media/icons/user.svg';
import defaultLogo from '@carbonmaps/media/images/default-logo.png';
import { ReactComponent as CarbonMapsLogo } from '@carbonmaps/media/other/carbonmaps-logo.svg';
import { FRONT_PATH_NAMES, roleLabels } from '@carbonmaps/shared/utils/constants';
import {
	MOD_AUDIT,
	MOD_CAMPAIGN,
	MOD_CAP2ER,
	MOD_DASHBOARD,
	MOD_ECO_CONCEPTION,
	MOD_INGREDIENT,
	MOD_PACKAGING,
	MOD_PRODUCT,
	MOD_SALES,
	MOD_SUPPLIER,
	MOD_TRAIN,
} from '@carbonmaps/shared/utils/module';
import { getConfigFacets } from '@carbonmaps/shared/utils/utils';
import { checkAcvPermission } from '@carbonmaps/ui/actions/acv.actions';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import { getPluginMap, isLockedModule } from '@carbonmaps/ui/utils/utils';

import Popover from '../../components/Popover';
import SidebarNav, { ItemButton } from '../../components/SidebarNav';
import { useRseQFormTabQueryParam } from '../../containers/supplier/general/tabs/rse/hooks/rse.hooks';
import useHasRoles from '../../hooks/useHasRoles';
import { useJuneTrack } from '../../hooks/useJuneTrack';
import { useTranslation } from '../../hooks/useTranslation';
import { cn, siteColors } from '../../lib/colors';
import {
	useGetClientAuthQuery,
	useLogOutMutation,
	useUpdateSessionCompanyMutation,
} from '../../lib/react-query/features/auth/auth.hooks';
import { useGetImgProxy } from '../../lib/react-query/features/img/img.hooks';

type NavListProps = {
	navGroup: NavGroup;
};

const footerMenu = [
	{
		text: 'about-text',
		link: 'https://carbonmaps.io/en/home-en',
		external: true,
	},
	{
		text: 'linkedIn-text',
		link: 'https://www.linkedin.com/company/carbon-maps/',
		external: true,
	},
	{
		text: 'Release Notes',
		link: '/release-notes',
		external: false,
	},
];

type NavGroup = {
	title?: string;
	items: {
		label: string;
		link: string;
		isActive: boolean;
		isLocked: boolean;
		icon: ReactNode;
	}[];
};

type LayoutSideBarProps = {
	open: boolean;
};

const LayoutSideBar = ({ open = false }: LayoutSideBarProps) => {
	const location = useLocation();
	const { pathname } = useLocation();
	const theme = useTheme();
	const hasRoles = useHasRoles();
	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const { t } = useTranslation();

	// ---- check acv module ---- //
	const { data: acv } = useQuery({
		queryKey: ['checkAcvPermission'],
		queryFn: checkAcvPermission,
	} as any);

	// ---- current modules for a company ---- //
	const plugins = useMemo(() => {
		return authData?.session.company?.plugins || [];
	}, [authData?.session.company]);

	// ---- all facets for company ---- //
	const configFacets = useMemo(() => {
		return getConfigFacets(authData?.session.company, false, 'Product');
	}, [authData?.session.company]);

	const isContriButorAndOnlyContributor =
		hasRoles([roleLabels.CONTRIBUTOR]) &&
		!hasRoles([roleLabels.ADMIN, roleLabels.EDITOR, roleLabels.USER, roleLabels.SUPER_ADMIN]);

	const hasAnsweredSurvey = _.get(authData, 'dataForm.hasAlreadyAnswered');

	const [qFormId] = useRseQFormTabQueryParam();

	const navListGroups: NavGroup[] = useMemo(() => {
		const supplierURLSearchParams = qFormId ? `?${decodeURIComponent(`rse:questionFormId=${qFormId}`)}` : '';

		let val = [
			{
				items: [
					...(!isContriButorAndOnlyContributor
						? [
								{
									label: getPluginMap(plugins).get(MOD_DASHBOARD)?.active ? t('Tableau de bord') : t('CAP’2ER'),
									link: _.toString(
										getPluginMap(plugins).get(MOD_DASHBOARD)?.active ? FRONT_PATH_NAMES.home : FRONT_PATH_NAMES.cap2er,
									),
									icon: getPluginMap(plugins).get(MOD_DASHBOARD)?.active ? <Home size={20} /> : <Leaf size={20} />,
									isActive: [FRONT_PATH_NAMES.home, FRONT_PATH_NAMES.cap2er].some((e) => {
										return pathname.startsWith(e);
									}),
									isLocked: isLockedModule(
										plugins,
										getPluginMap(plugins).get(MOD_DASHBOARD)?.active ? MOD_DASHBOARD : MOD_CAP2ER,
									),
								},
						  ]
						: []),

					...(isContriButorAndOnlyContributor && hasAnsweredSurvey
						? [
								{
									label: t('supplier_page'),
									link: `${FRONT_PATH_NAMES.contributor}/${authData?.supplier}/synthesis${supplierURLSearchParams}`,
									isActive: pathname.startsWith(FRONT_PATH_NAMES.contributor),
									icon: <SupplierIcon className={cx('sidebar-icon', listItemStyles.supplierIcon)} />,
									isLocked: false,
								},
						  ]
						: []),

					...(isContriButorAndOnlyContributor && !hasAnsweredSurvey
						? [
								{
									label: t('quiz-breadcrumbs'),
									isActive: pathname === FRONT_PATH_NAMES.questionnaire,
									link: FRONT_PATH_NAMES.questionnaire,
									icon: <PencilLine size={20} />,
									isLocked: false,
								},
						  ]
						: []),

					...(isContriButorAndOnlyContributor
						? [
								{
									label: t('other-features'),
									link: '/promote',
									isActive: pathname.startsWith('/promote'),
									icon: <Leaf size={20} />,
									isLocked: false,
								},
						  ]
						: []),
				],
			},

			...(!isContriButorAndOnlyContributor
				? [
						{
							title: t('analyser'),
							items: [
								...(getPluginMap(plugins).get(MOD_PRODUCT)?.show === true
									? [
											{
												label: t('products'),
												link: FRONT_PATH_NAMES.products.general,
												isActive: pathname.startsWith('/products'),
												icon: <BoxIcon className="sidebar-icon-lucide" size={20} color={theme.palette.grey[700]} />,
												isLocked: isLockedModule(plugins, MOD_PRODUCT),
											},
									  ]
									: []),

								...(getPluginMap(plugins).get(MOD_PACKAGING)?.show === true
									? [
											{
												label: t('Emballages'),
												link: FRONT_PATH_NAMES.packagings.general,
												isActive: pathname.startsWith('/packagings'),
												icon: (
													<PackageOpen
														className={cx('sidebar-icon-lucide')}
														size={20}
														color={theme.palette.grey[700]}
													/>
												),
												isLocked: isLockedModule(plugins, MOD_PACKAGING),
											},
									  ]
									: []),

								...(getPluginMap(plugins).get(MOD_INGREDIENT)?.show === true
									? [
											{
												label: t('ingredients'),
												link: FRONT_PATH_NAMES.ingredients,
												isActive: pathname.startsWith(FRONT_PATH_NAMES.ingredients),
												icon: <Carrots className={cx('sidebar-icon-lucide')} />,
												isLocked: isLockedModule(plugins, MOD_INGREDIENT),
											},
									  ]
									: []),

								...(getPluginMap(plugins).get(MOD_SUPPLIER)?.show === true
									? [
											{
												label: t('Fournisseurs'),
												link: FRONT_PATH_NAMES.suppliers.general,
												isActive: pathname.startsWith('/suppliers'),
												icon: <SupplierIcon className={cx('sidebar-icon', listItemStyles.supplierIcon)} />,
												isLocked: isLockedModule(plugins, MOD_SUPPLIER),
											},
									  ]
									: []),

								...(getPluginMap(plugins).get(MOD_AUDIT)?.show === true
									? [
											{
												label: t('Audits'),
												link: FRONT_PATH_NAMES.audits.general,
												isActive: pathname.startsWith('/audits') || pathname.startsWith('/audit'),
												// &&
												// !pathname.includes('acv') &&
												// !pathname.includes('modelization'),
												icon: <ClipboardCheck size={20} />,
												isLocked: isLockedModule(plugins, MOD_AUDIT),
											},
									  ]
									: []),
							],
						},

						{
							title: t("S'engager"),
							items: [
								...(hasRoles([roleLabels.EDITOR, roleLabels.ADMIN]) &&
								getPluginMap(plugins).get(MOD_CAMPAIGN)?.show === true
									? [
											{
												label: t('Collecter'),
												link: `${FRONT_PATH_NAMES.questionForms}/general`,
												isActive: pathname.startsWith(FRONT_PATH_NAMES.questionForms),
												icon: <Database size={20} />,
												isLocked: isLockedModule(plugins, MOD_CAMPAIGN),
											},
									  ]
									: []),

								...(getPluginMap(plugins).get(MOD_SALES)?.show === true
									? [
											{
												label: t('Valoriser'),
												link: FRONT_PATH_NAMES.sales,
												isActive: [FRONT_PATH_NAMES.sales, FRONT_PATH_NAMES.quote].some((path) => {
													return pathname.startsWith(path);
												}),
												icon: <Megaphone size={20} />,
												isLocked: isLockedModule(plugins, MOD_SALES),
											},
									  ]
									: []),

								...(hasRoles([roleLabels.EDITOR, roleLabels.ADMIN]) &&
								getPluginMap(plugins).get(MOD_ECO_CONCEPTION)?.show === true
									? [
											{
												label: t('Éco-concevoir'),
												link: FRONT_PATH_NAMES.simulation,
												isActive: pathname.startsWith(FRONT_PATH_NAMES.simulation),
												icon: <FilePen size={20} />,
												isLocked: isLockedModule(plugins, MOD_ECO_CONCEPTION),
											},
									  ]
									: []),
							],
						},

						...(getPluginMap(plugins).get(MOD_TRAIN)?.show === true
							? [
									{
										title: t('Se former'),
										items: [
											{
												label: t('Académie'),
												link: _.toString(FRONT_PATH_NAMES.sectorSheet),
												isActive: pathname.startsWith(FRONT_PATH_NAMES.sectorSheet),
												isLocked: isLockedModule(plugins, MOD_TRAIN),
												icon: <GraduationCap size={20} />,
											},
											{
												label: t('Documentation'),
												link: _.toString(FRONT_PATH_NAMES.documentation),
												isActive: pathname.startsWith(FRONT_PATH_NAMES.documentation),
												isLocked: isLockedModule(plugins, MOD_TRAIN),
												icon: <BookOpen size={19} />,
											},
											// {
											// 	label: t('Release Notes'),
											// 	link: _.toString(FRONT_PATH_NAMES.releaseNotes),
											// 	isActive: pathname.startsWith(FRONT_PATH_NAMES.releaseNotes),
											// 	isLocked: isLockedModule(plugins, MOD_TRAIN),
											// 	icon: <GraduationCap size={20} />,
											// },
										],
									},
							  ]
							: []),
				  ]
				: []),
		];

		val = val.filter((e) => {
			return !_.isEmpty(e.items);
		});

		return val;
	}, [
		qFormId,
		isContriButorAndOnlyContributor,
		plugins,
		t,
		hasAnsweredSurvey,
		authData?.supplier,
		pathname,
		theme.palette.grey,
		hasRoles,
	]);

	return (
		<SidebarNav
			header={<Header />}
			footer={isContriButorAndOnlyContributor ? <ContributorFooter /> : <Footer />}
			content={navListGroups.map((item, index) => {
				return <NavList key={index} navGroup={item} />;
			})}
			open={open}
		/>
	);
};

export default LayoutSideBar;

//--------------------------------------------------------------------------------------//
//                                        Header                                        //
//--------------------------------------------------------------------------------------//

const headerStyles = {
	triggerContainer: css({
		// width: 210,
		// marginLeft: 35,
		// width: 220,
		marginLeft: 10,
		marginRight: 10,
		marginTop: 10,
		marginBottom: 4,
		cursor: 'pointer',

		borderRadius: 6,
		// background: 'red',
		// padding: '2px',
		padding: '8px',
		// marginLeft: '-16px',
		'&:hover': {
			background: siteColors.grey300,
		},
	}),

	triggerContent: css({
		// width: 80,
		// height: 80,
		width: 32,
		height: 32,
		borderRadius: 4,
		overflow: 'hidden',
		backgroundColor: siteColors.common.white,
		// backgroundColor: 'blue',
		boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.3)',
	}),
	img: css({
		objectFit: 'cover',
	}),
	skeleton: css({
		width: '100%',
		height: '100%',
	}),

	triggerInnerContent: css({
		marginLeft: 12,
		// background: 'red',
		flex: 1,
		overflow: 'hidden',
		// flexGrow: 1,
	}),

	title: css({
		textTransform: 'none !important',
		textAlign: 'left',
		// width: 90,
		// width: '100%!important',
		whiteSpace: 'nowrap',
		// textOverflow: 'hidden',
		// overflow: 'hidden',
		// background: 'blue',
	} as any),

	subtitle: css({
		// width: 100,
		whiteSpace: 'nowrap',
	}),
	p1Skeleton: css({
		width: 90,
		height: 16,
	}),
	p2Skeleton: css({
		width: 100,
		height: 12,
	}),

	listContainer: css({
		width: 250,
	}),

	enIcon: css({
		width: '24px',
		height: '16px',
		'& svg': {
			height: '100%',
			width: '100%',
		},
	}),
	frIcon: css({
		width: '24px',
		height: '16px',
	}),
};

export const FlagFr = () => {
	return (
		<div className={headerStyles.frIcon}>
			<FrIcon />
		</div>
	);
};

export const FlagEn = () => {
	return (
		<div className={headerStyles.enIcon}>
			<EnIcon />
		</div>
	);
};

const Header = () => {
	const hasRoles = useHasRoles();

	const {
		result: { data: authData, isLoading: isGetClientAuthLoading },
	} = useGetClientAuthQuery();

	const analytics = useJuneTrack();

	const { t, i18n } = useTranslation();

	const navigate = useNavigate();
	const [, setOpen] = useState(false);

	const onNavigate = (url: string) => {
		setOpen(false);
		navigate(url);
	};

	const {
		result: { mutate: updateSessionCompany },
	} = useUpdateSessionCompanyMutation({
		onSuccess() {
			navigate(FRONT_PATH_NAMES.home);
		},
	});

	const {
		result: { mutate: logOut },
	} = useLogOutMutation();

	const activeCompanies = _.get(authData, 'user.companies')?.filter((e) => {
		return _.get(e, 'company.deleted') !== true;
	});

	const listActions = [
		...(activeCompanies && _.isArray(activeCompanies) && activeCompanies.length > 1
			? [
					{
						icon: <ArrowLeftRight color={siteColors.grey700} />,
						title: t('switch-organisation'),
						onClick: () => {
							// do nothing
						},
						subMenu: activeCompanies.map((e) => {
							let company = {
								logo: '---',
								code: '---',
								name: '---',
								objectId: '---',
							};

							if (_.isObject(e.company)) {
								company = e.company;
							}

							return {
								icon: <CompanyLogo logo={company.logo || '---'} />,
								label: company.name || company.code || '---',
								onClick: () => {
									updateSessionCompany({ companyId: e.company.objectId || '---' });
								},
							};
						}),
					},
			  ]
			: []),

		{
			icon: <UserIcon />,
			title: t('my-compte'),
			onClick: () => {
				return onNavigate(`${FRONT_PATH_NAMES.userSettings}`);
			},
		},
		...(hasRoles(['administrateur'])
			? [
					{
						icon: <BuildingIcon />,
						title: t('entreprise'),
						onClick: () => {
							return onNavigate(`${FRONT_PATH_NAMES.managerSettings}`);
						},
					},
			  ]
			: []),

		{
			icon: <LogoutIcon />,
			title: t('logout'),
			onClick: () => {
				return logOut();
			},
		},
		{
			icon: i18n?.language === 'en' ? <FlagEn /> : <FlagFr />,
			title: t('switch-language'),
			onClick: () => {
				// do nothing
			},
			subMenu: [
				{
					label: t('fr'),
					icon: <FlagFr />,
					onClick: () => {
						analytics.track('General Switch Language', { language: 'fr' }, { context: { category: 'General' } });
						i18n.changeLanguage('fr');
					},
				},
				{
					label: t('en'),
					icon: <FlagEn />,
					onClick: () => {
						i18n.changeLanguage('en');
					},
				},
			],
		},
	];

	const {
		result: { data: logoUrl },
	} = useGetImgProxy({
		params: { filename: authData?.session.company?.logo || '' },
	});

	return (
		<Popover
			anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
			trigger={
				<div className={cx('flexRow alignCenter', headerStyles.triggerContainer)}>
					<div className={cx('flexRow alignCenter justifyCenter', headerStyles.triggerContent)}>
						{!isGetClientAuthLoading
? (
							<Box
								component="img"
								width="100%"
								className={headerStyles.img}
								src={authData?.session.company?.logo ? logoUrl : defaultLogo}
							/>
						)
: (
							<Skeleton variant="rectangular" className={headerStyles.skeleton} />
						)}
					</div>
					<div className={headerStyles.triggerInnerContent}>
						{!isGetClientAuthLoading ?
						(
							<>
								<Typography
									variant="body2"
									className={headerStyles.title}
									noWrap
									// sx={{ /* width: '100%', */ bgcolor: 'blue' /* , flex: 1 */ }}
								>
									{authData?.session.company?.name}
								</Typography>
								<Typography variant="caption" display="block" className={cx('grey900', headerStyles.subtitle)} noWrap>
									{`${authData?.user.firstName ?? ''} ${authData?.user.lastName ?? ''}`}
									{/* fzefzfezfezfezfzefezfezfezfezfzefzefzefzezfefzefze */}
								</Typography>
							</>
						) :
						(
							<>
								<Skeleton variant="text" className={headerStyles.p1Skeleton} />
								<Skeleton variant="text" className={headerStyles.p2Skeleton} />
							</>
						)}
					</div>
				</div>
			}
			renderContent={({ close }) => {
				return (
					<List className={headerStyles.listContainer}>
						{listActions.map((action, index) => {
							return (
								<Fragment key={action.title + index}>
									{action.subMenu
? (
										<Popover
											anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
											trigger={
												<ListItemButton
													onClick={() => {
														action.onClick();
														close();
													}}
												>
													<ListItemIcon>{action.icon}</ListItemIcon>
													<ListItemText primary={action.title} />
												</ListItemButton>
											}
											content={action.subMenu.map((e, index) => {
												return (
													<ListItemButton
														key={index}
														onClick={() => {
															e.onClick();
															close();
														}}
													>
														{e.icon}
														<ListItemText sx={{ marginLeft: '8px' }} primary={e.label} />
													</ListItemButton>
												);
											})}
										></Popover>
									)
: (
										<ListItemButton
											onClick={() => {
												action.onClick();
												close();
											}}
										>
											<ListItemIcon>{action.icon}</ListItemIcon>
											<ListItemText primary={action.title} />
										</ListItemButton>
									)}
									{index < listActions.length - 1 && <Divider />}
								</Fragment>
							);
						})}
					</List>
				);
			}}
		/>
	);
};

//--------------------------------------------------------------------------------------//
//                                        Footer                                        //
//--------------------------------------------------------------------------------------//

const footerStyles = {
	container: css({
		marginTop: 24,
		marginBottom: 24,
		marginLeft: 24,
	}),
	muiLink: css({
		marginBottom: '4px !important',
	}),
	logo: css({
		width: 100,
		height: 20,
		'& path': { fill: siteColors.grey700 },
	}),

	beforeText: css({
		fontSize: '0.75rem !important',
		marginLeft: '4px !important',
		marginRight: '4px !important',
	}),
	link: css({
		fontSize: '0.75rem !important',
		textDecoration: 'none !important',
	}),
};

const Footer = () => {
	const { t } = useTranslation();

	return (
		<div className={footerStyles.container}>
			<MUILink component={Link} to="/" display="block" className={footerStyles.muiLink}>
				<CarbonMapsLogo className={footerStyles.logo} />
			</MUILink>
			<Box>
				{footerMenu.map((e, index) => {
					return (
						<Fragment key={index}>
							{index > 0 && (
								<Typography component="span" className={cx('grey700', footerStyles.beforeText)}>
									&bull;
								</Typography>
							)}
							<MUILink
								component={Link}
								target={e.external ? '_blank' : ''}
								to={e.link}
								variant="body1"
								className={cx('grey700', footerStyles.link)}
							>
								{t(e.text)}
							</MUILink>
						</Fragment>
					);
				})}
			</Box>
		</div>
	);
};

const ContributorFooter = () => {
	const { t } = useTranslation();

	// const analytics = useJuneTrack();

	// const trackEvent = useCallback(
	// 	(name: string) => {
	// 		if (!analytics) return;
	// 		analytics.track(name, {
	// 			context: { category: 'Contributor' },
	// 		});
	// 	},
	// 	[analytics],
	// );

	return (
		<div className={cx('flexCenter')} css={{ padding: 24, gap: 16, borderTop: cn('1px', 'solid', siteColors.grey500) }}>
			<div css={{ alignSelf: 'start', justifySelf: 'start' }}>
				<svg xmlns="http://www.w3.org/2000/svg" width="27" height="22" viewBox="0 0 27 22" fill="none">
					<g clip-path="url(#clip0_94_4589)">
						<path
							d="M20.9562 0C19.5183 0.00187583 18.1368 0.547763 17.1009 1.52341C16.065 2.49906 15.455 3.82884 15.3986 5.23444C14.5717 4.85163 13.6611 4.67425 12.7474 4.71803C11.8337 4.76182 10.9452 5.0254 10.1606 5.48544C9.37593 5.94548 8.71941 6.58775 8.2492 7.35535C7.77898 8.12294 7.50959 8.99215 7.4649 9.88595C6.61707 9.4924 5.68122 9.31512 4.7445 9.37063C3.80778 9.42614 2.90065 9.71263 2.10759 10.2034C1.31452 10.6942 0.66132 11.3734 0.2088 12.1776C-0.243719 12.9818 -0.480836 13.885 -0.480468 14.803V22H2.70888V14.803C2.71802 14.1931 2.97212 13.6111 3.41628 13.183C3.86044 12.7548 4.45898 12.5148 5.08256 12.5148C5.70615 12.5148 6.30469 12.7548 6.74885 13.183C7.19301 13.6111 7.44711 14.1931 7.45624 14.803V22H10.6456V10.1525C10.6456 9.5366 10.8957 8.94592 11.3409 8.51041C11.7861 8.07491 12.3899 7.83024 13.0195 7.83024C13.6491 7.83024 14.253 8.07491 14.6982 8.51041C15.1434 8.94592 15.3935 9.5366 15.3935 10.1525V22H18.5823V5.44221C18.5823 4.8263 18.8324 4.23562 19.2776 3.80011C19.7228 3.36461 20.3266 3.11994 20.9562 3.11994C21.5859 3.11994 22.1897 3.36461 22.6349 3.80011C23.0801 4.23562 23.3302 4.8263 23.3302 5.44221V22H26.5195V5.44221C26.5179 3.99933 25.9313 2.616 24.8883 1.59573C23.8453 0.575464 22.4312 0.00158254 20.9562 0Z"
							fill="#7176FF"
						/>
					</g>
					<defs>
						<clipPath id="clip0_94_4589">
							<rect width="27" height="22" fill="white" transform="translate(-0.480469)" />
						</clipPath>
					</defs>
				</svg>
			</div>
			<div>
				<div
					className="width100 grey900"
					css={{
						overflowWrap: 'break-word',
						whiteSpace: 'normal',
						marginBottom: '8px',
						fontWeight: 500,
					}}
				>
					{t('do-more-with-your-csr')}
				</div>
				<div className="width100 grey800" css={{ overflowWrap: 'break-word', whiteSpace: 'normal', fontWeight: 400 }}>
					{t('cta-text-contributor')}
				</div>
			</div>
			<Link
				className="width100"
				to={'/promote'}
			>
				<BButton label="Learn more" variant="primary" addStyles={{ width: '100%' }} />
			</Link>
		</div>
	);
};

const NavList = ({ navGroup }: NavListProps) => {
	return (
		<List
			component="nav"
			subheader={
				navGroup.title
? (
					<ListSubheader
						component="div"
						id="nested-list-subheader"
						css={{ color: siteColors.grey700, fontSize: 12, lineHeight: '32px', paddingLeft: '24px' }}
					>
						{navGroup.title}
					</ListSubheader>
				)
: null
			}
		>
			{navGroup.items.map((item, index) => {
				return (
					<ItemButton
						key={index}
						icon={item.icon}
						text={item.label}
						isActive={item.isActive}
						link={item.link}
						isLocked={item.isLocked}
					/>
				);
			})}
		</List>
	);
};

const listItemStyles = {
	supplierIcon: css({
		path: {
			fill: siteColors.grey700,
		},
	}),
};

const CompanyLogo = ({ logo }: { logo: string }) => {
	const {
		result: { data: logoUrl },
	} = useGetImgProxy({
		params: { filename: logo || '' },
	});

	return (
		<img
			src={logo ? logoUrl : defaultLogo}
			css={{
				width: '32px',
				height: '32px',
				borderRadius: '4px',
				boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.50)',
				objectFit: 'contain',
			}}
			alt="logo company"
		/>
	);
};
