import { css, cx } from '@emotion/css';

import BlockTitle from '../../../../components/compareProduct/BlockTitle';
import CompareProductBarChart from '../../../../components/compareProduct/CompareProductBarChart';
import ImpactSelected from '../../../../components/compareProduct/ImpactSelected';
import Skeleton from '../../../../components/Skeleton';
import { useCompareProduct } from '../../../../hooks/useCompareProduct';
import { useSearchQueryParams } from '../../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../../hooks/useTranslation';
import { orderBy } from '../../../../utils/array.utils';
import { compareProductConfig } from '../../../../utils/compareProduct';
import { useProductsToCompare } from '../hooks';


const styles = {
	container: css({
			padding: '16px 8px',
	}),

	skeleton: css({
		height: 'calc(62vh + 10px) !important',
		width: '100%',
	}),
};

const options = [
	{
		label: 'Tri du moins impactant au plus impactant',
		value: 'asc',
	},
	{
		label: 'Tri du plus impactant au mois impactant',
		value: 'desc',
	},
];

const PerKiloContent = () => {

	const { t } = useTranslation();

	const { isLoading } = useProductsToCompare();

	// search params: don't show loading if no products to compare
		const [searchQueryParams] = useSearchQueryParams();

	const { sortDirection, setSortDirection, products, compareType, indicator } = useCompareProduct();

	// config for compare value
	const config = compareProductConfig.get(compareType)[indicator] as any;

	return (
		<div className={cx('flexColumn flex1 gap24', styles.container)}>
			<BlockTitle title={t('Comparaison de l’impact au kilo')} subtitle={t('Compare plusieurs éléments pour déterminer lequel est le moins émissif par kilo')}/>
			<ImpactSelected options={options} value={sortDirection} onChange={(v: any) => {setSortDirection(v);}}/>
			{ isLoading && searchQueryParams?.productToCompare
? <Skeleton width="100%!important" className={styles.skeleton} /> :
			<CompareProductBarChart products={orderBy(products || [], config.field, sortDirection as any) as any}/> }
		</div>
	);


};

export default PerKiloContent;
