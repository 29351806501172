import { css } from '@emotion/css';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';

import { productCompanyCount } from '@carbonmaps/ui/actions/product.actions';

import { useYearSelection } from '../../../../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../../../../hooks/useSearchQueryParams';
import { MAX_NUMBER_OF_PRODUCTS_TO_DISPLAY } from '../../../../../utils/constants';

import FloatingMenu from './FloatingMenu';
import ProductsFilter from './ProductsFilter';
import ProductsTable from './ProductsTable';
import ProductTradeoff from './ProductTradeoff';

const styles = {

	container: css({
		position:'relative',
	'.filterContainer-wrapper': {
				paddingLeft: 0,
				paddingRight: 0,
				top: `${_.toString(58 * 2 + 2)}px`,
			},
	}),
};

const ProductsList = ({ itemNature }: { itemNature?: string }) => {
	const [searchQueryParams] = useSearchQueryParams();
	const { selectedYear } = useYearSelection();

	//for Remove Products graph for dataset of more than 10k products
	const { data: productCount, isLoading } = useQuery({
		queryKey: ['getProductCompanyCount', { period: selectedYear, itemNature }],
		queryFn: productCompanyCount,
	});

	// remove keys that are not needed for the query

	const { productToCompare, compareProduct, ...filteredQueryParams } = searchQueryParams;

	return (
		<div
			className={styles.container}
		>
			<ProductsFilter itemNature={itemNature} />
			<div className="flexColumn width100 gap24">
				{/* {!isLoading && productCount < MAX_NUMBER_OF_PRODUCTS_TO_DISPLAY
					? (
						<ProductTradeoff filterOptions={filteredQueryParams} itemNature={itemNature} />
					)
					: null} */}
					<ProductTradeoff filterOptions={filteredQueryParams} itemNature={itemNature} />
				<ProductsTable filterOptions={filteredQueryParams} manualSortBy itemNature={itemNature} />
			</div>
			<FloatingMenu itemNature={itemNature}/>
		</div>
	);
};

export default ProductsList;
