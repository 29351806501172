import { useMemo } from 'react';

import { css, cx } from '@emotion/css';
import { tooltipClasses } from '@mui/material';

import LightTooltipGlossary from '../../../../components/LightTooltipGlossary';
import Typography from '../../../../components/Typography';
import Unit from '../../../../components/units/Unit';
import { useGlossary } from '../../../../hooks/useGlossary';
import { useTranslation } from '../../../../hooks/useTranslation';
import { siteColors } from '../../../../lib/colors';
import KPICard from '../../../packagings/components/KPICard';
import KpiValue from '../../../packagings/components/KpiValue';
import { useSBTiFlagKpiData } from '../../sbtiFlag.hooks';

const styles = {
	container: css({
		'@media (max-width: 994px)': {
			flexDirection: 'column !important',
		},
	} as any),
	impactValue: css({
		color: 'var(--color)',
	}),
	impactUnit: css({ paddingLeft: 2 }),
	kpiContainer: css({
		padding: '16px 24px',
		borderLeft: '6px solid var(--borderColor)',
		borderRadius: '6px 16px 16px 6px',
	}),
};

const KPIReport = () => {
	const { t } = useTranslation();

	// ---- fetch data ---- //
	const { data, isLoading } = useSBTiFlagKpiData();

	const items = useMemo(() => {
		return [
			{
				label: 'Land Use Change & Land Use Emissions (LULUC)',
				value: data?.gesFlagLulucImpact,
				color: siteColors.sbtiFlag.gesFlagLuluc,
				keyGlossary: 'land-use-change-land-use-emissions-luluc',
			},
			{
				label: 'CO₂ Land Management Emissions',
				value: data?.gesFlagLmCo2Impact,
				color: siteColors.sbtiFlag.gesFlagLmCo2,
				keyGlossary: 'co2-land-use-management-emissions',
			},
			{
				label: 'Non-CO₂ Land Management Emissions',
				value: data?.gesFlagLmNonCo2Impact,
				color: siteColors.sbtiFlag.gesFlagLmNonCo2,
				keyGlossary: 'non-co2-land-use-management-emissions',
			},
			{
				label: 'Total Net FLAG emissions & removals',
				value: data?.gesFlagImpact,
				color: siteColors.sbtiFlag.gesFlag,
				keyGlossary: 'net-flag-emissions-removals',
			},
		];
	}, [data]);

	const { g } = useGlossary();

	return (
		<div className={cx('flexRow width100 gap16 stretch nowrap', styles.container)}>
			{items.map((item, key: number) => {
				return (
					<KPICard
						key={key}
						className={cx('flex1 flexStart', styles.kpiContainer)}
						label={
							<LightTooltipGlossary
								title={g(item.keyGlossary || '')}
								css={{
									[`& .${tooltipClasses.tooltip}`]: {
										minWidth: '500px',
									},
								}}
							>
								<div>
									<Typography className="grey8" style={{ textDecoration: `underline dashed ${siteColors.grey700}` }}>{item.label}</Typography>
								</div>
							</LightTooltipGlossary>
						}
						value={
							<KpiValue
								value={item.value}
								unit={<Unit measure={'carbonImpactTon'} color={siteColors.grey7} className={styles.impactUnit} />}
								className={styles.impactValue}
								style={{ ['--color' as never]: item.color }}
								message={t('volume incorrect')}
							/>
						}
						isLoading={isLoading}
						style={{ ['--borderColor' as never]: item.color }}
					/>
				);
			})}
		</div>
	);
};

export default KPIReport;
