import { useState } from 'react';

import { css, cx } from '@emotion/css';
import useTheme from '@mui/material/styles/useTheme';
import { Cloudy, Droplets } from 'lucide-react';

import { ITEM_NATURE_NON_FOOD } from '@carbonmaps/shared/utils/constants';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import { Indicator } from '@carbonmaps/ui/contexts/AppProvider';
import { CARBON_INDICATOR, WATER_INDICATOR } from '@carbonmaps/ui/utils/constants';
import { capitalizeFirstLetter } from '@carbonmaps/ui/utils/utils';

import { useImpactWaterSwitch } from '../../hooks/useImpactSwitch';
import { useJuneTrack } from '../../hooks/useJuneTrack';
import { useQueryParams } from '../../hooks/useSearchParams';
import { useTranslation } from '../../hooks/useTranslation';
import { cn, siteColors } from '../../lib/colors';

const buttons = [
	{
		label: 'carbon',
		defaultColor: '',
		icon: Cloudy,
		value: CARBON_INDICATOR,
	},

	{
		label: 'water',
		defaultColor: '',
		icon: Droplets,
		value: WATER_INDICATOR,
	},
];

const styles = {
	container: css({
		borderRadius: '9px',
		border: cn('1px solid', siteColors.grey500),
	}),

	buttonBase: css({
		background: 'transparent!important',
		color: cn(siteColors.grey800, '!important'),
		'& svg.lucide': {
			stroke: siteColors.grey700,
		},
	}),
	button: {
		carbon: css({
			background: cn(siteColors.carbon100, '!important'),
			color: cn(siteColors.carbon500, '!important'),
			'& svg.lucide': {
				stroke: siteColors.carbon500,
			},
		}),
		water: css({
			background: cn(siteColors.water100, '!important'),
			color: cn(siteColors.water500, '!important'),
			'& svg.lucide': {
				stroke: siteColors.water500,
			},
		}),
	},
};

type SwitchButtonProps = {
	value: Indicator;
	onChange: (value: Indicator) => void;
};

const SwitchButton = ({ value, onChange }: SwitchButtonProps) => {
	const theme = useTheme();
	const [selected, setSelected] = useState(value);
	const { switchWaterActive } = useImpactWaterSwitch();

	const { t } = useTranslation();
	const analytics = useJuneTrack();

	const handleChange = (value: Indicator) => {
		analytics.track(
			`General Switch ${capitalizeFirstLetter(value)}`,
			{},
			{
				// Add the GROUP_ID here to track this event on behalf of a workspace
				context: { category: 'General' },
			},
		);
		setSelected(value as any);
		onChange(value);
	};

	return (
		<div className={cx('flexRow alignCenter', styles.container)}>
			{buttons.map((item, index: number) => {
				const IconComponent = item.icon;
				const isSelected = item.value === selected;

				return (
					<BButton
						key={index}
						label={t(item.label)}
						variant="secondary"
						iconLeft={<IconComponent color={isSelected ? theme.palette.common.white : siteColors.grey700} />}
						className={cx('switch-btn',// use for testing
							selected === item.value ? styles.button[selected] : styles.buttonBase)}
						onClick={() => {
							return handleChange(item.value as Indicator);
						}}
					/>
				);
			})}
		</div>
	);
};

export default SwitchButton;
