import { css, cx } from '@emotion/css';

import { siteColors } from '../../lib/colors';
import Skeleton from '../Skeleton';

const styles = {
	container: css({
		padding: 24,
		borderRadius: 'var(--borderRadius)',
		border: '1px solid var(--borderColor)',
		minHeight: 250,
		height: '100%',
	}),
	skeleton: css({
		height: '100% !important',
		width: '100% !important',
		minHeight: '136px',
		flex: 1,
	} as any),
};
type CardProps = {
	title?: React.ReactNode;
	content?: React.ReactNode;
	children?: React.ReactNode;
	footer?: React.ReactNode;
	loading?: boolean;
	topTitle?: React.ReactNode;
	className?: string;
	radius?: number;
	noBorder?: boolean;
	containerClassName?: string;
	innerClassName?: string;
};

const Card = ({
	title,
	topTitle,
	content,
	children,
	footer,
	loading,
	containerClassName,
	innerClassName,
	className,
	radius = 16,
	noBorder = false,
}: CardProps) => {
	return (
		<div className={cx('width100 flexColumn gap16', innerClassName)}>
			{topTitle}
			<div
				className={cx('flexColumn gap16 width100', styles.container, containerClassName)}
				style={{
					['--borderRadius' as any]: `${radius}px`,
					['--borderColor' as any]: !noBorder ? siteColors.grey500 : 'transparent',
				}}
			>
				{title}

				{loading
? (
					<Skeleton width="100%!important" className={styles.skeleton}></Skeleton>
				)
: (
					<>
						{content}
						<div className={cx('flexColumn gap16 width100 spaceBetween flex1', className)}>
							{children}
							{footer}{' '}
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default Card;
