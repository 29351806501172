import { type QueryFunctionContext } from '@tanstack/react-query';

import { functionName } from '@carbonmaps/shared/utils/constants';

import { useYearOptions } from '../../../../containers/apbo/useApbo.hook';

type GetGlobalIndicatorParams = {
	year: number;
};

/**
 * getGlobalIndicatorAction
 * @param context
 * @returns
 */
export const getGlobalIndicatorAction = async (
	context: QueryFunctionContext<readonly ['globalIndicatorData', GetGlobalIndicatorParams]>,
) => {
	try {
		const {
			queryKey: {
				1: { year },
			},
		} = context;
		const result = await Parse.Cloud.run(functionName.apbo.globalIndictorData, { year });
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getCountResultAction = async (context: QueryFunctionContext<readonly ['getCountResult']>) => {
	try {
		const result = await Parse.Cloud.run(functionName.apbo.getResultCount);
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getCarbonTrajectoryGraphAction = async (
	context: QueryFunctionContext<readonly ['getCarbonTrajectoryGraph']>,
) => {
	try {
		const result = await Parse.Cloud.run(functionName.apbo.getCarbonTrajectoryGraph);
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

type GetTooltipParams = {
	year: number;
	field: string;
};

export const getTooltipGraphAction = async (
	context: QueryFunctionContext<readonly ['getTooltipGraph', GetTooltipParams]>,
) => {
	try {
		const {
			queryKey: {
				1: { year, field },
			},
		} = context;

		if (!year || !field) return null;

		const result = await Parse.Cloud.run(functionName.apbo.getDataTooltip, { year, field });
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

type GetDataTableParams = {
	year: number;
};

export const getDataTableAction = async (
	context: QueryFunctionContext<readonly ['getDataTable', GetDataTableParams]>,
) => {
	try {
		const {
			queryKey: {
				1: { year },
			},
		} = context;

		if (!year) return null;

		const result = await Parse.Cloud.run(functionName.apbo.getDataTable, { year });
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getIndicatorStatAction = async (context: QueryFunctionContext<readonly ['getIndicatorStat', { year: number }]>) => {
	try {
		const { year } = context.queryKey[1];
		const result = await Parse.Cloud.run(functionName.apbo.getIndicatorStat, { year });
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const AddCommentAction = async ({ message, key, year }: any) => {
	try {
		return await Parse.Cloud.run(functionName.apbo.addComment, { message, key, year });
	} catch (error) {
		return Promise.reject(error);
	}
};

export const removeCommentAction = async ({ key, year }: any) => {
	try {
		return await Parse.Cloud.run(functionName.apbo.removeComment, { key, year });
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getCommentsAction = async (context: QueryFunctionContext<readonly ['getComments', { year:  number }]>) => {
	try {
		const { year } = context.queryKey[1];
		const result = await Parse.Cloud.run(functionName.apbo.getComments, { year });
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getStatDiagAction = async (context: QueryFunctionContext<readonly ['getStatDiag', { year: number }]>) => {
	try {
		const { year } = context.queryKey[1];
		const result = await Parse.Cloud.run(functionName.apbo.getStatDiag, { year });

		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};
