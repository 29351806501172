import { css, cx } from '@emotion/css';

import BButton from '@carbonmaps/ui/components/saas/BButton';

import { siteColors } from '../../lib/colors';

const styles = {
	button: css({
		color: `${siteColors.blackButton} !important`,
		padding: '8px 16px !important',
		fontWeight: '500 !important',
	}),
};

type ButtonProps = {
	label: string;
	handleClick: () => void;
	isDisabled?: boolean;
	className?: string;
	iconLeft?: React.ReactNode;
	isFullWidth?: boolean;

};

const Button = ({ label, handleClick, iconLeft, isFullWidth=true, className }: ButtonProps) => {
	return (
		<BButton
			variant="secondary"
			label={label}
			onClick={handleClick}
			className={cx(isFullWidth && 'width100', styles.button, className)}
			iconLeft={iconLeft}
		/>
	);
};

export default Button;
