import { useCallback, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

import { ALL_YEAR_OPTION_VALUE, ITEM_NATURE_NON_FOOD, roleLabels } from '@carbonmaps/shared/utils/constants';
import { MOD_ECO_CONCEPTION } from '@carbonmaps/shared/utils/module';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useProductInfo } from '@carbonmaps/ui/hooks/useGetProductSheet';
import { getPluginCompany } from '@carbonmaps/ui/utils/utils';

import useHasRoles from '../../../hooks/useHasRoles';
import { useYearSelection } from '../../../hooks/useImpactYear';
import { useJuneTrack } from '../../../hooks/useJuneTrack';
import { useGetClientAuthQuery } from '../../../lib/react-query/features/auth/auth.hooks';

import MetaDataBlock from './MetaDataBlock';
import OtherIndicatorsBlock from './OtherIndicatorsBlock';
import ProductCalculationsBlock from './ProductCalculsBlock';
import CategoriesBlock from './ProductCategoriesBlock';
import ProductIndicatorsBlock from './ProductIndicatorsBlock';
import ProductIngredientsRepartition from './ProductIngredientsRepartition';
import LifeCycleBlock from './ProductLifeCycleBlock';
import ProductPackagingRepartition from './ProductPackagingRepartition';
import ProductQuantityBlock from './ProductQuantityBlock';
import ProductSimulationsBlock from './ProductSimulationsBlock';
import ProductSuppliersBlock from './ProductSuppliersBlock';

const ProductSynthesis = () => {
	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const plugins = authData?.session.company?.plugins || [];
	const hasRoles = useHasRoles();
	const params = useParams();

	const { indicator } = useApp();
	const { selectedYear, setSelectedYear } = useYearSelection();

	const { data: productInfo } = useProductInfo({ objectId: _.toString(params.productId), viewMode: indicator });

	const analytics = useJuneTrack();

	const trackEvent = useCallback(
		(options: any) => {
			if (!analytics) return;
			analytics.track('Explore Land Product', options, {
				context: { category: 'Explore' },
			});
		},
		[analytics],
	);

	// ---- track event product page ---- //
	useEffect(() => {
		if (!productInfo) return;

		trackEvent({ ProductLabel: productInfo?.label });

		const impactYear = productInfo.impactYear;

		const isFound = (impactYear || []).find((year) => {
			return year === selectedYear;
		});

		if (!isFound && impactYear && selectedYear !== ALL_YEAR_OPTION_VALUE) setSelectedYear(impactYear[0]);
	}, [productInfo, trackEvent]);

	return (
		<>
			<Grid container gap="40px" sx={{ flexWrap: 'nowrap' }}>
				{/* Main content */}
				<Grid item xs={8.2}>
					<ProductIndicatorsBlock />
					<LifeCycleBlock />
					{(productInfo as any)?.['itemNature'] !== ITEM_NATURE_NON_FOOD && <ProductIngredientsRepartition />}
					<ProductPackagingRepartition />
					<OtherIndicatorsBlock />
				</Grid>
				{/* Right sidebar */}
				<Grid item flexGrow={1}>
					{hasRoles([roleLabels.EDITOR, roleLabels.ADMIN]) &&
						getPluginCompany(plugins, MOD_ECO_CONCEPTION).active === true && <ProductSimulationsBlock />}

					<CategoriesBlock />
					<ProductCalculationsBlock />
					<ProductSuppliersBlock />
					<ProductQuantityBlock />
					<MetaDataBlock />
				</Grid>
			</Grid>
		</>
	);
};

export default ProductSynthesis;
