/* eslint-disable no-useless-escape */
import _ from 'lodash';
import FileResizer from 'react-image-file-resizer';

export function getServerUrl() {
	if (window.LOCAL) {
		//---- LOCAL ----//
		return 'http://127.0.0.1:1339/';
	} else if (window.PRODUCTION) {
		//---- PROD ----//
		return 'https://app.carbonmaps.io/';
	} else if (_.get(window, 'SANDBOX')) {
		//---- PROD ----//
		return 'https://app.carbonmaps.io/';
	} else {
		//---- PREPROD ----//
		return 'https://carbonmaps-preproduction.ew.r.appspot.com/';
	}
}

export const resizeFile = (file: any) => {
	return new Promise((resolve) => {
		FileResizer.imageFileResizer(
			file,
			300,
			300,
			'PNG',
			100,
			0,
			(uri) => {
				resolve(uri);
			},
			'file',
		);
	});
};

/**
 * Create a slug
 * @param {String} str
 * @param {String} separator
 */
export const slugify = (str: any, separator = '-') => {
	if (!str) return '';

	str = str.trim();

	return str
		.replace(/^[\\//:*?<>|]/g, '')
		.normalize('NFKD') // split accented characters into their base characters and diacritical marks
		.replace('œ', 'oe') // invalid chars
		.replace('Œ', 'Oe') // invalid chars
		.replace(/[()]/g, separator)
		.replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
		.replace(/[\\//:*?<>|]/g, separator)
		.replace(/["']/g, '')
		.replace(/[^a-z0-9 ]/i, '')
		.replace(/\s+/g, separator) // remove all chars not letters, numbers and spaces (to be replaced)
		.toLowerCase(); // convert to lowercase
};

export const toLowerCase = (str: string) => {
	if (!str) return '';
	return str?.toLowerCase();
};

export const isEmptyText = (str?: string) => {
	if (!str) return true;

	const removeAllSpace = str.replace(/\s/g, '');

	return !removeAllSpace?.length;
};

export const capitalizeFirstLetter = (str?: string) => {
	if (!str) return '';

	return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getIsoCountryCode = (str?: string) => {
	if (!str) return '';
	return str
		.replace('en-US', 'en')
		.replace('fr-FR', 'fr')?.toLowerCase();
};

export const isLockedModule = (plugins: any, str: string) => {
	if (!str) return false;
	const plugin = plugins.find((p: any) => {
		return p.code === str && p.active === true;
	});
	return plugin ? false : true;
};

export const getPluginCompany = (plugins: any, str: string) => {
	if (!str) return [];
	return plugins.find((p: any) => {
		return p.code === str;
	});
};

export const getPluginMap = (plugins: any) => {
	const pluginMap = new Map();

	for (const plugin of plugins) {
		pluginMap.set(plugin.code, plugin);
	}

	return pluginMap;
};

/**
 * generateUniqUID
 * @returns
 */
export const generateUniqUID = () => {
	const timestamp = Date.now().toString(36);
	const randomComponent = Math.random().toString(36).substring(2, 15);
	return timestamp + randomComponent;
};

export const generateRandomString = (length = 10) => {
	const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
	let result = '';

	for (let i = 0; i < length; i++) {
		const randomIndex = Math.floor(Math.random() * chars.length);
		result += chars[randomIndex];
	}

	return result;
};

export const convertMarkdownToHTML = (markdownText: string) => {
	// Convertir les titres (#)
	markdownText = markdownText.replace(/^### (.*$)/gim, '<h3>$1</h3>');
	markdownText = markdownText.replace(/^## (.*$)/gim, '<h2>$1</h2>');
	markdownText = markdownText.replace(/^# (.*$)/gim, '<h1>$1</h1>');

	// Convertir le texte en gras (** ou __)
	markdownText = markdownText.replace(/\*\*(.*)\*\*/gim, '<strong>$1</strong>');
	markdownText = markdownText.replace(/__(.*)__/gim, '<strong>$1</strong>');

	// Convertir le texte en italique (* ou _)
	markdownText = markdownText.replace(/\*(.*)\*/gim, '<em>$1</em>');
	markdownText = markdownText.replace(/_(.*)_/gim, '<em>$1</em>');

	// Convertir les liens [texte](url)
	// markdownText = markdownText.replace(/\[(.*?)\]\((.*?)\)/gim, '<a href="$2">$1</a>');

	// Convert links (e.g. [link](http://example.com) => <a href="http://example.com">link</a>)
	markdownText = markdownText.replace(/\[([^\]]+)\]\((https?:\/\/[^\)]+)\)/g, '<a href="$2">$1</a>');


	// Convertir les listes (- ou *)
	markdownText = markdownText.replace(/^\s*[\*\-]\s(.*)/gim, '<li>$1</li>');

	// Ajouter des balises <ul> autour des <li> (listes non ordonnées)
	markdownText = markdownText.replace(/(<li>.*<\/li>)/gim, '<ul>$1</ul>');

	// Convertir les sauts de ligne en balises <br>
	markdownText = markdownText.replace(/\n/gim, '<br>');

	markdownText = markdownText.replace(/\[/g, '['); // important don't remove this line

	return markdownText.trim(); // Supprimer les espaces en trop
};
