import { useCallback, useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { useJuneTrack } from '../../hooks/useJuneTrack';

import ResetPasswordForm from './ResetPasswordForm';
import SendResetEmailForm from './SendResetEmailForm';

const ResetPassword = () => {
	const [searchParams] = useSearchParams();
	const username = searchParams.get('username');
	const token = searchParams.get('token');
	const [existUser, setExistUser] = useState<boolean>(false);

	const analytics = useJuneTrack();
	const trackEvent = useCallback(
		(name: string, options: any) => {
			if (!analytics) return;
			analytics.track(name, options, {
				context: { category: 'Analyse' },
			});
		},
		[analytics],
	);

	useEffect(() => {
		trackEvent('Reset password confirmed', { description: 'Arrivée sur la page', key: username });
	}, [trackEvent, username]);

	// TODO: change using react-query
	const findUser = useCallback(async function findUser(username: string) {
		const foundUser = (await Parse.Cloud.run('findUserByUsername', { username })) as boolean;
		if (!foundUser) return;
		setExistUser(foundUser);
	}, []);

	useEffect(() => {
		if (username && token) {
			findUser(username);
		}
	}, [findUser, token, username]);

	if (username && token) {
		return existUser && <ResetPasswordForm />;
	}

	return <SendResetEmailForm />;
};

export default ResetPassword;
