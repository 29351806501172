import { css } from '@emotion/css';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';

import { product } from '../../hooks/useCompareProduct';

const styles = {
	link: css({
	textDecoration: 'none',
		':hover': {
			textDecoration: 'underline',
			textDecorationColor: 'var(--underlineColor)',
		},
	}),
};

type ProductLinkProps = {
	product: product;
	color: string;
	children: React.ReactNode;
}

const ProductLink = ({ product, children, color }: ProductLinkProps) => {

	return(
		<a href={`${FRONT_PATH_NAMES.products.details}/${product.objectId}/synthesis`} target='_blank' className={styles.link} style={{ ['--underlineColor' as any] : color }} rel="noreferrer">
				{children}
		</a>
	);

};

export default ProductLink;
