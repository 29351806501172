import { useState } from 'react';

import { CircularProgress } from '@mui/material';
import { Plus } from 'lucide-react';

import BButton from '@carbonmaps/ui/components/saas/BButton';

import { product } from '../../hooks/useCompareProduct';
import { useTranslation } from '../../hooks/useTranslation';

type ProductButtonProps = {
	product: product;
	label: string;
	variant:  'primary' | 'secondary' | 'tertiary';
	icon: React.ReactNode;
	onClick: (product: product) => void;
	addStyles?: any;
}

const ProductButton = ({ product, variant, label, icon, onClick }: ProductButtonProps) => {

	const { t } = useTranslation();

	const [isRunning, setIsRunning] = useState(false);

	return (
		<BButton
			variant={variant}
			iconLeft={isRunning ? <CircularProgress size={20} /> : icon }
			onClick={async () => {
				setIsRunning(true);
				 onClick({
					...product,
					objectId: product?.id as string,
				 });
				setIsRunning(false);
			}}
			label={t(label)}
			// addStyles={{ background: 'transparent', '&:hover': { background: 'transparent' } }}
		/>
	);
};

export default ProductButton;
