import { useMemo } from 'react';

import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import _ from 'lodash';
import ReactDOMServer from 'react-dom/server';

import { formatCompactNumber } from '@carbonmaps/ui/utils/numberFormat';


import { siteColors } from '../../lib/colors';


import TrajectoryTooltip from './TrajectoryTooltip';

const formatLabel: any = function (this: any) {
	return formatCompactNumber(this?.value as any);
};

type ImpactTonnageGraphProps = {
	data: any;
	height?: number;
	gap?: number;
	isFilter?: boolean;
	graphWidth?: string,
	legend: React.ReactNode;
	tooltipItems: Array<{
		label: string;
		unit: React.ReactNode;
		color:string;
		className: string;
		isBorderTop?: boolean;
	}>;
};

const TrajectoryGraph = ({ graphWidth, data, legend, tooltipItems }: ImpactTonnageGraphProps) => {

	const currentYear = new Date().getFullYear();

	// ------------------------------------------------------ //
	// ----------------- Tooltip Formatter ------------------ //
	// ------------------------------------------------------ //
	const tooltipFormatter: any = function (this: any) {

		return ReactDOMServer.renderToString(
			<TrajectoryTooltip title={this.x} items={tooltipItems.map((item, index:number) => {
				return {
				...item,
				value: index > 0 ? this.points[this.points.length === 4 ? 2: 1].y.toFixed(0): this.points[index].y.toFixed(0),
			};})} />,
		);
	};

	// ------------------------------------------------------ //
	// ------------- Display dashed line  ------------------- //
	// ------------------------------------------------------ //
	const countBeforeCurrentYear = useMemo(() => {
			return _.filter(_.sortBy(data?.map?.((d: any) => { return d.year; }) || []), (year) => {return year < currentYear;}).length;
		}, [currentYear, data]);



		const arrayNullBeforeCurrentYear = useMemo(() => {
			return _.times(countBeforeCurrentYear, () => {return null;});
		}, [countBeforeCurrentYear]);

		const commonOptionsFistLine = {
			name: 'SBTi FLAG',
			lineWidth: 2,
			type: 'line',
			color: siteColors.green800, // Ligne grise pour le tonnage
			marker: {
				enabled: true,
				symbol: 'circle', // Points en forme de petit rond
				radius: 4, // Taille du cercle
				lineWidth: 2, // Épaisseur du contour du cercle
				fillColor: 'transparent', // Cercle vide,
				lineColor: siteColors.green500, // Couleur du contour rouge
			},
			yAxis: 1,
		};

		const commonOptionsImpact = {
			name: 'Total CO₂e (tonnes)',
			type: 'line',
			lineWidth: 2,
			color: siteColors.carbon500,
			marker: {
				enabled: true,
				symbol: 'circle', // Points en forme de petit rond
				radius: 4, // Taille du cercle
				lineWidth: 2, // Épaisseur du contour du cercle
				fillColor: 'transparent', // Cercle vide
				lineColor: siteColors.carbon400, // Couleur du contour rouge
			},
			yAxis: 0,
		};

		const options = {
			chart: {
				type: 'line',
				width: null,
				reflow: true, // Active le redimensionnement automatique
			},
			title: {
				text: null, // Pas de titre au centre
			},
			xAxis: {
				categories: data?.map((d: any) => {
					return d.year;
				}), // Remplacez par vos années spécifiques
				labels: {
					style: {
						color: siteColors.grey800, // Change la couleur des chiffres de l'axe X en rouge
					},
				},
				lineColor: siteColors.grey500,
				crosshair: {
					color: siteColors.grey600, // Couleur de la ligne verticale
					width: 1, // Largeur de la ligne
				},
			},
			yAxis: [
				{
					min: 0,
					maxPadding: 0.2, // Ajoute un espace supplémentaire en haut de l'échelle

					// Axe pour l'Impact
					title: {
						text: '', // Étiquette à droite
					},
					labels: {
						formatter: formatLabel,
						style: {
							color: siteColors.grey800, // Change la couleur des chiffres de l'axe X en rouge
						},
					},
					opposite: true,
				}, {
					min: 0,
					maxPadding: 4, // Ajoute un espace supplémentaire en haut de l'échelle

					// Axe pour le Tonnage
					title: {
						text: '', // Étiquette à droite
					},
					labels: {
						formatter: formatLabel,
						style: {
							color: siteColors.grey800, // Change la couleur des chiffres de l'axe X en rouge
						},
					},

				}],
			legend: {
				align: 'right',
				verticalAlign: 'top',
				layout: 'vertical',
				x: 0,
				y: 0,
				floating: true,
				enabled: false, // Désactive la légende

			},
			series: [{
				...commonOptionsImpact,
				data: data?.filter((f: any) => {
					return parseInt(f.year) <= currentYear;
				})?.map((d: any) => {
					return d.gesImpactFlag;
				}),
				dashStyle: 'Solid',
			},
			{
				...commonOptionsImpact,
				// eslint-disable-next-line no-unsafe-optional-chaining
				data: [...arrayNullBeforeCurrentYear, ...data?.filter((f: any) => {
					return parseInt(f.year) >= currentYear;
				})?.map((d: any) => {
					return d.gesImpactFlag;
				})],
				dashStyle: 'Dash',
			},
			{
				...commonOptionsFistLine,
				data: data?.filter((f: any) => {
					return parseInt(f.year) <= currentYear;
				})?.map((d: any) => {
					return d.gesTotal;
				}),
				dashStyle: 'Solid',
			},
			{

				...commonOptionsFistLine,
				// eslint-disable-next-line no-unsafe-optional-chaining
				data: [...arrayNullBeforeCurrentYear, ...data?.filter((f: any) => {
					return parseInt(f.year) >= currentYear;
				})?.map((d: any) => {
					return d.gesTotal;
				})],
				dashStyle: 'Dash',

			}],
			tooltip: {
				useHTML: true,
				shared: true,
				backgroundColor: siteColors.common.white,
				formatter: tooltipFormatter,
				style: {
					boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.45), 0px 4px 16px 0px rgba(0, 0, 0, 0.40)',
				},
				borderRadius: 8,
				borderWidth: 0,
				outside: true,
			},
			credits: {
				enabled: false,
			},
			responsive: {
				rules: [{
					condition: {
						maxWidth: 200,
					},
					chartOptions: {
						legend: {
							align: 'center',
							verticalAlign: 'bottom',
							layout: 'horizontal',
						},
					},
				}],
			},
		};
 return (
		<>
			{legend}
			<div style={{ maxWidth: '100%', width: '100%', height: '100%', overflow: 'visible' }}>
				<HighchartsReact
					highcharts={Highcharts}
					options={options}
					containerProps={{
						style: {
							width: graphWidth ?? '100%',
							height:'155px',
						},
					}}
				/>
			</div>
		</>
 );
};

export default TrajectoryGraph;
