import { css, cx } from '@emotion/css';

import ButtonRadio from './ButtonRadio';
import ItemValuePercent from './ItemValuePercent';

const styles = {
	container: css({
		minWidth: 96,
	}),
};


type CompareItemValueProps = {
	value: number;
	isCompared: boolean;
	onSelected: () => void;
}

const CompareItemValue = ({ value, isCompared, onSelected }: CompareItemValueProps) => {
	return (
		<div className={cx('flexRow gap12 alignCenter justifyEnd', styles.container)}>
			<ItemValuePercent value={value} isSelected={isCompared} />
			<ButtonRadio isSelected={isCompared} onSelected={onSelected}/>
		</div>
	);

};

export default CompareItemValue;
