import { css, cx } from '@emotion/css';
import { Toolbar } from '@mui/material';

import { useCompareProduct } from '../../../hooks/useCompareProduct';
import { siteColors } from '../../../lib/colors';
import { PER_KILO_TYPE, PER_PERIOD_TYPE } from '../../../utils/compareProduct';

import CompareTypeMenu from './CompareTypeMenu';
import PerKiloContent from './content/PerKiloContent';
import PerPeriodContent from './content/PerPeriodContent';

const styles = {
	container: css({
		paddingTop: 16,
		flexGrow: 1,
		padding: 8,
		paddingLeft: 0,

	}),

	innerContainer: css({
		padding: 8,
		background: siteColors.common.white,
		boxShadow: '0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.08)',
		borderRadius: 8,
		height: 'calc(100vh - 76px)',
		overflow: 'hidden',
	}),
};

const CompareProductContent = () => {

	const { compareType } = useCompareProduct();

	return (
		<main className={styles.container}>
			<Toolbar />
			<div className={cx('flexRow gap24', styles.innerContainer)}>
				<CompareTypeMenu />
				{compareType === PER_KILO_TYPE && <PerKiloContent /> }
				{compareType === PER_PERIOD_TYPE && <PerPeriodContent /> }
			</div>
		</main>
	);

};

export default CompareProductContent;
