export const siteColors = {
	text: '#140c27',
	primary: '#7176FF',
	primary2: '#5600F1',
	primaryHover: '#4A50F6',
	primary10: '#F0F0FF',

	carbon500: '#7176FF',
	carbon400: '#999DFF',
	carbon300: '#B2B5FF',
	carbon100: '#E0E1FF',
	carbon10: '#F0F0FF',

	water500: '#4B9FD2',
	water400: '#82B4D3',
	water300: '#B2D2E5',
	water100: '#E2F3FD',
	water10: '#E8F1F6',

	chartGrey: '#5B5B5B',

	blueGrey10: '#D8E0F3',
	backgroundDrawer: '#F9FAFB',

	grey900: '#474154',
	grey800: '#6A618A',
	grey700: '#9587AE',
	grey600: '#C0B5D4',
	grey500: '#E2DDEB',
	grey400: '#ECE9F2',
	grey300: '#F4F2F7',
	grey200: '#F9F8FB',
	grey100: '#FAF9FC',
	grey9: '#374151',
	grey8: '#4B5563',
	grey7: '#6B7280',
	grey6: '#9CA3AF',
	grey5: '#D1D5DB',
	grey4: '#E8EAED',
	grey3: '#E5E7EB',
	grey2: '#F3F4F6',
	blackButton: '#292430',
	greenSBTi: '#68C19B',
	cmText: '#292430',

	green500: '#95AB76',
	green800: '#557A06',
	green10: '#F4F7EE',
	jobGreen10: '#DDE7CB',
	jobGreen100: '#6C8C40',
	jobOrange10: '#FAE6E0',
	warning: '#C74600',

	brown500: '#8E7B6C',
	brown10: '#F6F5F4',

	purple500: '#B7578A',
	purple10: '#F8F1F5',

	// teal800: '',
	teal500: '#34919D',
	teal10: '#E6F3F4',

	scope1: '#34919D',
	scope2: '#4B9FD2',
	scope3: '#5A719D',

	blueGray500: '#5A719D',
	blueGray10: '#ECF0F9',

	blue10: '#E8F1F6',
	blue100: '#E0E1FF',

	orange500: '#E47F5F',
	blue500: '#4B9FD2',
	orange10: '#FCF0ED',
	recycledIcon: '#6B7280',
	carbonImpact: '#7176FF',

	status: {
		warning: '#7176FF',
		success: '#95AB76',
		error: '#C74600',
		info: '#7176FF',
	},

	sbtiFlag: {
		gesFlagLuluc: '#D26FA4',
		gesFlagLmCo2: '#5A719D',
		gesFlagLmNonCo2: '#E9B44F',
		gesFlagRemoval: '#9CA3AF',
		gesFlag: '#95AB76',
	},

	fontPrint: {
		color0: '#5B6EF9',
		color1: '#B376FA',
		color2: '#5574A1',
		color3: '#AA8344',
		color4: '#8EA06E',
		color5: '#D97959',
		color6: '#827162',
	},
	common: {
		white: '#FFFFFF',
	},
	job: {
		teal500: '#34919D',
		blue500: '#4B9FD2',
	},
	materialColors: {
		color0: '#4B9FD2',
		color1: '#E47F5F',
		color2: '#5A719D',
		color3: '#95AB76',
		color4: '#B7578A',
		other: '#D1D5DB',
	},

	compareProductColors: {
		color0: '#7176FF',
		color1: '#E47F5F',
		color2: '#95AB76',
		color3: '#B7578A',
		color4: '#FFBE17',
		other: '#D1D5DB',
	},
};

export const cn = (...args: string[]) => {
	let res = '';

	args.forEach((arg, i) => {
		res += (i === 0 ? '' : ' ') + arg.replace(/^\s+|\s+$/g, '');
	});

	return res;
};
