import { useCallback, useEffect, useState } from 'react';

import { css, cx } from '@emotion/css';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ArrowLeft, Edit2, FileEdit, Scale } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { getAllProductSimulationAction, updateScenarioAction } from '@carbonmaps/ui/actions/simulation.actions';
import BButton from '@carbonmaps/ui/components/saas/BButton';

import BlueCheck from '../../../components/BlueCheck';
import LoadingDialog from '../../../components/dialogs/LoadingDialog';
import { useJuneTrack } from '../../../hooks/useJuneTrack';
import { useTranslation } from '../../../hooks/useTranslation';
import { cn, siteColors } from '../../../lib/colors';
import { TOP_BAR_HEIGHT } from '../../../utils/constants';
import ScenarioEditModal from '../ScenarioEditModal';
import { useScenario } from '../scenario.hooks';
import { useDataSimulation } from '../useDataSimulation.hooks';

import HideOrShowBlock from './HideOrShowBlock';
import ResetBlock from './ResetBlock';

const headerStyles = {
	container: css({
		boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.50)',
		zIndex: 200,
		position: 'sticky',
		top: TOP_BAR_HEIGHT,
		padding: '20px 21px 20px 16px',
		background: 'white',
	}),
	iconBox: css({
		borderRadius: '8.89px',
		width: '40px',
		height: '40px',
		marginRight: '8px',
		marginLeft: '16px',
	}),

	simulationName: css({
		fontWeight: '600 !important',
		fontSize: '16px!important',
		marginRight: '8px!important',
		maxWidth: '300px!important',
		textOverflow: 'ellipsis!important',
		overflow: 'hidden!important',
	}),

	productSourceLabel: css({
		'& .blue-check-icon-container': {
			width: '16px!important',
		},
		'& .MuiTypography-h2:first-of-type': {
			fontSize: '14px!important',
			fontWeight: '400!important',
			maxWidth: '300px!important',
			textOverflow: 'ellipsis!important',
		},
	}),
	goBack: css({
		minWidth: '0px !important',
		padding: '0px !important',
	}),
	more: css({
		cursor: 'pointer',
	}),
	width300: css({
		width: 300,
	}),
	dropItemButton: css({
		gap: 8,
		fontSize: '14px!important',
		fontWeight: '400!important',
		color: cn(siteColors.text, '!important'),
		cursor: 'pointer',
		padding: '8px 16px 8px 16px',
		background: 'transparent!important',
		paddingInline: 0,
	}),
	menuItem: css({
		width: '300px !important',
		paddingLeft: '9px !important',
	}),
	itemText: css({
		paddingLeft: '9px !important',
		textTransform: 'none !important',
	} as any),
	actionContainer: css({
		gap: 16,
	}),
};

const ScenarioHeader = () => {
	const params = useParams();
	const { data } = useScenario({
		simulationId: params.simulationId || '',
	});
	const queryClient = useQueryClient();

	// translation
	const { t } = useTranslation();

	const { simulation, onDataChange } = useDataSimulation();
	//opens or closes the edit scenario form
	const [open, setOpen] = useState(false);
	//If we only need net weight in the form
	const [onlyNetWeight, setOnlyNetWeight] = useState(false);
	//For net weight change form title
	const [formModalTitle, setFormModalTitle] = useState('');
	const [search, setSearch] = useState<any>();

	const navigate = useNavigate();

	const [options, setOptions] = useState<any>();

	//----- fetch all products ----//
	const { data: dataProduct } = useQuery({
		queryKey: [
			'getAllProductSimulation',
			{
				simulationId: params.simulationId || '',
				input: search?.input,
			},
		],
		queryFn: getAllProductSimulationAction,
	});

	const [selectedOption, setSelectedOption] = useState<any>() as any;

	// ---- update product ---- //
	const { mutate: updateScenario, isLoading: isLoadingUpdate } = useMutation({
		mutationKey: ['updateScenario'],
		mutationFn: updateScenarioAction,
		onSuccess: async () => {
			queryClient.invalidateQueries({ queryKey: ['getScenario'] });
			queryClient.invalidateQueries({
				queryKey: ['getProductSimulationDataAction'],
			});
			setOpen(false);
		},
		onError: (error: any) => {
			queryClient.invalidateQueries({ queryKey: ['getScenario'] });
		},
	});

	const analytics = useJuneTrack();
	// ---- track event ---- //
	const trackEvent = useCallback(
		(eventName: string, options?: any) => {
			if (!analytics) return;
			analytics.track(eventName, options, {
				context: { category: 'Simulate' },
			});
		},
		[analytics],
	);

	const onSaveData = (data: any) => {
		updateScenario({ ...data, simulationId: params.simulationId });
		setOpen(false);
	};

	const handleGoBack = () => {
		queryClient.invalidateQueries({ queryKey: ['getProductSimulationDataAction'] });
		navigate(`${FRONT_PATH_NAMES.simulation}`);
	};

	// ---- update zustand store ---- //
	useEffect(() => {
		onDataChange(data);

		if (data) {
			trackEvent('Simulate Eco Design Land Scenario', {
				ScenarioName: data.name,
			});
		}
	}, [data, onDataChange]);

	useEffect(() => {
		setOptions(dataProduct);
	}, [dataProduct]);

	useEffect(() => {
		setSelectedOption((simulation as any)?.product?.toJSON());
	}, [simulation]);

	return (
		<div className={cx('flexRow justifyBetween alignCenter', headerStyles.container)}>
			<div className={cx('flexRow alignCenter')}>
				<BButton
					label=""
					iconLeft={<ArrowLeft color={siteColors.grey700} />}
					variant="tertiary"
					onClick={handleGoBack}
					className={headerStyles.goBack}
				/>
				{/* <ArrowLeft color={siteColors.grey700} onClick={handleGoBack} className={headerStyles.goBack} /> */}
				<div className={cx('bgGrey300 flexCenter', headerStyles.iconBox)}>
					<FileEdit color={siteColors.grey700} />
				</div>

				<div>
					<div className={cx('flexRow')}>
						<div className={cx('black', headerStyles.simulationName)}>{data?.name}</div>
						<Edit2
							css={{ cursor: 'pointer' }}
							color={siteColors.grey700}
							width={17.5}
							onClick={() => {
								setOnlyNetWeight(false);
								setFormModalTitle(t('simulation-details-modal-title'));
								return setOpen(true);
							}}
						/>
					</div>

					<BlueCheck
						className={headerStyles.productSourceLabel}
						title={data?.[data.pointer].get('label')}
						withIcon={data?.[data.pointer].get('tagAdvanced') === 'yes'}
					/>
				</div>
			</div>

			<div className={cx('flexRow alignCenter', headerStyles.actionContainer)}>
				<BButton
					label={`${(simulation as any)?.netWeight ?? ' '}g`}
					variant="secondary"
					iconLeft={<Scale color={siteColors.grey700} />}
					onClick={() => {
						setOnlyNetWeight(true);
						setFormModalTitle(t('simulation-poids-net-button'));
						return setOpen(true);
					}}
				/>
				<HideOrShowBlock />
				<ResetBlock />
				{/* <BButton
					label={t('simulation-details-button-config')}
					variant="secondary"
					onClick={() => {
						setFormModalTitle(t('simulation-details-modal-title'));
						setOnlyNetWeight(false);
						return setOpen(true);
					}}
				/> */}
			</div>

			<ScenarioEditModal
				open={open}
				onClose={() => {
					return setOpen(false);
				}}
				simulation={simulation}
				options={options}
				selectedOption={selectedOption}
				setSelectedOption={setSelectedOption}
				onSaveData={onSaveData}
				setSearch={setSearch}
				onlyNetWeight={onlyNetWeight}
				title={formModalTitle}
			/>

			<LoadingDialog open={isLoadingUpdate} content={`${t('Modification en cours')}...`} />
		</div>
	);
};

export default ScenarioHeader;
