import { useRef } from 'react';

import { css, cx } from '@emotion/css';
import { AppBar } from '@mui/material';
import { ArrowLeft } from 'lucide-react';



import ShareButton from '../../../components/layout/ShareButton';
import SwitchButton from '../../../components/layout/SwitchButton';
import Typography from '../../../components/Typography';
import Button from '../../../components/v3/Button';
import { product, useCompareProduct } from '../../../hooks/useCompareProduct';
import { useYearSelection } from '../../../hooks/useImpactYear';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';


const Z_INDEX = 1150 ;
const styles = {
	container: css({
		height: 64,
		padding: '0px 32px',
	}),
	selectedYearButton: css({
		borderRadius: 8,
		fontSize: '16px !important',
		padding: '8px 16px',
		background: siteColors.grey4,
		fontWeight: 500,
		color: `${siteColors.blackButton} !important`,
		fontVariantNumeric: 'lining-nums tabular-nums',
	}),
	rightContainer: css({
		alignItems: 'stretch !important',
		gap: '8px',
	}),
	appBar:css({
    zIndex: `${Z_INDEX + 1} !important`,
		background:`${siteColors.backgroundDrawer} !important`,
		boxShadow: 'none !important',
  }),
};

type CompareProductHeaderProps = {
	onClick: () => void;
}

const CompareProductHeader = ({ onClick }: CompareProductHeaderProps) => {
	const topBarRef = useRef<any>(null);

	const { selectedYear } = useYearSelection();

	const { t } = useTranslation();

	const { indicator, setIndicator } = useCompareProduct();

	const { products } = useCompareProduct();

		return (
			<AppBar position="fixed" className={styles.appBar}>
				<div className={cx('flexRow width100 spaceBetween alignCenter nowrap gap16', styles.container)}>
					<div>
						<Button  label={t('Retour')} handleClick={onClick} iconLeft={<ArrowLeft />} />
					</div>
					<div className={cx('flexRow center alignCenter', styles.rightContainer)}>
						<ShareButton anchorEl={topBarRef.current} products={products.map((p: product) => {return p.objectId;})} />
						<Typography className={cx('flexCenter', styles.selectedYearButton)}>
							{selectedYear}
						</Typography>
						<SwitchButton
							value={indicator as any}
							onChange={(value) => {
								return setIndicator(value);
							}}
						/>
					</div>
				</div>
				</AppBar>
		);

};

export default CompareProductHeader;
