import { css, cx } from '@emotion/css';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { X } from 'lucide-react';
import { useToggle } from 'react-use';

import BButton from '@carbonmaps/ui/components/saas/BButton';

import { useYearOptions } from '../../containers/apbo/useApbo.hook';
import { cn, siteColors } from '../../lib/colors';
import { removeCommentAction } from '../../lib/react-query/features/apbo/apbo.actions';
import Popover from '../Popover';
import Typography from '../Typography';

const styles = {
	check: css({
		color: siteColors.green500,
	}),
	popoverContainer: css({
		padding: '24px',
		width: '300px',
		borderRadius: '8px',
		border: cn('1px solid', siteColors.grey500),
		background: '#FFFFFF',
		boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.4), 0px 1px 2px rgba(0, 0, 0, 0.45)',
	}),

	titlePopover: css({
		textAlign: 'left !important',
		fontSize: '16px !important',
		fontWeight: '600 !important',
	} as any),

	content: css({
		gap: 20,
		marginTop: '16px',
	}),

	deletedButton: css({
		color: cn(siteColors.grey700, '!important'),
		background: cn(siteColors.grey200, '!important'),
		paddingLeft: '0 !important',
		paddingRight: '0 !important',
		minWidth: '0 !important',
	}),
};
type DeletedMessageButtonProps = {
	messageKey: string;
};

const DeletedMessageButton = ({ messageKey }: DeletedMessageButtonProps) => {
	const [toggle, toggleClose] = useToggle(true);

	const queryClient = useQueryClient();

	const { mutate: removeComment } = useMutation({
		mutationKey: ['removeComment'],
		mutationFn: removeCommentAction,
		onSuccess: async () => {
			toggleClose();
			queryClient.invalidateQueries({ queryKey: ['getComments'] });
		},
	});

	const { value: year } = useYearOptions();

	const onConfirm = () => {
		removeComment({ key: messageKey, year });
	};

	return (
		<Popover
			isClose={toggle}
			trigger={
				<div>
					<BButton variant="tertiary" iconLeft={<X />} label="" className={styles.deletedButton} />
				</div>
			}
			content={
				<div className={styles.popoverContainer}>
					<Typography className={styles.titlePopover}>Voulez-vous supprimer ce commentaire? </Typography>
					<div className={cx('flexColumn width100', styles.content)}>
						<BButton
							variant="secondary"
							label={'Confirmer'}
							onClick={() => {
								onConfirm();
							}}
						/>
					</div>
				</div>
			}
		/>
	);
};

export default DeletedMessageButton;
