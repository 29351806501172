import { css, cx } from '@emotion/css';
import { InfoIcon } from 'lucide-react';

import LightTooltipGlossary from '../../../components/LightTooltipGlossary';
import Skeleton from '../../../components/Skeleton';
import Typography from '../../../components/Typography';
import { siteColors } from '../../../lib/colors';

const styles = {
	container: css({
		padding: '16px 24px',
		border: `1px solid ${siteColors.grey5}`,
		borderRadius: 16,
		minHeight: 50,
	}),
	value: css({
		fontSize: '24px !important',
		lineHeight: '1.2 !important',
		color: `${siteColors.grey8} !important`,
	}),
	label: css({
		lineHeight: '1.2 !important',
		color: `${siteColors.grey7} !important`,
	}),
	skeleton: css({
		height: '100% !important',
		width: '100% !important',
		minHeight: 50,
		flex: 1,
	} as any),
	popoverContent: css({
		padding: '16px',
		width: '220px !important',
	}),
	tooltip: css({
		maxWidth: '350px !important',
	}),
};

type KPICardProps = {
	icon?: React.ReactNode;
	className?: string;
	label?: React.ReactNode;
	value: React.ReactNode;
	isLoading?: boolean;
	code?: string;
	tooltip?: React.ReactNode;
	[x: string]: any;
};

const KPICard = ({ className, icon, value, label, isLoading, tooltip, ...props }: KPICardProps) => {

	return (
		<div className={cx('flexRow alignCenter gap16', styles.container, className)} {...props}>
			{isLoading
? (
				<Skeleton width="100%!important" className={styles.skeleton}></Skeleton>
			)
: (
				<div className="flexRow width100 spaceBetween">
					<div className="flexRow alignCenter gap16">
						{icon}
						<div className="flexColumn gap4">
							{value}
							<Typography className={cx(styles.label)}>{label}</Typography>
						</div>{' '}
					</div>
					{tooltip
? (
						<LightTooltipGlossary title={tooltip} className={styles.tooltip}>
							<InfoIcon size={20} color={siteColors.grey6} />
						</LightTooltipGlossary>
					)
: null}
				</div>
			)}
		</div>
	);
};

export default KPICard;
