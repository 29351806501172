import { css, cx } from '@emotion/css';
import _ from 'lodash';

import { getConfigFacets } from '@carbonmaps/shared/utils/utils';

import { useGetClientAuthQuery } from '../../../../../lib/react-query/features/auth/auth.hooks';
import { moveItemToFront } from '../../../../../utils/utils';

import CategoryCard from './CategoryCard';
import TopProducts from './TopProducts';

const styles = {
	container: css({
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		gap: 32,
	}),
};



const CategoryCardGroup = () => {
	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const items = getConfigFacets(authData?.session.company, false, 'Product').slice(0, 10);
	const sortedItems = moveItemToFront(
		items,
		_.get(authData, 'session.company.category', _.get(authData, '0.field')) as never,
	);

	return (
		<div className={cx('width100', styles.container)}>
			<TopProducts />
			{sortedItems.map((item, key: number) => {
				return <CategoryCard key={key} category={_.get(item, 'field', '---')} label={_.get(item, 'label', '---')} />;
			})}
		</div>
	);
};

export default CategoryCardGroup;
