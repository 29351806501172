import { useCallback, useEffect } from 'react';

import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import { Link, useSearchParams } from 'react-router-dom';

import { useJuneTrack } from '../../hooks/useJuneTrack';
import { useTranslation } from '../../hooks/useTranslation';

const InvalidLink = () => {
	const { t } = useTranslation();
	const theme = useTheme();

	const analytics = useJuneTrack();
	const trackEvent = useCallback(
		(name: string, options: any) => {
			if (!analytics) return;
			analytics.track(name, options, {
				context: { category: 'Analyse' },
			});
		},
		[analytics],
	);

	const [searchParams] = useSearchParams();

	useEffect(() => {
		trackEvent('Invalid Login Link Land', { description: 'Arrivée sur la page', key: searchParams.get('email') });
	}, [searchParams, trackEvent]);

	return (
		<Box px={0} py={2} maxWidth={'386px'} minHeight={'300px'}>
			<Typography marginTop="32px" marginBottom="16px">
				{t('Lien expiré veuillez relancer')}
			</Typography>
			<Box className="flexColumn gap16">
				<Link
					to="/changer-mot-de-passe"
					css={{
						textDecoration: 'none',
						color: theme.palette.primary.main,
						fontWeight: 600,
					}}
				>
					{t('password-form-button')}
				</Link>
				<Link
					to="/login"
					css={{
						textDecoration: 'none',
						color: theme.palette.primary.main,
						fontWeight: 600,
					}}
				>
					{t('Se connecter')}
				</Link>
			</Box>
		</Box>
	);
};

export default InvalidLink;
