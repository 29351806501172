import Typography from '../Typography';

type BlockTitleProps = {
	title: string;
	subtitle?: string;
}

const BlockTitle = ({ title, subtitle }: BlockTitleProps) => {
	return (
		<div className="flexColumn gap4">
			<Typography variant="h3" className="grey9">{title}</Typography>
			<Typography className="grey8 numeric">{subtitle}</Typography>
		</div>
	);

};

export default BlockTitle;
