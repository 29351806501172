import { css, cx } from '@emotion/css';
import { Minus } from 'lucide-react';

import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import { siteColors } from '../../lib/colors';

const styles = {
	container: css({
		zIndex: 50000,
		padding: 16,
		minWidth:300,
		gap: 16,

	}),
	title: css({
		fontSize: '16px',
		fontWeight: 600,
		color: siteColors.grey900,
	}),
	tooltipLabel: css({
		fontSize: '14px',
		fontWeight: 400,
		color: siteColors.grey900,
		display: 'flex',
		alignItems: 'center',
		verticalAlign: 'middle',
	}),

	tooltipValue: css({
		fontSize: '14px',
		fontWeight: 400,
		// color: 'var(--color) !important',
		color: 'red !important',
	}),
};

type TrajectoryTooltipProps = {
	title: string;
	items: Array<{
		label: string;
		value: number;
		unit: React.ReactNode;
		className: string;
		color: string;
		isBorderTop?: boolean;
	}>
}

const TrajectoryTooltip = ({ title,  items  }: TrajectoryTooltipProps) => {
	return (
		<div className={cx('flexColumn', styles.container)}>
			<div className="flexColumn">
				<span className={styles.title}>
					{title}
				</span>
			</div>
			{items.map((item, index) => {
				return (
					<ItemDisplay
						key={index}
						label={item.label}
						value={item.value}
						unit={item.unit}
						color={item.color}
						className={item.className}
					/>
				);
			})}
		</div>
	);
};

export default TrajectoryTooltip;

type ItemDisplayProps = {
	label: string;
	value: number;
	unit: React.ReactNode;
	className: string;
	color: string;
}

export const ItemDisplay = ({ label, value, unit, color, className }: ItemDisplayProps) => {

	return (

			<div className={cx('flexRow spaceBetween nowrap width100')}>
				<span className={cx(styles.tooltipLabel)}>
					<Minus color={color} />
					<div className={cx(styles.tooltipValue, className)} >
						{label}
					</div>
					</span>
					<span className={cx(styles.tooltipValue, className)} style={{ color: 'red !important' }}>
					{formatNumber(value, undefined, 0)}
				{' '}	{unit}</span>
			</div>
	);
};
