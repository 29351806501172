import { useMutation, useQuery, useQueryClient, type MutateOptions } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import type { IUser } from '@carbonmaps/shared/types/user.types';
import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import type { LogInInput } from '@carbonmaps/shared/validations/auth.validations';

import {
	LoginAsActionProps,
	UpdateSessionCompanyActionProps,
	getClientAuthAction,
	getClientAuthKey,
	logInAction,
	logInAsAction,
	logOutAction,
	updateSessionCompanyAction,
} from './auth.actions';

type UseLogInMutationProps = {
	onSuccess?: MutateOptions<IUser, Error, LogInInput>['onSuccess'];
	onError?: MutateOptions<IUser, Error, LogInInput>['onError'];
};

export const useLogInMutation = ({ onSuccess, onError }: UseLogInMutationProps = {}) => {
	const key = ['logIn'] as const;

	const result = useMutation({
		mutationKey: key,
		mutationFn: logInAction,
		onSuccess,
		onError,
	});

	return { result, key };
};

type UseGetClientAuthQueryProps = {
	enabled?: boolean;
};

export const useGetClientAuthQuery = ({ enabled }: UseGetClientAuthQueryProps = {}) => {
	const key = getClientAuthKey;

	const result = useQuery({
		queryKey: key,
		queryFn: getClientAuthAction,
		enabled,
	});

	return { result, key };
};

type UseLogOutMutationProps = {
	onSuccess?: MutateOptions['onSuccess'];
	redirect?: boolean;
};

export const useLogOutMutation = ({ onSuccess, redirect = true }: UseLogOutMutationProps = {}) => {
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const key = ['logOut'] as const;

	const result = useMutation({
		mutationKey: key,
		mutationFn: logOutAction,
		onSuccess: (...args) => {
			onSuccess?.(...args);
			queryClient.removeQueries(); // TODO: find out which method is better

			if (redirect === true) {
				navigate(`${FRONT_PATH_NAMES.logIn}?isLogout=true`, { replace: true });
			}
		},
	});

	return { result, key };
};

type UseLoginAsMutationProps = {
	options?: {
		onSuccess?: MutateOptions<unknown, unknown, LoginAsActionProps>['onSuccess'];
	};
};

export const useLoginAsMutation = ({ options }: UseLoginAsMutationProps = {}) => {
	const queryClient = useQueryClient();
	const key = ['logInAs', {}] as const;

	const result = useMutation({
		mutationKey: key,
		mutationFn: logInAsAction,
		onSuccess: async (...args) => {
			await queryClient.invalidateQueries();

			options?.onSuccess?.(...args);
		},
		cacheTime: 0,
	});

	return { key, result };
};

type UseUpdateSessionCompanyMutationProps = {
	onSuccess?: MutateOptions<unknown, unknown, UpdateSessionCompanyActionProps>['onSuccess'];
	onError?: MutateOptions<unknown, unknown, UpdateSessionCompanyActionProps>['onError'];
};

export const useUpdateSessionCompanyMutation = ({ onSuccess, onError }: UseUpdateSessionCompanyMutationProps = {}) => {
	const queryClient = useQueryClient();
	const key = ['updateSessionCompany'] as const;

	const result = useMutation({
		mutationKey: key,
		mutationFn: updateSessionCompanyAction,
		async onSuccess(...args) {
			await queryClient.invalidateQueries();
			onSuccess?.(...args);
		},
		onError(...args) {
			onError?.(...args);
		},
	});

	return { result, key };
};
